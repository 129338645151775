import React, { useState, useCallback, useEffect } from 'react'
import { Button,Row, Col, Pagination, Collapse , Modal, message} from "antd"
import axios from "axios";
import { CaretRightOutlined, InfoCircleOutlined, DownloadOutlined } from '@ant-design/icons'

import PageHeader from '../Layout/PageHeader'
import WeightDisputeList from './WeightDisputeList';
import WeightDisputeFilters from './WeightDisputeFilters';
import DragUpload from "../Form/DragUpload";

const downloadTemplate = async () => {
  try {
    const { data } = await axios({
      url: "api/order/template/updateWeightDisputeTemplate",
      method: "GET",
      responseType: "blob",
      timeout: 1000 * 300
    });
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "template_updateWeightDispute.xlsx");
    document.body.appendChild(link);
    link.click();
  } catch (err) {
    console.log("err", err);
  }
}

const WeightDispute = () => {
  const [loading, setLoading] = useState({ index: false, export: false })
  const [weightDisputes, setWeightDisputes] = useState([])
  const [filters, setFilters] = useState({ })
  const [pagination, setPagination] = useState({ currentPage: 1, limit: 50 })
  const [bulkResults, setBulkResults] = useState();
  const [serviceProvider,setServiceProvider] = useState()

  const fetchWeightDisputes = useCallback(async () => {
    setLoading(prevLoading => ({ ...prevLoading, index: true }))

    const params = {
      limit: pagination.limit,
      page: pagination.currentPage,
      ...(filters.search && { searchField: filters.searchField, search: filters.search }),
      ...(filters.weightDiffFrom && { weightDiffFrom: filters.weightDiffFrom }),
      ...(filters.priceDiffFrom && { priceDiffFrom: filters.priceDiffFrom }),
      startDate: filters.startDate?.startOf('day')?.toISOString(),
      endDate: filters.endDate?.endOf('day')?.toISOString(),
    }
    const res = await axios.get("/api/weight-disputes", { params }) 

    setWeightDisputes(res.data.data || [])
    setPagination(res.data.pagination)
    setLoading(prevLoading => ({ ...prevLoading, index: false }))
  }, [pagination.currentPage, pagination.limit, filters])

  const exportDownload = async () => {
    setLoading(prevLoading => ({ ...prevLoading, export: true }))

    const params = {
      ...(filters.search && { searchField: filters.searchField, search: filters.search }),
      ...(filters.weightDiffFrom && { weightDiffFrom: filters.weightDiffFrom }),
      ...(filters.priceDiffFrom && { priceDiffFrom: filters.priceDiffFrom }),
      startDate: filters.startDate?.startOf('day')?.toISOString(),
      endDate: filters.endDate?.endOf('day')?.toISOString(),
    }
    
    const messageContent = (
      <p>
        Are you sure you want to download {pagination.totalSize} orders data ?<br/>
      </p>
    );

    Modal.confirm({
      content: messageContent,
      title: `Download Weight Dispute List ? `,
      async onOk() {
        await axios
        .get("/api/weight-disputes", { params, headers: { 'accept': 'application/vnd.ms-excel' } })
          .then((res) => {
            message.info(`Please check your email after 5-20 minute`);
          })
          .catch((error) => {
            message.error(error.message);
          });
      },
    });
    
    setLoading(prevLoading => ({ ...prevLoading, export: false }))
  }

  useEffect(() => {
    fetchWeightDisputes()
  }, [pagination.currentPage, pagination.limit, fetchWeightDisputes])

  const uploadSuccessHandler = (res) => {
    message.info(`Please check your email after 5-20 minute`)     
  };

  return (
    <div>
      <PageHeader 
        title="Weight Dispute"
        childRoutes={[{ path: "/weight-dispute", breadcrumbName: "Weight Dispute" }]}
      />

      <Collapse
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
      >
        <Collapse.Panel header="Update Weight Dispute" key="bulk-refund-cod">
          <br />
          <DragUpload
            action={`${process.env.REACT_APP_SERVER_URL}/api/orders/update-weight-dispute`}
            accept=".xls, .xlsx"
            method="post"
            timeout={1000 * 600}
            onUploadSuccess={(res) => uploadSuccessHandler(res)}
            beforeUpload={serviceProvider !== ""}
          />
          <br />
          <Button onClick={downloadTemplate} className="mt-2">
            <DownloadOutlined /> Template
          </Button>
        </Collapse.Panel>
      </Collapse>

      <Collapse expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}>
        <Collapse.Panel header="Filters" key="filters">
          <WeightDisputeFilters
            filters={filters} 
            onFiltersChange={filters => setFilters(filters)} 
          />
        </Collapse.Panel>
      </Collapse>

      <Row className="mt-2 mb-2" justify="space-between">
        <Col>
          <Pagination 
            total={pagination?.totalSize} 
            pageSize={pagination?.limit}
            onChange={((currentPage, limit) => { setPagination({ ...pagination, limit, currentPage }) })}
            current={pagination.currentPage} 
          />
        </Col>

        <span color="blue">
          <Button onClick={exportDownload} loading={loading.export}>
            Export <DownloadOutlined></DownloadOutlined>
          </Button>
        </span>
      </Row>
      
        <WeightDisputeList
          weightDisputes={weightDisputes}
          loading={loading.index}
        />

        <Pagination 
            total={pagination?.totalSize} 
            pageSize={pagination?.limit}
            onChange={((currentPage, limit) => { setPagination({ ...pagination, limit, currentPage }) })}
            current={pagination.currentPage} 
        />
    </div>
  )
}

export default WeightDispute