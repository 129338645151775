import React, { useEffect, useState, useCallback } from "react";
import "react-vis/dist/style.css";
import {
  Breadcrumb,
  Row,
  Col,
  Tabs,
  Table,
  Collapse,
  Pagination,
  Tooltip,
} from "antd";
import axios from "axios";
import { CaretRightOutlined } from "@ant-design/icons";

import WeightDispute from "../Transactions/WeightDispute";
import { formatDateTime } from "../../helpers/date";
import ServiceProviderTag from "../shared/ServiceProviderTag";
import TransactionFilters from "../Transactions/TransactionFilters";
import { TransactionButtons } from "../Transactions/TransactionButtons.js";

const routes = [
  { path: "", breadcrumbName: "App" },
  { path: "/transaction", breadcrumbName: "Transaction" },
];

const table = [
  {
    title: "ID",
    dataIndex: "objectId",
  },
  {
    title: "Reference Id",
    dataIndex: "ref_no",
    key: "ref_no",
    render: (text) => (text ? text : "-"),
  },
  {
    title: "Amount",
    dataIndex: "amount",
  },
  {
    title: "Method",
    dataIndex: "method",
  },
  {
    title: "Success At (YYYY-MM-DD)",
    dataIndex: "creditSuccessAt",
    render: (text) => (text ? formatDateTime(text) : "-"),
  },
  {
    title: "Remarks",
    dataIndex: "remarks",
    render: (text) => text || "-",
  },
];

const UserOnlinePayment = ({ merchantId, loading }) => {
  const [transactions, setTransactions] = useState([]);
  const [filters, setFilters] = useState({});
  const [pagination, setPagination] = useState({ currentPage: 1, limit: 10 });
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);

  const fetchTransactions = useCallback(async () => {
    setIsLoading(true);
    const params = {
      merchantId,
      ...(filters.startDate !== null && {
        startDate: filters.startDate?.startOf("day")?.toISOString(),
      }),
      ...(filters.endDate !== null && {
        endDate: filters.endDate?.endOf("day")?.toISOString(),
      }),
      ...(filters.search && {
        searchField: filters.searchField,
        search: filters.search,
      }),
      limit: pagination.limit,
      page: pagination.currentPage,
    };

    const res = await axios.get(`/api/transactions?types=Top Up`, {
      params,
    });

    setTransactions(res.data.data);
    setPagination(res.data.pagination);
    setIsLoading(false);
  }, [pagination.currentPage, pagination.limit, filters]);

  useEffect(() => {
    fetchTransactions();
  }, [pagination.currentPage, pagination.limit, fetchTransactions]);

  return (
    <div>
      <Collapse
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
      >
        <Collapse.Panel header="Filters" key="filters">
          <TransactionFilters
            filters={filters}
            onFiltersChange={(filters) => setFilters(filters)}
          />
        </Collapse.Panel>
      </Collapse>

      <div className="table mt-4">
        <Row className="mt-2 mb-2" justify="space-between">
          <TransactionButtons
            filters={{
              ...(filters.startDate !== null && {
                startDate: filters.startDate?.startOf("day")?.toISOString(),
              }),
              ...(filters.endDate !== null && {
                endDate: filters.endDate?.endOf("day")?.toISOString(),
              }),
              ...(filters.search && {
                searchField: filters.searchField,
                search: filters.search,
              }),
              pagination: pagination,
              merchantId,
              types: "Top Up",
            }}
          />
          <Col>
            <Pagination
              total={pagination?.totalSize}
              pageSize={pagination?.limit}
              onChange={(currentPage, limit) => {
                setPagination({ ...pagination, limit, currentPage });
              }}
              current={pagination.currentPage}
            />
          </Col>
        </Row>

        <Table
          className="table-section"
          columns={table}
          dataSource={transactions}
          pagination={false}
          scroll={{ x: true }}
          loading={loading}
        />

        <Row justify="end">
          <Col>
            <Pagination
              total={pagination?.totalSize}
              pageSize={pagination?.limit}
              onChange={(currentPage, limit) => {
                setPagination({ ...pagination, limit, currentPage });
              }}
              current={pagination.currentPage}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default UserOnlinePayment;
