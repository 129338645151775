import React, { useEffect, useState } from "react"
import axios from 'axios'
import { Col, Row, Breadcrumb, Collapse, Space } from "antd"
import { CaretRightOutlined } from '@ant-design/icons'

import PackagePricingTable from './PackagePricingTable'
import DragUpload from "../Form/DragUpload"

const PackagePricing = () => {
  const routes = [
    { path: "", breadcrumbName: "App" },
    { path: "/package/pricing", breadcrumbName: "Package Pricing" },
  ];

  const fetchPricing = async () => {
    setIsLoading(true)
    const res = await axios.get(`/api/packages/pricing`)
    setPricing(res.data.data)
    setIsLoading(false)
  }

  const [isLoading, setIsLoading] = useState(false)
  const [pricing, setPricing] = useState()
  useEffect(() => { fetchPricing() }, [])

  return (
    <div>
      <div className="header">
        <Row>
          <Col span={12}>
            <h2 className="detail-header-text">Package Pricing (Old)</h2>
          </Col>
          <Col span={12}>
            <Breadcrumb
              style={{ margin: "16px 0", textAlign: "right" }}
              routes={routes}
            />
          </Col>
        </Row>
      </div>

      <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
        { false && 
          <div className="upload">
            <Collapse expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}>
              <Collapse.Panel header="Upload New Pricing" key="upload">
                <DragUpload 
                  action={`${process.env.REACT_APP_SERVER_URL}/api/packages/pricing`} 
                  accept=".xls, .xlsx"
                  method="put"
                  onUploadSuccess={fetchPricing}
                />
              </Collapse.Panel>
            </Collapse>
          </div>
        }

        <div className="table">
          {pricing && <PackagePricingTable pricing={pricing} isLoading={isLoading} />}
        </div>
      </Space>
    </div>
  )
}

export default PackagePricing;