import React, { useState, useEffect, useCallback } from "react";
import { Col, Row, Pagination, Collapse, Form, Table } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";
import axios from "axios";
import PageHeader from "../Layout/PageHeader";
import moment from "moment";
import { Link } from "react-router-dom";
import UserBadgeTag from "../shared/UserBadgeTag";
import TopupPackageTag from "../shared/TopupPackageTag";
import { useParams } from "react-router-dom";
import ReferralAccountFilter from "./ReferralAccountFilter";

import { formatDateTime } from "../../helpers/date";

const endOfMonth = moment().endOf("month");
const startOfMonth = moment().startOf("month");

const RefereeAccountList = () => {
  const { id } = useParams();
  const [filters, setFilters] = useState({});
  const [selectedRows, setSelectedRows] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState({ currentPage: 1, limit: 10 });
  const [refereeAccount, setRefereeAccount] = useState();

  const fetchRefereeAccount = useCallback(async () => {
    setIsLoading(true);
    const params = {
      referralAccountId: id,
      ...(filters.startDate !== null && {
        startDate: filters.startDate?.startOf("day")?.toISOString(),
      }),
      ...(filters.endDate !== null && {
        endDate: filters.endDate?.endOf("day")?.toISOString(),
      }),
      ...(filters.search && {
        searchField: filters.searchField,
        search: filters.search,
      }),
      limit: pagination.limit,
      page: pagination.currentPage,
    };

    const res = await axios.get(`/api/users`, {
      params,
    });

    setRefereeAccount(res.data.data);
    setPagination(res.data.pagination);
    setIsLoading(false);
  }, [pagination.currentPage, pagination.limit, filters]);

  useEffect(() => {
    fetchRefereeAccount();
  }, [pagination.currentPage, pagination.limit, fetchRefereeAccount]);
  const userTable = [
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
      sorter: {
        compare: (a, b) => a.username.length - b.username.length,
        multiple: 1,
      },
      sortDirections: ["descend", "ascend"],
      render: (text, row) => <Link to={`/user/${row.id}`}>{text}</Link>,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Credit",
      dataIndex: "credit",
      key: "credit",
      sorter: {
        compare: (a, b) => a.credit - b.credit,
        multiple: 2,
      },
    },
    {
      title: "Total Topup",
      dataIndex: "totalTopup",
      key: "totalTopup",
      sorter: {
        compare: (a, b) => a.totalTopup - b.totalTopup,
        multiple: 3,
      },
    },
    {
      title: "Package",
      dataIndex: ["merchant", "topupPackage"],
      key: "topupPackage",
      render: (text) => (text ? <TopupPackageTag packageKey={text} /> : "-"),
    },
    {
      title: "Badge",
      dataIndex: "badge",
      key: "badge",
      render: (text, row) =>
        text ? <UserBadgeTag statusKey={text}>Verified</UserBadgeTag> : "-",
    },
    {
      title: "Total Usage",
      dataIndex: "totalUsage",
      key: "totalUsage",
      render: (text) => (text ? text.toFixed(2) : "-"),
      sorter: {
        compare: (a, b) => a.totalUsage - b.totalUsage,
        multiple: 2,
      },
    },
    {
      title: "Total Order",
      dataIndex: "totalNoOrder",
      key: "totalNoOrder",
      sorter: {
        compare: (a, b) => a.totalTopup - b.totalTopup,
        multiple: 3,
      },
    },
    {
      title: "Last Active Date",
      dataIndex: "lastDateActive",
      key: "lastDateActive",
      render: (text) => (text === "-" ? "-" : moment(text).fromNow()),
    },
    {
      title: "Referral Code",
      dataIndex: "referralCode",
      key: "referralCode",
      render: (text) => (text ? text : "-"),
      sorter: (a, b) => {
        a = a.referralCode || "";
        b = b.referralCode || "";
        return a.localeCompare(b);
      },
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Locked Tier",
      dataIndex: "lockedTier",
      key: "lockedTier",
    },
    {
      title: "Registered Date (YYYY-MM-DD)",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => (text ? formatDateTime(text) : "-"),
      sorter: {
        compare: (a, b) => a.totalTopup - b.totalTopup,
        multiple: 3,
      },
    },
  ];
  return (
    <div>
      <PageHeader
        title="Referee Account"
        childRoutes={[
          { path: "/referee-account", breadcrumbName: "Referee Account" },
        ]}
      />
      <Collapse
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
      >
        <Collapse.Panel header="Filters" key="filters">
          <ReferralAccountFilter
            filters={filters}
            onFiltersChange={(filters) => setFilters(filters)}
          />
        </Collapse.Panel>
      </Collapse>
      <br />
      <Table
        rowKey="objectId"
        className="table-section"
        columns={userTable}
        dataSource={refereeAccount}
        pagination={false}
        scroll={{ x: true }}
        loading={isLoading}
      />
      <br />
      <Row justify="end">
        <Col>
          <Pagination
            total={pagination?.totalSize}
            pageSize={pagination?.limit}
            onChange={(currentPage, limit) => {
              setPagination({ ...pagination, limit, currentPage });
            }}
            current={pagination.currentPage}
          />
        </Col>
      </Row>
    </div>
  );
};

export default RefereeAccountList;
