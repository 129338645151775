import React, { useState } from "react";
import { DatePicker, Space } from "antd";

const ExportFilters = ({ filters, onFiltersChange, type }) => {
  return (
    <Space size="large">
      {type === "topup" ? (
        <React.Fragment>
          <div>
            Topup Before :{" "}
            <DatePicker.RangePicker
              value={[filters.startBeforeDate, filters.endBeforeDate]}
              onChange={(dates) =>
                onFiltersChange({
                  ...filters,
                  startBeforeDate: dates ? dates[0] : undefined,
                  endBeforeDate: dates ? dates[1] : undefined,
                })
              }
            />
          </div>
          <div>
            Topup After :{" "}
            <DatePicker.RangePicker
              value={[filters.startAfterDate, filters.endAfterDate]}
              onChange={(dates) =>
                onFiltersChange({
                  ...filters,
                  startAfterDate: dates ? dates[0] : undefined,
                  endAfterDate: dates ? dates[1] : undefined,
                })
              }
            />
          </div>
        </React.Fragment>
      ) : (
        <div>
          Order At :{` `}
          <DatePicker.RangePicker
            value={[filters.startDate, filters.endDate]}
            onChange={(dates) =>
              dates
                ? onFiltersChange({
                    ...filters,
                    startDate: dates[0],
                    endDate: dates[1],
                  })
                : onFiltersChange({
                    ...filters,
                    startDate: undefined,
                    endDate: undefined,
                  })
            }
          />
        </div>
      )}
    </Space>
  );
};

export default ExportFilters;
