import React, { useState, useEffect } from "react";
import { Row, Col, Card, Spin, message } from "antd";
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, registerables } from 'chart.js';
import axios from "axios";
import moment from 'moment'

ChartJS.register(...registerables);

const thirtyDaysAgo = moment().subtract(30, 'days').startOf('day')
const today = moment().startOf('day')

const Dashboard = () => {
  const [dailyOrderStats, setDailyOrderStats] = useState([])
  const [loading, setLoading] = useState({ order: false })

  useEffect(() => {
    const fetchDailyOrderStats = async () => {
      setLoading(prevLoading => ({ ...prevLoading, order: true }))

      try {
        const res = await axios.get(`/api/analysis/daily-order`, {
          params: { startDate: thirtyDaysAgo.toISOString() },
        })
        setDailyOrderStats(res.data.data)
      } catch (error) {
        message.error(error.response?.data?.message || error.message, 0);
      }

      setLoading(prevLoading => ({ ...prevLoading, order: false }))
    }

    fetchDailyOrderStats()
  }, [])

  const todayOrderStat = dailyOrderStats.find(stat => 
    stat.year === today.year() && 
    stat.month === (today.month() + 1) && 
    stat.day === today.date()
  )

  return (
    <React.Fragment>
      <Row gutter={16}>
        <Col span={8}>
          <Card title="Orders Count Today" bordered={false}>
          <strong>{todayOrderStat?.totalCount || "-"}</strong>
          </Card>
        </Col>
        <Col span={8}>
          <Card title="Orders Total Today" bordered={false}>
            RM <strong>{todayOrderStat?.totalPrice || "-"}</strong>
          </Card>
        </Col>
        <Col span={8}>
          <Card title="COD Total Today" bordered={false}>
            RM (+SGD) <strong>{todayOrderStat?.totalCod || "-"}</strong>
          </Card>
        </Col>
      </Row>

      <Row gutter={16} className="mt-4">
        <Col md={12}>
          <Card title="Last 30 Days Orders Count">
          {loading.order 
            ? <Spin /> 
            : <Line
              data={{
                labels: dailyOrderStats.map(stat => `${stat.year}-${stat.month}-${stat.day}`),
                datasets: [{
                  backgroundColor: 'rgb(255, 99, 132)',
                  borderColor: 'rgb(255, 99, 132)',
                  data: dailyOrderStats.map(stat => stat.totalCount),
                }]
              }}
              options={{
                plugins: {
                  legend: {
                    display: false
                  }
                }
              }}
            />}
          </Card>
        </Col>
        <Col md={12}>
          <Card title="Last 30 Days Orders Total">
          {loading.order 
            ? <Spin /> 
            : <Line
              data={{
                labels: dailyOrderStats.map(stat => `${stat.year}-${stat.month}-${stat.day}`),
                datasets: [{
                  backgroundColor: 'rgb(255, 99, 132)',
                  borderColor: 'rgb(255, 99, 132)',
                  data: dailyOrderStats.map(stat => stat.totalPrice),
                }]
              }}
              options={{
                plugins: {
                  legend: {
                    display: false
                  }
                }
              }}
            />}
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default Dashboard;
