import React from "react";
import { connect } from "react-redux";
import "./Insurance.css";
import "react-vis/dist/style.css";
import {
  SearchOutlined,
} from "@ant-design/icons";
import moment from "moment";
import {
  Button,
  Breadcrumb,
  Row,
  Col,
  Modal,
  Input,
  Popover,
  Space,
  Radio,
  message,
  InputNumber,
  Table,
  Popconfirm,
  Pagination,
  DatePicker
} from "antd";

import axios from "axios";
class Insurance extends React.Component {
  state = {
    data: [],
    userData:[],
    pagination: {
      current: 1,
      pageSize: 10,
    },
    visible: false,
    confirmLoading: false,
    loading: false,
    total: 0,
    adminSearch: {
      consign_no: null,
      status: null,
      insurance: [null,null],
      date: null
    },
    sortOrder: 'desc',
    insuranceList: [],
    previewVisible: false,
    internalNote: '',
    originalNote: '',
    editedId: '',
    userId: {},
    header: {
      Authorization: "Bearer " + this.props.token,
    },
  };

  componentDidMount = () => {
      this.getInsuranceList();
  };

  getInsuranceList = () => {
    this.setState({ loading: true});
    axios
    .get(`/api/insurances/admin`,{
      headers: {
        Authorization: "Bearer " + this.props.token, //the token is a variable which holds the token
      },
      params: {
        limit: this.state.pagination.pageSize,
        page: this.state.pagination.current,
        order: this.state.sortOrder === "ascend" ? "ascending" : this.state.sortOrder,
        ...(this.state.adminSearch.consign_no && {consign_no: this.state.adminSearch.consign_no}),
        ...(this.state.adminSearch.status && {status: this.state.adminSearch.status}),
        ...(this.state.adminSearch.insurance[0] && {insuranceMin: this.state.adminSearch.insurance[0]}),
        ...(this.state.adminSearch.insurance[1] && {insuranceMax: this.state.adminSearch.insurance[1]}),
        ...(this.state.adminSearch.date && { startDate: moment(this.state.adminSearch.date[0].toISOString()).subtract(1,'d').toISOString().substring(0, 10) + "T16:00:00.000Z" }),
        ...(this.state.adminSearch.date && { endDate: moment(this.state.adminSearch.date[1]).toISOString().substring(0, 10) + "T16:00:00.000Z" }),
      }
    }).then((res)=>{
      const insuranceList = res.data.insurances;
      this.setState({
        total: insuranceList[0]?.count.total
      })
      insuranceList.forEach(element => {
        element.insurance._created_at = moment(element.insurance._created_at).format("MMMM Do YYYY, h:mm a"); 
        element.insurance.shortRemarks = element.insurance.remarks.length > 50 ? element.insurance.remarks.substring(0,50) + '...' : element.insurance.remarks;
      }); 
      this.setState({
         insuranceList,
         loading: false,
       })
    }).catch((_err)=>{
       message.error("Error in retrieving insurance list")
    })
  };

  handleCancel = () =>{
    this.setState({visible: false});
    this.clearModal();
  };

  internalNote = (e) =>{
    this.setState({internalNote: e.target.value});
  };

  handleSearch = (selectedKeys, functionCall, dataIndex) => {
      if (dataIndex === "insurance.order.consign_no"){
        this.setState({ adminSearch: { 
          ...this.state.adminSearch, 
          consign_no: selectedKeys[0]
        }}) 
      }else if (dataIndex === "insurance.order.insurance"){
        this.setState({ adminSearch: { 
          ...this.state.adminSearch, 
          insurance: selectedKeys
        }}) 
      }else if (dataIndex === "insurance.status"){
        this.setState({ adminSearch: { 
          ...this.state.adminSearch, 
          status: selectedKeys[0] ? selectedKeys[0] : null
        }})         
      }
      else if (dataIndex === "insurance._created_at"){
        this.setState({ adminSearch: { 
          ...this.state.adminSearch, 
          date: selectedKeys
        }})         
      }

      this.setState({
        pagination:{
          ...this.state.pagination,
          current: 1
        }
      })
      functionCall();
      setTimeout(() => {
        this.getInsuranceList();      
     }, 1);
  };

  clearModal = () =>{
    this.setState({
      editedId: "",
      internalNote: "",
      originalNote: ""
    })
  }

  editNote = (note,id)=>{
    this.setState({
      editedId: id,
      visible: true,
      internalNote: note
    })
  }

  edit = async (req,id)=>{
    await axios.put(`/api/insurances/edit/${id}`,{
      ...req
    },{
    headers: {
      Authorization: "Bearer " + this.props.token, //the token is a variable which holds the token
    },        
    }).then((_res)=>{
      this.getInsuranceList();
      message.success(`Successfully ${_res.data.success.status}`)    
    }).catch((_err)=>{
      message.error(`Failed due to error`)
    })
  } 

  pageChange = (pageNum)=>{
    this.state.pagination.current = pageNum
    this.getInsuranceList();
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleSearch([null], clearFilters, dataIndex)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
  });

  onChange = (_newPagination, _filters, sorter) => {
    this.setState({
      sortOrder: sorter.order,
      pagination:{
        ...this.state.pagination,
        current: 1
      }
    })
    setTimeout(() => {
      this.getInsuranceList();      
    }, 1);
  };

  getStatusSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Space>
          <Radio.Group onChange={async (e) =>{
              await setSelectedKeys(e.target.value ? [e.target.value] : [])           
              this.setState({ adminSearch: { 
                ...this.state.adminSearch, 
                status: e.target.value ? e.target.value : null
              }})    
            }            
          } value={this.state.adminSearch.status} buttonStyle="solid">
            <Radio.Button value="approved">Approved</Radio.Button>
            <Radio.Button value="pending">Pending</Radio.Button>
            <Radio.Button value="rejected">Rejected</Radio.Button>
          </Radio.Group>
        </Space>
        <br></br>
        <br></br>
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleSearch([null], clearFilters, dataIndex)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
  });

  getInsuranceSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Space>
          <InputNumber
            min={1}
            ref={(node) => {
              this.searchInput = node;
            }}
            placeholder="Min"
            value={selectedKeys[0]}
            onChange={(e) => {
                if (e && selectedKeys.length > 0){
                  selectedKeys[0] = e > selectedKeys[1] ? selectedKeys[1] - 1 : e;
                  setSelectedKeys(selectedKeys)
                }
                else if (e && selectedKeys.length < 1){
                  selectedKeys = [e];
                  setSelectedKeys(selectedKeys)
                }              
              }             
            }
            onPressEnter={() =>
              this.handleSearch(selectedKeys, confirm, dataIndex)
            }
            style={{ marginBottom: 8, display: "block" }}
          />
           <InputNumber
            min={2}
            ref={(node) => {
              this.searchInput = node;
            }}
            placeholder="Max"
            value={selectedKeys[1]}
            onChange={(e) => {
                if (e && selectedKeys.length > 0){
                  selectedKeys[1] = e < selectedKeys[0] ? selectedKeys[0] + 1 : e;
                  setSelectedKeys(selectedKeys)
                }
                else if (e && selectedKeys.length < 1){
                  selectedKeys = [1,e]
                  setSelectedKeys(selectedKeys)
                }              
              }             
            }
            onPressEnter={() =>
              this.handleSearch(selectedKeys, confirm, dataIndex)
            }
            style={{ marginBottom: 8, display: "block" }}
          />
        </Space>
        <br></br>
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleSearch([null], clearFilters, dataIndex)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
  });

  getDateSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Space>
          <DatePicker.RangePicker
            min={1}
            ref={(node) => {
              this.searchInput = node;
            }}
            value={selectedKeys}
            onChange={(e) => {
                if (e){
                  setSelectedKeys(e)
                }       
              }             
            }
            onPressEnter={() =>
              this.handleSearch(selectedKeys, confirm, dataIndex)
            }
            style={{ marginBottom: 8, display: "block" }}
          />
        </Space>
        <br></br>
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleSearch(null, clearFilters, dataIndex)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
  });

  handleOk = async () => {
    if (this.state.internalNote){
    
      this.setState({confirmLoading: true});

      await axios.put(`/api/insurances/edit/${this.state.editedId}`,{
          internalNote: this.state.internalNote
        },{
        headers: {
          Authorization: "Bearer " + this.props.token, //the token is a variable which holds the token
        },        
        }).then((_res)=>{
          this.getInsuranceList();
          this.clearModal();
          this.handleCancel();
          this.setState({confirmLoading: false});

          message.success(`Note saved successfully`)    
        }).catch((err)=>{
           this.setState({confirmLoading: false});
           message.error("Failed to edit due to error")
        })
    }  
    else{
      message.error(`Please complete the field before proceed`);
    }
  };

  render = () =>{
    const {loading, insuranceList } = this.state;

    const routes = [
      {
        path: "",
        breadcrumbName: "App",
      },
      {
        path: "/insurance",
        breadcrumbName: "Insurance",
      },
    ];
    const columns = [
      {
        title: "Tracking No",
        dataIndex: ["insurance","order","consign_no"],
        fixed: "left",
        ...this.getColumnSearchProps("insurance.order.consign_no"),
      },
      {
        title: "Status",
        dataIndex: ["insurance","status"],      
        ...this.getStatusSearchProps("insurance.status"),  
      },
      {
        title: "Insurance Amount",
        dataIndex: ["insurance","order","insurance"],
        ...this.getInsuranceSearchProps("insurance.order.insurance"),
      },
      {
        title: "Remarks",
        dataIndex: ["insurance","remarks"],
        render: (_text,record) => { 
            if (_text.length > 50){
              return (
                <Popover content={_text} title="Remarks" trigger="hover">
                  <p>{ record.insurance.shortRemarks }</p>
                </Popover>
              )
            }else{
              return (<p>{ _text }</p>)
            }
          },       
      },
      {
        title: "Date/Time",
        dataIndex: ["insurance","_created_at"],
        sorter: true,
        ...this.getDateSearchProps("insurance._created_at")
      },
      {
        title: "Actions",
        key: "action",
        render: (_text,record) =>{
          if (record.insurance.status === "pending"){
            return (<Space>
             <Button onClick={() => this.editNote(record.insurance.internalNote,record.insurance._id)}>Note</Button>
              <Popconfirm
                title="Are you sure to approve this claim?"
                onConfirm={(() =>this.edit({status: "approved"},record.insurance._id))}
                okText="Yes"
                cancelText="No"
              >
                <Button type="primary">Approve</Button>
              </Popconfirm>
              <Popconfirm
                title="Are you sure to reject this claim?"
                onConfirm={(() =>this.edit({status: "rejected"},record.insurance._id))}
                okText="Yes"
                cancelText="No"
              >
                <Button type="primary" danger>Reject</Button>
              </Popconfirm>
          </Space>)
        }else{
          return (<Space>
                    <Button onClick={() => this.editNote(record.insurance.internalNote,record.insurance._id)}>Note</Button>
                  </Space>)
        }
      }
      }
    ];
    return (
      <>
        <div className="header">
        <Row>
          <Col span={12}>
            <h2 className="detail-header-text">Insurance Claims</h2>
          </Col>
          <Col span={12}>
            <Breadcrumb
              style={{ margin: "10px 0", textAlign: "right" }}
              routes={routes}
            />
          </Col>
        </Row>
      </div>
      <div className="table">
        <Table
            pagination={false}
            columns={columns.filter((col) => col.dataIndex !== "brn")}
            dataSource={insuranceList}
            onChange={this.onChange}
            scroll={{ x: true }}
            loading={loading}            
        />
        <Pagination onChange={this.pageChange} current={this.state.pagination.current} total={this.state.total} />
        <Modal
          title={"Internal Note"}
          visible={this.state.visible}
          onOk={this.handleOk}
          confirmLoading={this.state.confirmLoading}
          onCancel={this.handleCancel}
          width={520}
        > 
          <Input.TextArea rows={5} placeholder="Write your secret note" defaultValue={this.state.originalNote} value={this.state.internalNote} onChange={this.internalNote}/>
        </Modal>
      </div>
     </>
    );
  }
}

const mapStateToProps = (state) => ({
  token: state.auth.token,
});

export default connect(mapStateToProps)(Insurance);
