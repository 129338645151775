import React from 'react'
import { Tag } from 'antd'

const serviceProviders = [
    { key: 'poslaju', name: 'Poslaju', color: "magenta" },
    { key: 'dhl', name: 'DHL', color: "yellow" },
    { key: 'ninjavan', name: 'Ninjavan', color: "green" },
    { key: 'jnt', name: 'JNT', color: "geekblue" },
    { key: 'citylink', name: 'CityLink', color: "purple" },
    { key: 'pickupp', name: 'Pickupp', color: "gold" },
    { key: 'teleport', name: 'Teleport', color: "cyan" },
    { key: 'sfeconomy', name: 'SF Economy', color: "lime" },
    { key: 'sfstandard', name: 'SF Standard', color: "green" },
    { key: 'flash', name: 'Flash', color: "red" },
    { key: 'aramex', name: 'Aramex', color: "orange" },
    { key: 'lineclear', name: 'Lineclear', color: "teal" },
    { key: 'best', name: 'Best', color: "blue" },
    { key: 'imile', name: 'IMile', color: "gray" },
    { key: 'redly', name: 'Redly', color: "pink" },


]

const ServiceProviderTag = ({serviceProviderKey}) => {
  const serviceProvider = serviceProviders.find(serviceProvider => serviceProvider.key === serviceProviderKey)

  if (serviceProvider) {
    return <Tag color={serviceProvider.color}>{serviceProvider.name}</Tag>
  }

  return null
}

export default ServiceProviderTag