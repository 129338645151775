import React, { useState, useEffect, useCallback } from 'react'
import { Pagination, Row, Col, Collapse, Drawer, Modal,message , Button} from 'antd'
import moment from 'moment'
import { CaretRightOutlined, InfoCircleOutlined, DownloadOutlined } from '@ant-design/icons'
import axios from 'axios'

import PageHeader from '../Layout/PageHeader'
import OrdersList from './OrdersList'
import OrderButtons from './OrderButtons'
import OrderFilters from './OrderFilters'
import DragUpload from "../Form/DragUpload";

const today = moment()
const oneWeekAgo = moment().subtract(7, 'days')

const downloadTemplate = async () => {
  try {
    const { data } = await axios({
      url: "/api/order/template/bulkCancelTemplate",
      method: "GET",
      responseType: "blob",
      timeout: 1000 * 300
    });
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "template_bulk_cancel.xlsx");
    document.body.appendChild(link);
    link.click();
  } catch (err) {
    console.log("err", err);
  }
}

const AllOrder = () => {
  const [orders, setOrders] = useState([])
  const [filters, setFilters] = useState({ isRemittance: null, completeDelivered: null, startDate: oneWeekAgo, endDate: today })
  const [statusMaps, setStatusMaps] = useState({})
  const [drawerVisible, setDrawerVisible] = useState(false)
  const [pagination, setPagination] = useState({ currentPage: 1, limit: 10 })
  const [isLoading, setIsLoading] = useState(false)
  const [selectedRows, setSelectedRows] = useState([])
  const [bulkCancel, setBulkCancel] = useState();

  const fetchOrders = useCallback(async () => {
    setIsLoading(true)

    const params = {
      ...(filters.startDate !== null) && { startDate: filters.startDate?.startOf('day')?.toISOString() },
      ...(filters.endDate !== null) && { endDate: filters.endDate?.endOf('day')?.toISOString() },
      ...(filters.channel) && { channel: filters.channel },
      ...(filters.search && { searchField: filters.searchField, search: filters.search }),
      ...(filters.serviceProvider && { serviceProvider: filters.serviceProvider }),
      ...(filters.internalStatus && { internalStatus: filters.internalStatus }),
      limit: pagination.limit,
      page: pagination.currentPage
    }

    const res = await axios.get("/api/orders", { params })

    setOrders(res.data.orders)
    setPagination(res.data.pagination)
    setIsLoading(false)
  }, [pagination.currentPage, pagination.limit, filters])

  useEffect(() => {
    fetchOrders()
  }, [pagination.currentPage, pagination.limit, fetchOrders])

  useEffect(() => {
    const getStatusMaps = async () => {
      const statusRes = await axios.get('api/order/get-status-maps')
      setStatusMaps(statusRes.data.data)
    }

    getStatusMaps()
  }, [])

  const uploadSuccessHandler = (res) => {
    const trackingNo = res.data.data;
    Modal.confirm({
      title: `Are you sure to bulk cancel the order in this file ?`,
      onOk() {
        axios
          .post(`/api/order/bulk/cancel`, {
            params: {
              trackingNo: trackingNo,
            },
          })
          .then((res) => {
            setBulkCancel(res.data.data);
            message.info("The bulk parcel cancelation process is done");
          })
          .catch((error) => {
            message.error(error.response.data.message || error.message);
          });
      },
    });
  };
  const searchUploadSuccessHandler = (res) => {
        message.info(`Please check your email after 5-20 minute`)
  }
  
  return (
    <div>
      <PageHeader 
        title="All Order"
        childRoutes={[{ path: "/all-order", breadcrumbName: "All Order" }]}
        additionalInfo={() => 
          <React.Fragment>
            <InfoCircleOutlined onClick={() => setDrawerVisible(true)} />
            <Drawer title="Internal Status Mappings" placement="right" onClose={() => setDrawerVisible(false)} visible={drawerVisible} width={736}>
              <p>Internal statuses of Orders are mapped from Couriers statuses as follows:</p>
              { Object.keys(statusMaps).map(courier => {
                return (
                  <React.Fragment>
                    <h2>{courier.toUpperCase()}</h2>
                    <ul>
                    { Object.keys(statusMaps[courier]).map(externalStatus => {
                      return (
                        <li>{externalStatus} ➜ {statusMaps[courier][externalStatus]}</li>
                      )
                    }) }
                    </ul>
                  </React.Fragment>
                );
              }) }
            </Drawer>
          </React.Fragment>
        }
      />

      <Collapse
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
      >
        <Collapse.Panel header="Bulk Cancel Order" key="bulkCancel">
          {bulkCancel && (
            <Collapse
              className="mt-2"
              size="small"
              expandIcon={({ isActive }) => (
                <CaretRightOutlined rotate={isActive ? 90 : 0} />
              )}
            >
              <Collapse.Panel
                header={
                  <span>
                    <InfoCircleOutlined /> Results of Last Bulk
                  </span>
                }
                key="last-bulk-result"
              >
                <h4>
                  <strong>Refunded</strong>
                </h4>
                <ol>
                  {bulkCancel
                    .filter((result) => result.success)
                    .map((success) =>  (
                      <li key={success.consign_no}>
                        {success.consign_no}: RM {success.message} 
                      </li>
                    ))}
                </ol>
                <h4>
                  <strong>Failed</strong>
                </h4>
                <ol>
                  {bulkCancel
                    .filter((result) => !result.success)
                    .map((fail) => (
                      <li key={fail.consign_no}>
                        {fail.consign_no}: {fail.message}
                      </li>
                    ))}
                </ol>
              </Collapse.Panel>
            </Collapse>
          )}

          <DragUpload
            action={`${process.env.REACT_APP_SERVER_URL}/api/order/bulk/beforeCancel`}
            accept=".xls, .xlsx"
            method="post"
            onUploadSuccess={(res) => uploadSuccessHandler(res)}
          />
          <br />
          <Button onClick={downloadTemplate} className="mt-2">
            <DownloadOutlined /> Template
          </Button>
        </Collapse.Panel>
      </Collapse>

      <Collapse
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
      >
        <Collapse.Panel header="Bulk Search Order" key="bulkSearch">
          <DragUpload
            action={`${process.env.REACT_APP_SERVER_URL}/api/order/bulk/search/csv`}
            accept=".xls, .xlsx"
            method="post"
            onUploadSuccess={(res) => searchUploadSuccessHandler(res)}
            additionalDesc={`Excel file with "Search Tracking Number" columns.`}
          />
          <br />
        </Collapse.Panel>
      </Collapse>

      <Collapse expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}>
        <Collapse.Panel header="Filters" key="filters">
          <OrderFilters
            filters={filters} 
            onFiltersChange={filters => setFilters(filters)} 
          />
        </Collapse.Panel>
      </Collapse>

      <Row className="mt-2 mb-2" justify="space-between">
        <OrderButtons 
          selectedRowKeys={selectedRows}
          orders={orders}
          filters={{
            ...(filters.startDate !== null) && { startDate: filters.startDate?.startOf('day')?.toISOString() },
            ...(filters.endDate !== null) && { endDate: filters.endDate?.endOf('day')?.toISOString() },
            ...(filters.channel) && { channel: filters.channel },
            ...(filters.search && { searchField: filters.searchField, search: filters.search }),
            ...(filters.serviceProvider && { serviceProvider: filters.serviceProvider }),
            ...(filters.internalStatus && { internalStatus: filters.internalStatus }),
            pagination:pagination
          }}
          onRefunded={() => fetchOrders()}
        />

        <Col>
          <Pagination 
            total={pagination?.totalSize} 
            pageSize={pagination?.limit}
            onChange={((currentPage, limit) => { setPagination({ ...pagination, limit, currentPage }) })}
            current={pagination.currentPage} 
          />
        </Col>
      </Row>

      <OrdersList 
        orders={orders} 
        onSelectRows={selectedRowKeys => setSelectedRows(selectedRowKeys)}
        selectedRows={selectedRows}
        loading={isLoading}
        showButtons={{ download: true, markCancelledByUser: true, invoice: true }}
        onMarkCancelled={() => fetchOrders()}
      />

      <Row justify="end">
        <Col>
          <Pagination 
            total={pagination?.totalSize} 
            pageSize={pagination?.limit}
            onChange={((currentPage, limit) => { setPagination({ ...pagination, limit, currentPage }) })}
            current={pagination.currentPage} 
          />
        </Col>
      </Row>
    </div>
  )
}

export default AllOrder