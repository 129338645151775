import React, { useState, useEffect } from "react";
import { Row, Col, Card, Typography, Collapse, Spin, message } from "antd";
import { Line, Chart } from "react-chartjs-2";
import { Chart as ChartJS, registerables } from "chart.js";
import axios from "axios";
import moment from "moment";

import PageHeader from "../Layout/PageHeader";
import AnalysisFilters from "./AnalysisFilters";

ChartJS.register(...registerables);

const endOfThisMonth = moment().endOf("month");
const threeMonthsAgo = moment().subtract(3, "months").startOf("month");

const notificationTypes = [
  { key: "pickupWHATSAPP", name: "Pickup Whatsapp", color: "magenta" },
  { key: "pickupSMS", name: "Pickup Sms", color: "yellow" },
  { key: "pickupEMAIL", name: "Pickup Email", color: "green" },
  { key: "rescheduleWhatsapp", name: "Reschedule Whatsapp", color: "geekblue" },
];

const NotificationAnalysis = () => {
  const [filters, setFilters] = useState({
    startDate: threeMonthsAgo,
    endDate: endOfThisMonth,
  });
  const [monthlyNotificationUsage, setMonthlyNotificationUsage] = useState([]);
  const [loading, setLoading] = useState({ notify: false });

  useEffect(() => {
    const dateParams = {
      startDate: filters.startDate?.startOf("month")?.toISOString(),
      endDate: filters.endDate?.endOf("month")?.toISOString(),
    };

    const fetchMonthlyNotificationUsage = async () => {
      setLoading((prevLoading) => ({ ...prevLoading, notify: true }));

      try {
        const res = await axios.get(
          `/api/analysis/monthly-notification-usage`,
          {
            params: { ...dateParams },
            timeout: 1000 * 300,
          }
        );
        console.log(res.data.data);
        setMonthlyNotificationUsage(res.data.data);
      } catch (error) {
        message.error(error.response?.data?.message || error.message, 0);
      }

      setLoading((prevLoading) => ({ ...prevLoading, notify: false }));
    };

    fetchMonthlyNotificationUsage();
  }, [filters.startDate, filters.endDate]);

  // const uniqueNotifyTypes = [...new Set(monthlyNotificationUsage.flatMap((notification) => notification.data.map((notify) => notify.notifyType)))];
  return (
    <React.Fragment>
      <PageHeader title="Notifications Analytics" childRoutes={[]} />

      <Collapse>
        <Collapse.Panel
          header={`Filters (${filters.startDate.format(
            "MMM YYYY"
          )} - ${filters.endDate.format("MMM YYYY")})`}
          key="filters"
        >
          <AnalysisFilters
            filters={filters}
            onFiltersChange={(filters) => setFilters(filters)}
          />
        </Collapse.Panel>
      </Collapse>

      <Typography.Title level={4} className="mt-4">
        Notification
      </Typography.Title>
      <Row gutter={16} className="mt-4">
        <Col md={12}>
          <Card title="Notification Count">
            {loading.notify ? (
              <Spin />
            ) : (
              <Line
                data={{
                  labels: monthlyNotificationUsage[0]?.data?.map(
                    (stat) => `${stat.year}-${stat.month}`
                  ),
                  datasets: monthlyNotificationUsage.map(
                    (monthlyCourierOrder) => {
                      return {
                        label: monthlyCourierOrder.type,
                        data: monthlyCourierOrder.data.map(
                          (stat) => stat.orderCount
                        ),
                        borderColor: notificationTypes.find(
                          (sp) => sp.key === monthlyCourierOrder.type
                        )?.color,
                        backgroundColor: notificationTypes.find(
                          (sp) => sp.key === monthlyCourierOrder.type
                        )?.color,
                      };
                    }
                  ),
                }}
                options={{
                  interaction: {
                    mode: "index",
                  },
                }}
              />
            )}
          </Card>
        </Col>
        <Col md={12}>
          <Card title="Total Notification Price (MYR)">
            {loading.notify ? (
              <Spin />
            ) : (
              <Line
                data={{
                  labels: monthlyNotificationUsage[0]?.data?.map(
                    (stat) => `${stat.year}-${stat.month}`
                  ),
                  datasets: monthlyNotificationUsage.map(
                    (monthlyCourierOrder) => {
                      return {
                        label: monthlyCourierOrder.type,
                        data: monthlyCourierOrder.data.map(
                          (stat) => stat.totalPrice
                        ),
                        borderColor: notificationTypes.find(
                          (sp) => sp.key === monthlyCourierOrder.type
                        )?.color,
                        backgroundColor: notificationTypes.find(
                          (sp) => sp.key === monthlyCourierOrder.type
                        )?.color,
                      };
                    }
                  ),
                }}
                options={{
                  interaction: {
                    mode: "index",
                  },
                }}
              />
            )}
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default NotificationAnalysis;
