import React, { useState, useEffect } from "react";
import { Row, Col, Card, Typography, Collapse, Spin, message } from "antd";
import { Chart as ChartJS, registerables } from "chart.js";
import axios from "axios";
import moment from "moment";

import ChartRenderer from "./ChartRenderer";
import { useParams } from "react-router-dom";

ChartJS.register(...registerables);

const endOfThisMonth = moment().endOf("month");
const oneYearAgo = moment().subtract(12, "months").startOf("month");

const colors = [
  "magenta",
  "yellow",
  "green",
  "geekblue",
  "purple",
  "gold",
  "cyan",
  "lime",
  "green",
  "red",
];

const QuestionaireResult = () => {
  const [filters, setFilters] = useState({
    startDate: oneYearAgo,
    endDate: endOfThisMonth,
  });
  const { id } = useParams();
  const [result, setResult] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const dateParams = {
      startDate: filters.startDate?.startOf("month")?.toISOString(),
      endDate: filters.endDate?.endOf("month")?.toISOString(),
    };

    const fetchResult = async () => {
      setLoading(true);
      const res = await axios.get(`/api/questionaire/result/${id}`);
      setResult(res.data.data);
      setLoading(false);
    };

    fetchResult();
  }, [filters.startDate, filters.endDate]);

  return (
    <React.Fragment>
      {result.map((item, index) => {
        const feedbacks = item.feedbacks ?  item.feedbacks : []
        const hasValidData = item.counts || feedbacks.length > 0 

        return (
          <Row
            gutter={[16, 16]}
            justify="center"
            align="middle"
            style={{ margin: "16px 0" }}
            key={index}
          >
            <Col key={index} md={12}>
              <Card title={`${index + 1} - ${item.title}`}>
                {hasValidData ? (
                  <ChartRenderer type={item.type} data={item} index={index} />
                ) : (
                  <p>The response is empty</p>
                )}
              </Card>
            </Col>
          </Row>
        )
      })}
    </React.Fragment>
  );
};

export default QuestionaireResult;
