import React, { useState, useEffect } from "react"
import { Table, Popover, Typography } from 'antd'
import { InfoCircleOutlined } from "@ant-design/icons";

import { formatDateTime } from "../../helpers/date";
import ServiceProviderTag from '../shared/ServiceProviderTag'

const WeightDisputeList = props =>{
  const [weightDisputes, setWeightDisputes] = useState(props.weightDisputes)

  useEffect(() => {
    setWeightDisputes(props.weightDisputes);
  }, [props.weightDisputes])

  const table = [
    {
      title: "Merchant",
      dataIndex: ["user"],
      render: user => `${user.countryCode || ""}${user.phone || ""} (${user.email || ""})`
    },
    {
      title: "Courier",
      dataIndex: "serviceProvider",
      render: text => text ? <ServiceProviderTag serviceProviderKey={text} /> : ""
    },
    {
      title: "Tracking No",
      dataIndex: "consign_no",
    },
    {
      title: "Prev Weight (KG)",
      dataIndex: "prevWeight",
    },
    {
      title: "New Weight (KG)",
      dataIndex: "newWeight",
    },
    {
      title: "Weight Diff (KG)",
      dataIndex: "weightDiff",
      render: text => text 
        ? text > 0 
          ? <Typography.Text type="danger">+{text.toFixed(2)}</Typography.Text>
          : text.toFixed(2)
        : "-"
    },
    {
      title: "Prev Price (RM)",
      dataIndex: "prevPrice",
      render: (text, row) => text 
        ? <React.Fragment>
            {text.toFixed(2)}{` `}
            <Popover 
              title="Price Breakdown"
              content={<pre>{JSON.stringify(row.prevPrices, null, 2)}</pre>} 
              placement="bottom">
                <InfoCircleOutlined />
            </Popover> 
          </React.Fragment>
        : "-"
    },
    {
      title: "New Price (RM)",
      dataIndex: "newPrice",
      render: (text, row) => text 
        ? <React.Fragment>
            {text.toFixed(2)}{` `}
            <Popover 
              title="Price Breakdown"
              content={<pre>{JSON.stringify(row.newPrices, null, 2)}</pre>} 
              placement="bottom">
                <InfoCircleOutlined />
            </Popover> 
        </React.Fragment>
        : "-"
    },
    {
      title: "Price Diff (RM)",
      dataIndex: "priceDiff",
      render: text => text 
        ? text > 0 
          ? <Typography.Text type="danger">+{text.toFixed(2)}</Typography.Text>
          : text.toFixed(2)
        : "-"
    },
    {
      title: "Updated At (YYYY-MM-DD)",
      dataIndex: "createdAt",
      render: text => formatDateTime(text)
    },
  ];

  return (
      <div className="table">
        <Table
          rowKey="objectId"
          className="table-section"
          columns={table}
          dataSource={weightDisputes}
          pagination={false}
          scroll={{ x: true }}
          loading={props.loading}
        />
      </div>
    )
}

export default WeightDisputeList
