import React from "react"
import axios from 'axios'
import PropTypes from 'prop-types';
import { Upload, message, notification, Space } from "antd"
import { InboxOutlined, CloseCircleOutlined } from "@ant-design/icons"

const DragUpload = props => {
  const config = {
    name: props.name,
    multiple: true,
    disabled: props.disabled,
    accept: props.accept,
    onChange(info) {
      const { name, status, error, response } = info.file

      if (status === "done") {
        message.success(`${name} file uploaded successfully.`)
        if (typeof props.onUploadSuccess === 'function') {
          props.onUploadSuccess(response);
        }
      } else if (status === "error") {
        notification.open({
          message: 'Error',
          description: error.response?.data.message || error.message,
          duration: 0,
          icon: <CloseCircleOutlined style={{ color: 'red' }} />,
          style: { width: 500 }
        });
      }

      return false;
    },
    async customRequest(options) {
      const { onSuccess, onError, file } = options;

      const formData = new FormData();
      formData.append("file", file);

      try {
        const res = await axios({
          method: props.method,
          url: props.action,
          data: formData,
          timeout:  2 * 60 * 1000,
          headers: { "content-type": "multipart/form-data" }
        })
        onSuccess(res);
      } catch (err) {
        onError(err)
      }
    }
  };

  return (
    <div className="upload">
      <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
        <Upload.Dragger {...config}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Click or drag file to this area to upload
          </p>
          {props.additionalDesc && <p>({props.additionalDesc})</p>}
        </Upload.Dragger>
      </Space>
    </div>
  )
}

DragUpload.defaultProps = {
  name: "file",
  accept: ".xls, .xlsx, .csv",
  method: "post",
};

DragUpload.propTypes = {
  action: PropTypes.string.isRequired,
  name: PropTypes.string,
  accept: PropTypes.string,
  additionalDesc: PropTypes.string,
  method: PropTypes.oneOf(['post', 'put', 'patch']),
  onUploadSuccess: PropTypes.func,
  disabled: PropTypes.bool,
}

export default DragUpload