import React from 'react'
import { Tag, Tooltip } from 'antd'

const status = [
    { key: "New User", name: "New User", color: "green", title:"Registered last 7 days"},
    { key: "Active", name: "Active", color: "blue", title:"At least one order in the last 30 days or topup in last 30 days"},
    { key: "Inactive", name: "Inactive", color: "red", title:"No order in last 30 days and no topup in last 30 days"},
    { key: "Risky", name: "Risky", color: "cyan", title:"Active user that have no order in last 7 days and no topup in last 7 days"},
];
   
const UserStatusTag = ({statusKey}) => {

    const theStatus = status.find(theStatus => theStatus.key === statusKey)
    if (theStatus) {
      return(
        <React.Fragment>
          <Tooltip placement="topLeft" title={theStatus.title}>
           <Tag color={theStatus.color}>{theStatus.name}</Tag>
          </Tooltip>
        </React.Fragment>
      ) 
    }
    return null
  }
  
export default UserStatusTag