import React from "react"
import { Table, Select } from "antd"
import { startCase } from 'lodash'

const columns = packages => [
  { title: "Amount", dataIndex: "amount" },
  { title: "Bonus", dataIndex: "bonus" },
  { title: "Duration", dataIndex: "duration", render: text => `${text} days` },
  { 
    title: "Last Updated", 
    dataIndex: "updatedAt", 
    render: (text, record) => new Date(record.updatedAt).toLocaleString() 
  },
  { 
    title: "Package", 
    dataIndex: "package", 
    render: text => (
      <Select 
        defaultValue={text}
        style={{ width: 150 }}
      >
        { packages && packages.map(pkg => <Select.Option value={pkg}>{startCase(pkg)}</Select.Option>) }
      </Select>
    )
  },
];

const PackageList = ({ topupPackages, packages }) => {
  return (
    <div className="table">
      <Table
        rowKey="objectId"
        className="table-section"
        columns={columns(packages)}
        dataSource={topupPackages}
        pagination={false}
      />
    </div>
  )
}

export default PackageList