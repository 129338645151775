import React from "react"
import { DatePicker, Space, Select } from "antd"

const CodAnalysisFilters = ({filters, onFiltersChange}) => {
  return (
    <Space size="large">
      <div>
        Month Range :{` `}
        <DatePicker.RangePicker
          allowClear={false}
          allowEmpty={false}
          picker="month"
          value={[filters.startDate, filters.endDate]}
          onChange={dates => (dates 
              ? onFiltersChange({...filters, startDate: dates[0], endDate: dates[1]}) 
              : onFiltersChange({...filters, startDate: undefined, endDate: undefined})
          )}
        />
      </div>

      <div>
        COD Currency :{` `}
        <Select
          style={{ width: 120 }}
          defaultValue={filters.codCurrencyCode}
          onChange={value => onFiltersChange({...filters, codCurrencyCode: value})}
        >
          <Select.Option value="RM">RM</Select.Option>
          <Select.Option value="SGD">SGD</Select.Option>
        </Select>
      </div>
    </Space>
  )
}

export default CodAnalysisFilters;
