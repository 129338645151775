import React, { useState, useEffect } from 'react'
import { Table, Pagination, Row, Col, Tooltip } from "antd"
import axios from 'axios'

import { formatDateTime } from '../../helpers/date.js'
import ServiceProviderTag from '../shared/ServiceProviderTag.js'
import TransactionButtons from "./TransactionButtons.js";


const TransactionList = ({ globalFilters, types }) => {
  const [transactions, setTransactions] = useState()
  const [pagination, setPagination] = useState({ currentPage: 1, limit: 0 })
  const [filters] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const fetchTransactions = async () => {
      setIsLoading(true)
      const res = await axios.get(`api/transactions`, {
        params: {
          types: types,
          limit: pagination.limit,
          page: pagination.currentPage,
          sort: filters.sort,
          order: filters.order,
          status: filters.status,
          ...(globalFilters.search && { searchField: globalFilters.searchField, search: globalFilters.search }),
          ...(globalFilters.startDate !== null) && { startDate: globalFilters.startDate?.startOf('day')?.toISOString() },
          ...(globalFilters.endDate !== null) && { endDate: globalFilters.endDate?.endOf('day')?.toISOString() },
        }
      })
      setTransactions(res.data.data)
      setPagination(res.data.pagination)
      setIsLoading(false)
    }

    fetchTransactions()
  }, [pagination.currentPage, pagination.limit, filters, globalFilters])

  const columns = [
    {
      title: "Transaction ID",
      dataIndex: "objectId",
    },
    {
      title: "Merchant",
      dataIndex: "merchant",
      key: "username",
      render: text => <Tooltip title={ text?.user?.email || "-" }>{ text?.user?.username || "-"} </Tooltip>
    },
    {
      title: "Order ID",
      dataIndex: "order",
      key: "orderId",
      render: (text, row) => text?.objectId || row.ref_no || "-"
    },
    {
      title: "Tracking No",
      dataIndex: "order",
      key: "trackingNo",
      render: text => text?.consign_no || "-"
    },
    {
      title: "Courier",
      dataIndex: "order",
      render: (text, row) => text?.serviceProvider ? <ServiceProviderTag serviceProviderKey={text.serviceProvider} /> : ""
    },
    {
      title: "Amount",
      dataIndex: "amount",
      render: (text) => text ? text.toFixed(2) : "-"
    },
    {
      title: "Method",
      dataIndex: "method"
    },
    {
      title: "Remarks",
      dataIndex: "remarks"
    },
    {
      title: "Charged At (YYYY-MM-DD)",
      dataIndex: "creditSuccessAt",
      render: text => text ? formatDateTime(text) : "Failed"
    },
    {
      title: "Order Created At (YYYY-MM-DD)",
      dataIndex: "order",
      key: "createdAt",
      render: text => text?.createdAt ? formatDateTime(text.createdAt) : "-"
    },
  ];

  return (
    <React.Fragment>
     <Row className="mt-2 mb-2" justify="space-between">
            <TransactionButtons
            filters={{
              types : types,
              ...(globalFilters.startDate !== null) && { startDate: globalFilters.startDate?.startOf('day')?.toISOString() },
              ...(globalFilters.endDate !== null) && { endDate: globalFilters.endDate?.endOf('day')?.toISOString() },
              ...(globalFilters.search && { searchField: globalFilters.searchField, search: globalFilters.search }),
              pagination:pagination
            }}/>
        <Col>
          <Pagination 
            total={pagination?.totalSize} 
            pageSize={pagination?.limit}
            onChange={((currentPage, limit) => { setPagination({ ...pagination, limit, currentPage }) })}
            current={pagination.currentPage} 
          />
        </Col>
      </Row>

      <Table
        columns={columns}
        dataSource={transactions}
        pagination={false}
        loading={isLoading}
      />

      <Row justify="end">
        <Col>
          <Pagination 
            total={pagination?.totalSize} 
            pageSize={pagination?.limit}
            onChange={((currentPage, limit) => { setPagination({ ...pagination, limit, currentPage }) })}
            current={pagination.currentPage} 
          />
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default TransactionList