import React, { useState, useEffect, useCallback } from 'react'
import moment from 'moment'
import { Pagination, Row, Col, Collapse, Button, Modal, message } from 'antd'
import { CaretRightOutlined, DownloadOutlined } from '@ant-design/icons'
import axios from 'axios'

import PageHeader from '../Layout/PageHeader'
import CancelRequestFilters from './CancelRequestFilters'
import CancelRequestList from './CancelRequestList'

const today = moment()
const threeMonthsAgo = moment().subtract(3, 'months')

const CancelRequest = ({}) => {
  const [cancelRequest, setCancelRequest] = useState([])
  const [filters, setFilters] = useState({ startDate: threeMonthsAgo, endDate: today })
  const [pagination, setPagination] = useState({ currentPage: 1, limit: 10 })
  const [isLoading, setIsLoading] = useState(false);

  const fetchCancelRequest = useCallback(async () => {
    setIsLoading(true);
    const params = {
      ...(filters.startDate !== null) && { startDate: filters.startDate?.startOf('day')?.toISOString() },
      ...(filters.endDate !== null) && { endDate: filters.endDate?.endOf('day')?.toISOString() },
      ...(filters.reviewedStartDate !== null) && { reviewedStartDate: filters.reviewedStartDate?.startOf('day')?.toISOString() },
      ...(filters.reviewedEndDate !== null) && { reviewedEndDate: filters.reviewedEndDate?.endOf('day')?.toISOString() },
      ...(filters.search && { searchField: filters.searchField, search: filters.search }),
      ...(filters.serviceProvider && { serviceProvider: filters.serviceProvider }),
      ...(filters.status && { status: filters.status }),
      limit: pagination.limit,
      page: pagination.currentPage
    }
    const res = await axios.get("/api/cancel-requests/",{ params }) 
    setCancelRequest(res.data.data)
    setPagination(res.data.pagination)
    setIsLoading(false);
  }, [pagination.currentPage, pagination.limit, filters])

  useEffect(() => {
    fetchCancelRequest()
  }, [pagination.currentPage, pagination.limit, fetchCancelRequest])

  const downloadCSV = async () => {
    const params = {
      ...(filters.startDate !== null) && { startDate: filters.startDate?.startOf('day')?.toISOString() },
      ...(filters.endDate !== null) && { endDate: filters.endDate?.endOf('day')?.toISOString() },
      ...(filters.reviewedStartDate !== null) && { reviewedStartDate: filters.reviewedStartDate?.startOf('day')?.toISOString() },
      ...(filters.reviewedEndDate !== null) && { reviewedEndDate: filters.reviewedEndDate?.endOf('day')?.toISOString() },
      ...(filters.search && { searchField: filters.searchField, search: filters.search }),
      ...(filters.serviceProvider && { serviceProvider: filters.serviceProvider }),
      ...(filters.status && { status: filters.status }),
    }

    await axios.get("/api/users/count/").then((res) => {
      const messageContent = (
        <p>
          Are you sure you want to download {pagination.totalSize} orders data ?<br/>
        </p>
      );

      Modal.confirm({
        content: messageContent,
        title: `Download Cancel Request Order List ? `,
        async onOk() {
          await axios
          .get("api/cancel-requests/export", { params })
            .then((res) => {
              message.info(`Please check your email after 5-20 minute`);
            })
            .catch((error) => {
              message.error(error.message);
            });
        },
      });
    });
  }

  return (
    <div>
      <PageHeader 
        title="Cancel Order Request"
        childRoutes={[{ path: "/cancel-request", breadcrumbName: "Cancel Request" }]}
      />

      <Collapse expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}>
        <Collapse.Panel header="Filters" key="filters">
          <CancelRequestFilters
            filters={filters} 
            onFiltersChange={filters => setFilters(filters)} 
          />
        </Collapse.Panel>
      </Collapse>

      <Row className="mt-2 mb-2" justify="space-between">
        <div style={{ marginBottom: 16, float: "right" }}>
          <Button onClick={downloadCSV}>
            CSV <DownloadOutlined></DownloadOutlined>
          </Button>
        </div>

        <Col>
          <Pagination 
            total={pagination?.totalSize} 
            pageSize={pagination?.limit}
            onChange={((currentPage, limit) => { setPagination({ ...pagination, limit, currentPage }) })}
            current={pagination.currentPage} 
          />
        </Col>
      </Row>
      
        <CancelRequestList 
          cancelRequest={cancelRequest}
          onApproveRejectedCancel={() => fetchCancelRequest()}
          loading={isLoading}
        />

      <Row justify="end">
        <Col>
          <Pagination 
            total={pagination?.totalSize} 
            pageSize={pagination?.limit}
            onChange={((currentPage, limit) => { setPagination({ ...pagination, limit, currentPage }) })}
            current={pagination.currentPage} 
          />
        </Col>
      </Row>
    </div>
  )
}

export default CancelRequest