import React, { useState } from 'react'
import { Row, Col, Card, Tooltip } from 'antd'

import './CodStats.css'

const mapCodOrderToFilters = {
  'all':  { isRemittance: null, completeDelivered: null, isCodRefunded: null },
  'pending' : { isRemittance: false, completeDelivered: false, isCodRefunded: false },
  'pendingRemittance': { isRemittance: false, completeDelivered: true, isCodRefunded: false },
  'returned': { isRemittance: false, completeDelivered: false, isCodRefunded: true },
  'remitted': { completeDelivered: null, isRemittance: true, isCodRefunded: false },
}

const CodStats = ({ stats, onSelect, currencyCode }) => {
  const [selected, setSelected] = useState()

  const handleSelect = selected => {
    setSelected(selected)

    onSelect(mapCodOrderToFilters[selected])
  }

  return (
    <Row className="mb-2 cod-stats" gutter={16}>
      <Col span={6}>
        <Card hoverable={true} onClick={() => handleSelect('pending')} className={`${selected === 'pending' ? "selected" : ""}`}>
            <div style={{ textAlign: "center", color: "#575757" }}>
            <p style={{ fontSize: 30 }}>PENDING</p>
            <p className="user-info">
                Count: <strong>{stats.pendingCod?.count}</strong>
                <br />
                Amount: <strong>{currencyCode} {stats.pendingCod?.amount}</strong>
            </p>
            </div>
        </Card>
      </Col>

      <Col span={6}>
        <Card hoverable={true} onClick={() => handleSelect('pendingRemittance')} className={`${selected === 'pendingRemittance' ? "selected" : ""}`}>
            <div style={{ textAlign: "center", color: "#575757" }}>
            <Tooltip title="PENDING REMITTANCE">
              <p style={{ fontSize: 30 }}>P. REMITTANCE</p>
            </Tooltip>
            <p className="user-info">
                Count: <strong>{stats.pendingRemittanceCod?.count}</strong>
                <br />
                Amount: <strong>{currencyCode} {stats.pendingRemittanceCod?.amount}</strong>
            </p>
            </div>
        </Card>
      </Col>

      <Col span={6}>
        <Card hoverable={true} onClick={() => handleSelect('returned')} className={`${selected === 'returned' ? "selected" : ""}`}>
            <div style={{ textAlign: "center", color: "#575757" }}>
            <p style={{ fontSize: 30 }}>RETURNED</p>
            <p className="user-info">
                Count: <strong>{stats.refundedCod?.count}</strong>
                <br />
                Amount: <strong>{currencyCode} {stats.refundedCod?.amount}</strong>
            </p>
            </div>
        </Card>
      </Col>

      <Col span={6}>
        <Card hoverable={true} onClick={() => handleSelect('remitted')} className={`${selected === 'remitted' ? "selected" : ""}`}>
            <div style={{ textAlign: "center", color: "#575757" }}>
            <p style={{ fontSize: 30 }}>REMITTED</p>
            <p className="user-info">
                Count: <strong>{stats.remittedCod?.count}</strong>
                <br />
                Amount: <strong>{currencyCode} {stats.remittedCod?.amount}</strong>
            </p>
            </div>
        </Card>
      </Col>
    </Row>
  )
}

export default CodStats