import moment from 'moment'

import config from '../config/app'

export const formatDateTime = date => {
    return moment(date?.iso || date).format(config.dateFormat)
}

export const formatDate = date => {
    return formatDateTime(date).substring(0, 10)
}