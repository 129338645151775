import React, { useState, useEffect, useCallback } from "react";
import { Pagination, Row, Col, Collapse, Drawer, Button } from "antd";
import moment from "moment";
import { CaretRightOutlined, InfoCircleOutlined, DownloadOutlined } from "@ant-design/icons";
import axios from "axios";

import PageHeader from "../Layout/PageHeader";
import OrdersList from "./OrdersList";
import OrderButtons from "./OrderButtons";
import ReturnedOrderFilters from "./ReturnedOrderFilters";
import DragUpload from "../Form/DragUpload";

const today = moment();
const oneWeekAgo = moment().subtract(7, "days");

const downloadTemplate = async () => {
  try {
    const { data } = await axios({
      url: "/api/order/template/bulkRefundCodTemplate",
      method: "GET",
      responseType: "blob",
      timeout: 1000 * 300
    });
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "template_bulkRefundCod.xlsx");
    document.body.appendChild(link);
    link.click();
  } catch (err) {
    console.log("err", err);
  }
}

const ReturnedOrder = () => {
  const [orders, setOrders] = useState([]);
  const [returnedStatuses, setReturnedStatuses] = useState([]);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [filters, setFilters] = useState({
    startDate: oneWeekAgo,
    endDate: today,
  });
  const [pagination, setPagination] = useState({ currentPage: 1, limit: 10 });
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [bulkResults, setBulkResults] = useState();

  const fetchOrders = useCallback(async () => {
    setIsLoading(true);

    const params = {
      activeOnly: true,
      isReturnRequested: true,
      ...(filters.codOnly && { codOnly: true }),
      ...(filters.startDate !== null && !filters.codRefundedStartDate  && {
        startDate: filters.startDate?.startOf("day")?.toISOString(),
      }),
      ...(filters.endDate !== null && !filters.codRefundedEndDate && {
        endDate: filters.endDate?.endOf("day")?.toISOString(),
      }),
      ...(filters.codRefundedStartDate !== null && {
        codRefundedStartDate: filters.codRefundedStartDate
          ?.startOf("day")
          ?.toISOString(),
      }),
      ...(filters.codRefundedEndDate !== null && {
        codRefundedEndDate: filters.codRefundedEndDate?.endOf("day")?.toISOString(),
      }),
      ...(filters.isNextDayRemittance !== null && {
        isNextDayRemittance: filters.isNextDayRemittance,
      }),
      ...(filters.isCodRefunded !== null && {
        isCodRefunded: filters.isCodRefunded,
      }),
      ...(filters.search && {
        searchField: filters.searchField,
        search: filters.search,
      }),
      limit: pagination.limit,
      page: pagination.currentPage,
    };

    const res = await axios.get("/api/orders", { params });

    setOrders(res.data.orders);
    setPagination(res.data.pagination);
    setIsLoading(false);
  }, [pagination.currentPage, pagination.limit, filters]);

  useEffect(() => {
    fetchOrders();
  }, [pagination.currentPage, pagination.limit, fetchOrders]);

  useEffect(() => {
    const getReturnedStatuses = async () => {
      const statusRes = await axios.get("api/order/get-returned-statuses");
      setReturnedStatuses(statusRes.data.data);
    };

    getReturnedStatuses();
  }, []);

  const uploadSuccessHandler = (res) => {
    setBulkResults(res.data.data);
  };

  return (
    <div>
      <PageHeader
        title="Returned Order"
        childRoutes={[
          { path: "/returned-order", breadcrumbName: "Returned Order" },
        ]}
        additionalInfo={() => (
          <React.Fragment>
            <InfoCircleOutlined onClick={() => setDrawerVisible(true)} />
            <Drawer
              title="What is Returned Order?"
              placement="right"
              onClose={() => setDrawerVisible(false)}
              visible={drawerVisible}
              width={736}
            >
              <p>
                Orders are labeled as <strong>Returned</strong> as long as any
                of following statuses ever received from Couriers:
              </p>
              {returnedStatuses.map((status) => (
                <li>{status}</li>
              ))}
            </Drawer>
          </React.Fragment>
        )}
      />

      <Collapse
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
      >
        <Collapse.Panel header="Bulk Refund COD" key="bulk-refund-cod">
          {bulkResults && (
            <Collapse
              className="mt-2"
              size="small"
              expandIcon={({ isActive }) => (
                <CaretRightOutlined rotate={isActive ? 90 : 0} />
              )}
            >
              <Collapse.Panel
                header={
                  <span>
                    <InfoCircleOutlined /> Results of Last Bulk
                  </span>
                }
                key="last-bulk-result"
              >
                <h4>
                  <strong>Refunded</strong>
                </h4>
                <ol>
                  {bulkResults
                    .filter((result) => result.success)
                    .map((success) => (
                      <li key={success.consign_no}>
                        {success.consign_no}: {success.message}
                      </li>
                    ))}
                </ol>
                <h4>
                  <strong>Failed</strong>
                </h4>
                <ol>
                  {bulkResults
                    .filter((result) => !result.success)
                    .map((fail) => (
                      <li key={fail.consign_no}>
                        {fail.consign_no}: {fail.message}
                      </li>
                    ))}
                </ol>
              </Collapse.Panel>
            </Collapse>
          )}

          <DragUpload
            action={`${process.env.REACT_APP_SERVER_URL}/api/orders/bulk-refund-cod`}
            accept=".xls, .xlsx"
            method="post"
            onUploadSuccess={(res) => uploadSuccessHandler(res)}
          />
          <br />
          <Button onClick={downloadTemplate} className="mt-2">
            <DownloadOutlined /> Template
          </Button>
        </Collapse.Panel>
      </Collapse>

      <Collapse
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
      >
        <Collapse.Panel header="Filters" key="filters">
          <ReturnedOrderFilters
            filters={filters}
            onFiltersChange={(filters) => setFilters(filters)}
          />
        </Collapse.Panel>
      </Collapse>

      <Row className="mt-2 mb-2" justify="space-between">
        <OrderButtons
          selectedRowKeys={selectedRows}
          orders={orders}
          filters={{
            activeOnly: true,
            isReturnRequested: true,
            ...(filters.codOnly && { codOnly: true }),
            ...(filters.startDate !== null && !filters.codRefundedStartDate && {
              startDate: filters.startDate?.startOf("day")?.toISOString(),
            }),
            ...(filters.endDate !== null && !filters.codRefundedEndDate && {
              endDate: filters.endDate?.endOf("day")?.toISOString(),
            }),
            ...(filters.codRefundedStartDate !== null && {
              codRefundedStartDate: filters.codRefundedStartDate
                ?.startOf("day")
                ?.toISOString(),
            }),
            ...(filters.codRefundedEndDate !== null && {
              codRefundedEndDate: filters.codRefundedEndDate?.endOf("day")?.toISOString(),
            }),
            ...(filters.isNextDayRemittance !== null && {
              isNextDayRemittance: filters.isNextDayRemittance,
            }),
            ...(filters.isCodRefunded !== null && {
              isCodRefunded: filters.isCodRefunded,
            }),
            ...(filters.search && {
              searchField: filters.searchField,
              search: filters.search,
            }),
            pagination:pagination
          }}
        />

        <Col>
          <Pagination
            total={pagination?.totalSize}
            pageSize={pagination?.limit}
            onChange={(currentPage, limit) => {
              setPagination({ ...pagination, limit, currentPage });
            }}
            current={pagination.currentPage}
          />
        </Col>
      </Row>

      <OrdersList
        orders={orders}
        onSelectRows={(selectedRowKeys) => setSelectedRows(selectedRowKeys)}
        selectedRows={selectedRows}
        loading={isLoading}
        showButtons={{ download: false, refundCod: true }}
        onCodRefunded={() => fetchOrders()}
      />

      <Row justify="end">
        <Col>
          <Pagination
            total={pagination?.totalSize}
            pageSize={pagination?.limit}
            onChange={(currentPage, limit) => {
              setPagination({ ...pagination, limit, currentPage });
            }}
            current={pagination.currentPage}
          />
        </Col>
      </Row>
    </div>
  );
};

export default ReturnedOrder;
