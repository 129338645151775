import React, { useEffect, useState } from "react";
import axios from "axios";
import { Breadcrumb, Row, Col, Pagination, Collapse } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";
import QuestionaireResultList from "./QuestionaireList";

const routes = [
  { path: "", breadcrumbName: "App" },
  { path: "/email", breadcrumbName: "Email" },
];

const QuestionaireDetails = () => {
  return (
    <div>
      ''
      <div className="header">
        <Row>
          <Col span={12}>
            <h2 className="detail-header-text">Questionaire List</h2>
          </Col>
          <Col span={12}>
            <Breadcrumb
              style={{ margin: "10px 0", textAlign: "right" }}
              routes={routes}
            />
          </Col>
        </Row>
      </div>
      <QuestionaireResultList />
    </div>
  );
};

export default QuestionaireDetails;
