import React, { useEffect, useState } from "react"
import { Breadcrumb, Row, Col } from "antd"
import axios from "axios"

import PackageList from './PackageList'

const routes = [
  { path: "", breadcrumbName: "App" },
  { path: "/package", breadcrumbName: "Package" }
];

const Package = () => {
  const [topupPackages, setTopupPackages] = useState();
  const [packages, setPackages] = useState();

  useEffect(() => {
    const fetchTopupPackages = async () => {
      const res = await axios.get(`/api/topup-packages`)
      setTopupPackages(res.data.data)
      setPackages(res.data.meta?.packages)
    }

    fetchTopupPackages()
  }, [])

  return (
    <div>
      <div className="header">
        <Row>
          <Col span={12}>
            <h2 className="detail-header-text">Packages</h2>
          </Col>
          <Col span={12}>
            <Breadcrumb
            style={{ margin: "10px 0", textAlign: "right" }}
            routes={routes}
          />
          </Col>
        </Row>
      </div>
      <PackageList topupPackages={topupPackages} packages={packages} />
    </div>
  )
}

export default Package;
