import React, { useState } from "react";
import { DatePicker, Space, Select, Input } from "antd";

const SubAccountFilter = ({
  filters,
  onFiltersChange,
  defaultField,
  tabName,
}) => {
  const [searchField, setSearchField] = useState(defaultField || "email");

  const searchBy = (
    <Select
      defaultValue="email"
      className="select-before"
      onChange={(value) => setSearchField(value)}
    >
      <Select.Option value="phoneNumber">Phone Number</Select.Option>
      <Select.Option value="fullName">Name</Select.Option>
      <Select.Option value="email">Email</Select.Option>
    </Select>
  );

  const searchTransactionBy = (
    <Select
      defaultValue="userEmail"
      className="select-before"
      onChange={(value) => setSearchField(value)}
    >
      <Select.Option value="userEmail">Email</Select.Option>
    </Select>
  );

  return (
    <Space size="large">
      {tabName ? (
        <div>
          Created At :{` `}
          <DatePicker.RangePicker
            value={[filters.startDate, filters.endDate]}
            onChange={(dates) =>
              dates
                ? onFiltersChange({
                    ...filters,
                    startDate: dates[0],
                    endDate: dates[1],
                  })
                : onFiltersChange({
                    ...filters,
                    startDate: undefined,
                    endDate: undefined,
                  })
            }
          />
        </div>
      ) : (
        <div>
          Topup Range Date :{` `}
          <DatePicker.RangePicker
            value={[filters.topupStartDate, filters.topupEndDate]}
            onChange={(dates) =>
              dates
                ? onFiltersChange({
                    ...filters,
                    topupStartDate: dates[0],
                    topupEndDate: dates[1],
                  })
                : onFiltersChange({
                    ...filters,
                    topupStartDate: undefined,
                    topupEndDate: undefined,
                  })
            }
          />
        </div>
      )}
      {tabName === "topup" ? (
        <div>
          Search :{` `}
          <Input.Search
            addonBefore={searchTransactionBy}
            placeholder="Search"
            onSearch={(term) =>
              onFiltersChange({ ...filters, search: term, searchField })
            }
          />
        </div>
      ) : (
        <div>
          Search :{` `}
          <Input.Search
            addonBefore={searchBy}
            placeholder="Search"
            onSearch={(term) =>
              onFiltersChange({ ...filters, search: term, searchField })
            }
          />
        </div>
      )}
    </Space>
  );
};

export default SubAccountFilter;
