import React, { useState, useEffect } from 'react'
import { Table, Pagination, Row, Col, Tooltip , Button, Modal, message, Collapse} from "antd"
import { UndoOutlined, CaretRightOutlined, DownloadOutlined, InfoCircleOutlined } from "@ant-design/icons";
import axios from 'axios'

import { formatDateTime } from '../../helpers/date'
import ServiceProviderTag from '../shared/ServiceProviderTag'
import TransactionButtons from "./TransactionButtons.js";
import DragUpload from "../Form/DragUpload";


const downloadTemplate = async () => {
  try {
    const { data } = await axios({
      url: "/api/order/template/bulkRefundWeightDisputeTemplate",
      method: "GET",
      responseType: "blob",
      timeout: 1000 * 300
    });
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "template_bulk_refund_weight_dispute.xlsx");
    document.body.appendChild(link);
    link.click();
  } catch (err) {
    console.log("err", err);
  }
}

const WeightDispute = ({ globalFilters, onWeightDisputeRefunded, showButtons = { refundWeightDispute: true } }) => {
  const [weightDisputes, setWeightDisputes] = useState()
  const [pagination, setPagination] = useState({ currentPage: 1, limit: 50 })
  const [filters] = useState({})

  useEffect(() => {
    const fetchWeightDisputes = async () => {
      const res = await axios.get('api/transactions?types=weight-dispute', {
        params: {
          limit: pagination.limit,
          page: pagination.currentPage,
          sort: filters.sort,
          order: filters.order,
          status: filters.status,
          ...(globalFilters.search && { searchField: globalFilters.searchField, search: globalFilters.search }),
          ...(globalFilters.startDate !== null) && { startDate: globalFilters.startDate?.startOf('day')?.toISOString() },
          ...(globalFilters.endDate !== null) && { endDate: globalFilters.endDate?.endOf('day')?.toISOString() },
        }
      })
      setWeightDisputes(res.data.data)
      setPagination(res.data.pagination)
    }

    fetchWeightDisputes()
  }, [pagination.currentPage, pagination.limit, filters, globalFilters])

  const columns = [
    {
      title: "Transaction ID",
      dataIndex: "objectId",
    },
    {
      title: "Merchant",
      dataIndex: "merchant",
      key: "username",
      render: text => <Tooltip title={ text?.user?.email || "-" }>{ text?.user?.username || "-"} </Tooltip>
    },
    {
      title: "Order ID",
      dataIndex: "order",
      key: "orderId",
      render: (text, row) => text?.objectId || row.ref_no || "-"
    },
    {
      title: "Tracking No",
      dataIndex: "order",
      key: "trackingNo",
      render: text => text?.consign_no || "-"
    },
    {
      title: "Courier",
      dataIndex: "order",
      render: (text, row) => text?.serviceProvider ? <ServiceProviderTag serviceProviderKey={text.serviceProvider} /> : ""
    },
    {
      title: "Amount",
      dataIndex: "amount",
      render: (text) => text ? text.toFixed(2) : "-"
    },
    {
      title: "Remarks",
      dataIndex: "remarks"
    },
    {
      title: "Charged At (YYYY-MM-DD)",
      dataIndex: "successAt",
      render: text => text ? formatDateTime(text) : "Failed"
    },
    {
      title: "Order Created At (YYYY-MM-DD)",
      dataIndex: "order",
      key: "createdAt",
      render: text => text?.createdAt ? formatDateTime(text.createdAt) : "-"
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_,row) =>  
      <React.Fragment>
        {showButtons.refundWeightDispute && 
          <Tooltip placement="topLeft" title="Refund Weight Dispute">
            <Button 
              shape="circle" 
             icon={<UndoOutlined style={{ fontSize: "20px" }} 
             onClick={() => refundWeightDisputeHandler(row)} />} 
             disabled={row.isRefunded}
           />
          </Tooltip>
        }
      </React.Fragment>,
      fixed: "right",
    },
  ];

  const uploadSuccessHandler = (res) => {
    message.info(`Please check your email after 5-20 minute`) 
  };


  const refundWeightDisputeHandler = payment => {
    const confirmationMessage = <p>
      Weight Dispute Charge: RM {payment.amount}<br/>
    </p>
  
    Modal.confirm({
      content: confirmationMessage,
      title: `Are you sure to refund Weight Dispute charge for this Order?`,
      onOk() {
        axios.post(`/api/transactions/${payment.objectId}/refundWeightDispute`).then(res => {
          onWeightDisputeRefunded()
          message.info(`Weight Dispute Charge for transaction (${payment.objectId}) has been refunded.`)     
        }).catch(error => {
          message.error(error.response.data.message || error.message)
        })
      },
    });
  }

  return (
    <React.Fragment>
      <Collapse
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
      >
        <Collapse.Panel header="Bulk Refund Weight Dispute" key="bulk-refund-weight-dispute">
          <DragUpload
            action={`${process.env.REACT_APP_SERVER_URL}/api/orders/bulk-refund-weight-dispute`}
            accept=".xls, .xlsx"
            method="post"
            onUploadSuccess={(res) => uploadSuccessHandler(res)}
          />
          <br />
          <Button onClick={downloadTemplate} className="mt-2">
            <DownloadOutlined /> Template
          </Button>
        </Collapse.Panel>
      </Collapse>
     <Row className="mt-2 mb-2" justify="space-between">
            <TransactionButtons
            filters={{
              types : "weight-dispute",
              ...(globalFilters.startDate !== null) && { startDate: globalFilters.startDate?.startOf('day')?.toISOString() },
              ...(globalFilters.endDate !== null) && { endDate: globalFilters.endDate?.endOf('day')?.toISOString() },
              ...(globalFilters.search && { searchField: globalFilters.searchField, search: globalFilters.search }),
              pagination:pagination
            }}/>
        <Col>
          <Pagination 
            total={pagination?.totalSize} 
            pageSize={pagination?.limit}
            onChange={((currentPage, limit) => { setPagination({ ...pagination, limit, currentPage }) })}
            current={pagination.currentPage} 
          />
        </Col>
      </Row>

      <Table
        columns={columns}
        dataSource={weightDisputes}
        pagination={false}
      />

      <Row justify="end">
        <Col>
          <Pagination 
            total={pagination?.totalSize} 
            pageSize={pagination?.limit}
            onChange={((currentPage, limit) => { setPagination({ ...pagination, limit, currentPage }) })}
            current={pagination.currentPage} 
          />
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default WeightDispute