import React, { useState, useEffect } from "react"
import { Table, Typography } from 'antd'

import { formatDateTime } from "../../helpers/date";

const WeightDisputeByMerchantList = props =>{
  const [weightDisputes, setWeightDisputes] = useState(props.weightDisputes)

  useEffect(() => {
    setWeightDisputes(props.weightDisputes);
  }, [props.weightDisputes])

  const table = [
    {
      title: "Merchant",
      dataIndex: ["user"],
      render: user => `${user.countryCode || ""}${user.phone || ""} (${user.email || ""})`
    },
    {
      title: "Current Credit",
      dataIndex: ["merchant", "credits"],
      render: credits => Number(credits) < 0 
        ? <Typography.Text type="danger">+{credits.toFixed(2)}</Typography.Text>
        : credits.toFixed(2)
    },
    {
      title: "Count",
      dataIndex: "count",
    },
    {
      title: "Max Weight Diff (RM)",
      dataIndex: "maxWeightDiff",
      render: text => text?.toFixed(2)
    },
    {
      title: "Total Weight Diff (RM)",
      dataIndex: "totalWeightDiff",
      render: text => <Typography.Text type="danger">{text?.toFixed(2)}</Typography.Text>
    },
    {
      title: "Max Price Diff (RM)",
      dataIndex: "maxPriceDiff",
      render: text => text?.toFixed(2)
    },
    {
      title: "Total Price Diff (RM)",
      dataIndex: "totalPriceDiff",
      render: text => <Typography.Text type="danger">{text?.toFixed(2)}</Typography.Text>
    },
    {
      title: "Last Dispute At (YYYY-MM-DD)",
      dataIndex: "lastCreatedAt",
      render: text => formatDateTime(text)
    },
  ];

  return (
      <div className="table">
        <Table
          rowKey="objectId"
          className="table-section"
          columns={table}
          dataSource={weightDisputes}
          pagination={false}
          scroll={{ x: true }}
          loading={props.loading}
        />
      </div>
    )
}

export default WeightDisputeByMerchantList
