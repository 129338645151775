import React, { useState, useCallback, useEffect } from 'react'
import { Row, Col, Pagination, Collapse, Space, Form, Button, Input, InputNumber, DatePicker, message, Modal } from "antd"
import { CaretRightOutlined,DownloadOutlined } from '@ant-design/icons'
import axios from "axios";

import PageHeader from '../Layout/PageHeader'
import DiscountCodeList from './DiscountCodeList';

const DiscountCode = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState({ create: false, index: false })
  const [loadings, setLoadings] = useState(false);

  const [discountCodes, setDiscountCodes] = useState([])
  const [pagination, setPagination] = useState({ currentPage: 1, limit: 50 })

  const fetchDiscountCodes = useCallback(async () => {
    setLoading(prevLoading => ({ ...prevLoading, index: true }))

    const params = {
      limit: pagination.limit,
      page: pagination.currentPage
    }
    const res = await axios.get("/api/discount-codes", { params }) 

    setDiscountCodes(res.data.data || [])
    setPagination(res.data.pagination)
    setLoading(prevLoading => ({ ...prevLoading, index: false }))
  }, [pagination.currentPage, pagination.limit])

  useEffect(() => {
    fetchDiscountCodes()
  }, [pagination.currentPage, pagination.limit, fetchDiscountCodes])

  const handleExport = async () => {
    try {
      const messageContent = <p>
      Are you sure you want to download the discount redemption data ?<br/>
      </p>

    Modal.confirm({
      content: messageContent,
      title: `Download Discount Redemption List ? `,
      async onOk() {
        await axios
          .get("/api/discount-codes/export").then(res => {
            message.info(`Please check your email after 5-20 minute`)     
          }).catch(error => {
            message.error(error.message)
          })
      },
    });

    } catch (error) {
      message.error(error.response?.data?.message || error.message)
    } 
  };

  const createHandler = async (newDiscountCode) => {
    try {
      await axios.post('/api/discount-codes', {
        ...newDiscountCode,
        validFrom: newDiscountCode.validityPeriod[0].startOf('day').toDate(),
        validTo: newDiscountCode.validityPeriod[1].endOf('day').toDate()
      })

      form.resetFields()
      fetchDiscountCodes()
    } catch (error) {
      message.error(error.response.data.message || error.message)
    }
  }

  const updateHandler = async (discountCodeId, discountCode) => {
    try {
      await axios.patch(`/api/discount-codes/${discountCodeId}`, {
        ...discountCode,
        validFrom: discountCode.validFrom.iso,
        validTo: discountCode.validTo.iso
      })
      message.success(`Discount Code updated.`)
    } catch (error) {
      message.error(error.response.data.message || error.message)
    }
  }

  return (
    <div>
      <PageHeader 
        title="Discount Code"
        childRoutes={[{ path: "/discount-code", breadcrumbName: "Discount Code" }]}
      />

      <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
        <div className="create-discount-code-container">
          <Collapse expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}>
            <Collapse.Panel header="Create New Code">
            <Form
              form={form}
              name="new-discount-code-form"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              autoComplete="off"
              onFinish={createHandler}
            >
              <Form.Item
                extra={`Alphanumeric Character with length from 6-15. Eg: KACAK10`}
                label="Discount Code"
                name="code"
                onInput={e => e.target.value = e.target.value.toUpperCase()}
                rules={[
                  { required: true },
                  { pattern: /^\w{6,15}$/, message: 'Format does not match.' }
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                extra={`Minimum total checkout value to apply this discount code.`}
                label="Minimum Order Value"
                name="minimumOrderValue"
                rules={[
                  { required: true },
                  { type: 'number', min: 1.00 }
                ]}
              >
                <InputNumber precision={2} />
              </Form.Item>

              <Form.Item
                extra={`Amount that can be discounted from Order total.`}
                label="Discount Value"
                name="discountValue"
                rules={[
                  { required: true },
                  { type: 'number', min: 1.00 }
                ]}
              >
                <InputNumber precision={2} />
              </Form.Item>

              <Form.Item
                extra={`Times that this discount count can be redeemed.`}
                label="Redeem Times"
                name="redeemTimes"
                rules={[
                  { required: true },
                  { type: 'number', min: 1 }
                ]}
              >
                <InputNumber step={1} precision={0} />
              </Form.Item>

              <Form.Item
                extra={`Period where this discount code is valid.`}
                label="Validity Period"
                name="validityPeriod"
                rules={[
                  { required: true },
                ]}
              >
                <DatePicker.RangePicker />
              </Form.Item>
              
              <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button type="primary" htmlType="submit">Create</Button>
              </Form.Item>
            </Form>
            </Collapse.Panel>
          </Collapse>
        </div>
      </Space>

      <Row className="mt-2 mb-2" justify="space-between">
      <Space>
          <span color="blue">
            <Button onClick={handleExport}>
              Export <DownloadOutlined></DownloadOutlined>
            </Button>
          </span>

          <span></span>
        </Space>
        <Col>
          <Pagination 
            total={pagination?.totalSize} 
            pageSize={pagination?.limit}
            onChange={((currentPage, limit) => { setPagination({ ...pagination, limit, currentPage }) })}
            current={pagination.currentPage} 
          />
        </Col>
      </Row>
      
        <DiscountCodeList 
          discountCodes={discountCodes}
          loading={loading.index}
          onUpdate={updateHandler}
        />
    </div>
  )
}

export default DiscountCode