import React, { useState } from "react"
import { Space, DatePicker, Select, Input } from "antd"

const WeightDisputeByMerchantFilters = ({filters, onFiltersChange}) => {
  const [searchField, setSearchField] = useState("userEmail")
  const searchBy = (
    <Select defaultValue="userEmail" className="select-before" onChange={value => setSearchField(value)}>
      <Select.Option value="userEmail">User Email</Select.Option>
    </Select>
  );

  return (
    <Space size="large">
      <div>
        <DatePicker.RangePicker
          value={[filters.startDate, filters.endDate]}
          onChange={dates => (dates 
              ? onFiltersChange({...filters, startDate: dates[0], endDate: dates[1]}) 
              : onFiltersChange({...filters, startDate: undefined, endDate: undefined})
          )}
        />
      </div>

      <div>
        <Input.Search 
          addonBefore={searchBy}
          placeholder="Search" 
          onSearch={term => onFiltersChange({...filters, search: term, searchField }) } 
        />
      </div>
    </Space>
  )
}

export default WeightDisputeByMerchantFilters;
