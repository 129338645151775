import React from 'react'
import { Tag } from 'antd'

const statuses = [
  { key: "normal", name: "Normal", color: "" },
  { key: "gold", name: "Gold", color: "gold" },
  { key: "platinum", name: "Platinum", color: "#E5E4E2" },

  { key: "membership", name: "Membership", color: "green" },
  { key: "first_5_kg_special", name: "First 5kg Special", color: "green" },
  { key: "fookai_6_50", name: "Fookai 6.50", color: "green" },
  { key: "super_membership", name: "Super Membership", color: "green" },
  { key: "dhl_rm_5_membership", name: "DHL RM5 Membership", color: "green" },
];
   
const TopupPackageTag = ({packageKey}) => {
  const status = statuses.find(status => status.key === packageKey)

  return <Tag color={status?.color || ''}>{status?.name || packageKey}</Tag>
}
  
export default TopupPackageTag