import React, { useState, useEffect, useCallback } from "react";
import {
  Col,
  Row,
  Breadcrumb,
  Space,
  Modal,
  message,
} from "antd";
import axios from "axios";

import "react-vis/dist/style.css";
import DragUpload from "../Form/DragUpload";

const uploadFlashBulkPOD = (res) => {
  try {
    const CSV = res.data.csv;
      window.URL = window.webkitURL || window.URL;
      var contentType = "text/csv";
      var csvFile = new Blob([CSV], { type: contentType });

      var a = document.createElement("a");
      a.download = "Flash POD.csv";
      a.href = window.URL.createObjectURL(csvFile);

      a.dataset.downloadurl = [contentType, a.download, a.href].join(":");
      a.click();
  } catch (error){
    message.error(error.response.data.message || error.message);
  }
    

};

const FlashPOD = () => {
  return (
    <Space direction="vertical" style={{ display: "flex" }}>
      <div className="header">
        <Row>
          <Col span={12}>
            <h2 className="detail-header-text">Flash POD</h2>
          </Col>
          <Col span={12}>
            <Breadcrumb style={{ margin: "10px 0", textAlign: "right" }} />
          </Col>
        </Row>
      </div>

      <DragUpload
        action={`${process.env.REACT_APP_SERVER_URL}/api/orders/pod`}
        accept=".xls, .xlsx"
        method="post"
        onUploadSuccess={(res) => uploadFlashBulkPOD(res)}
      />
    </Space>
  );
};

export default FlashPOD;
