import React, { useState, useEffect } from "react";
import { Row, Col, Card, Typography, Collapse, Spin, message } from "antd";
import { Line, Chart } from 'react-chartjs-2';
import { Chart as ChartJS, registerables } from 'chart.js';
import axios from "axios";
import moment from 'moment'

import PageHeader from "../Layout/PageHeader";
import AnalysisFilters from './AnalysisFilters'

ChartJS.register(...registerables);

const endOfThisMonth = moment().endOf('month')
const threeMonthsAgo = moment().subtract(3, 'months').startOf('month')

const serviceProviders = [
  { key: 'poslaju', name: 'Poslaju', color: "magenta" },
  { key: 'dhl', name: 'DHL', color: "yellow" },
  { key: 'ninjavan', name: 'Ninjavan', color: "green" },
  { key: 'jnt', name: 'JNT', color: "geekblue" },
  { key: 'citylink', name: 'CityLink', color: "purple" },
  { key: 'pickupp', name: 'Pickupp', color: "gold" },
  { key: 'teleport', name: 'Teleport', color: "cyan" },
  { key: 'sfeconomy', name: 'SF Economy', color: "lime" },
  { key: 'sfstandard', name: 'SF Standard', color: "green" },
  { key: 'flash', name: 'Flash', color: "red" },
  { key: 'imile', name: 'IMile', color: "gray" },
  { key: 'redly', name: 'Redly', color: "pink" },
]

const CourierAnalysis = () => {
  const [filters, setFilters] = useState({ startDate: threeMonthsAgo, endDate: endOfThisMonth })
  const [monthlyCourierOrderStats, setMonthlyCourierOrderStats] = useState([])
  const [loading, setLoading] = useState({ order: false })

  useEffect(() => {
    const dateParams = {
      startDate: filters.startDate?.startOf('month')?.toISOString(),
      endDate: filters.endDate?.endOf('month')?.toISOString(),
    }

    const fetchMonthlyCourierOrderStats = async () => {
      setLoading(prevLoading => ({ ...prevLoading, order: true }))

      try {
        const res = await axios.get(`/api/analysis/monthly-courier-order`, {
          params: { ...dateParams },
          timeout: 1000 * 300
        })
        setMonthlyCourierOrderStats(res.data.data)
      } catch (error) {
        message.error(error.response?.data?.message || error.message, 0);
      }

      setLoading(prevLoading => ({ ...prevLoading, order: false }))
    }

    fetchMonthlyCourierOrderStats()
  }, [filters.startDate, filters.endDate])

  return (
    <React.Fragment>
      <PageHeader title="Courier Order Analytics" childRoutes={[]} />

      <Collapse>
        <Collapse.Panel header={`Filters (${filters.startDate.format('MMM YYYY')} - ${filters.endDate.format('MMM YYYY')})`} key="filters">
          <AnalysisFilters
            filters={filters} 
            onFiltersChange={filters => setFilters(filters)} 
          />
        </Collapse.Panel>
      </Collapse>

      <Typography.Title level={4} className="mt-4">Orders</Typography.Title>
      <Row gutter={16}>
        <Col md={12}>
          <Card title="Order Count by Couriers">
          {loading.order 
            ? <Spin /> 
            : <Line
              data={{
                labels: monthlyCourierOrderStats[0]?.data?.map(stat => `${stat.year}-${stat.month}`),
                datasets: monthlyCourierOrderStats.map(monthlyCourierOrder => {
                  return {
                    label: monthlyCourierOrder.serviceProvider,
                    data: monthlyCourierOrder.data.map(stat => stat.orderCount),
                    borderColor: serviceProviders.find(sp => sp.key === monthlyCourierOrder.serviceProvider)?.color,
                    backgroundColor: serviceProviders.find(sp => sp.key === monthlyCourierOrder.serviceProvider)?.color
                  }
                })
              }}
              options={{
                interaction: {
                  mode: 'index'
                }
              }}
            />}
          </Card>
        </Col>

        <Col md={12}>
          <Card title="Total Order Price by Couriers (MYR)">
          {loading.order 
            ? <Spin /> 
            : <Line
              data={{
                labels: monthlyCourierOrderStats[0]?.data?.map(stat => `${stat.year}-${stat.month}`),
                datasets: monthlyCourierOrderStats.map(monthlyCourierOrder => {
                  return {
                    label: monthlyCourierOrder.serviceProvider,
                    data: monthlyCourierOrder.data.map(stat => stat.totalPrice),
                    borderColor: serviceProviders.find(sp => sp.key === monthlyCourierOrder.serviceProvider)?.color,
                    backgroundColor: serviceProviders.find(sp => sp.key === monthlyCourierOrder.serviceProvider)?.color
                  }
                })
              }}
              options={{
                interaction: {
                  mode: 'index'
                }
              }}
            />}
          </Card>
        </Col>
      </Row>

      <Row gutter={16} className="mt-4">
        <Col md={12}>
          <Card title="Overall Order (Total Price & Count)">
          {loading.order 
            ? <Spin /> 
            : <Chart
              type="line"
              data={{
                labels: monthlyCourierOrderStats[0]?.data?.map(stat => `${stat.year}-${stat.month}`),
                datasets: [{
                  label: 'Total Price (MYR)',
                  type: 'line',
                  backgroundColor: 'rgb(255, 99, 132)',
                  borderColor: 'rgb(255, 99, 132)',
                  yAxisID: 'A',
                  data: monthlyCourierOrderStats.map((_, index) => {
                    return monthlyCourierOrderStats.reduce((prev, curr) => prev + curr.data[index]?.totalPrice, 0);
                  }),
                },
                {
                  label: 'Count',
                  type: 'bar',
                  backgroundColor: 'rgb(132, 99, 255)',
                  borderColor: 'rgb(132, 99, 255)',
                  yAxisID: 'B',
                  data: monthlyCourierOrderStats.map((_, index) => {
                    return monthlyCourierOrderStats.reduce((prev, curr) => prev + curr.data[index]?.orderCount, 0);
                  }),
                }]
              }}
              options={{
                scales: {
                  A: {
                      type: 'linear',
                      position: 'left',
                      ticks: { color: 'rgb(255, 99, 132)' },
                  },
                  B: {
                      type: 'linear',
                      position: 'right',
                      ticks: { color: 'rgb(132, 99, 255)' },
                  }
                }
              }}
            />}
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default CourierAnalysis;
