import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import {
  Pagination,
  Table,
  Col,
  Row,
  Tooltip,
  Space,
  Switch,
  message,
  Button,
} from "antd";
import { formatDateTime } from "../../helpers/date";

const QuestionaireResponseList = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [questionaires, setQuestionaires] = useState(props.list);
  const [responses, setResponses] = useState([]);
  const [additionalCol, setAdditionalCol] = useState([]);
  const { id } = props;
  const [pagination, setPagination] = useState({ currentPage: 1, limit: 10 });
  const [filters, setFilters] = useState({});
  useEffect(() => {
    setQuestionaires(props.list);
    fetchResponse();
  }, [props.list]);

  const exportScv = () => {
    axios.get(`/api/questionaire/${id}/csv`);
  };

  const fetchResponse = useCallback(
    (questionaire, status) => {
      setIsLoading(true);

      const params = {
        limit: pagination.limit,
        page: pagination.currentPage,
      };

      axios
        .get(`/api/questionaire-response/${id}`, { params })
        .then((res) => {
          const data = res.data.data;
          setResponses(data);
          setPagination(res.data.pagination);

          const question = res.data.questions;
          const additionalCol = generateDynamicColumns(question);
          setAdditionalCol(additionalCol, data);
        })
        .catch((error) => {
          message.error(error.response || error.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [pagination.currentPage, pagination.limit, filters]
  );

  useEffect(() => {
    fetchResponse();
  }, [pagination.currentPage, pagination.limit, fetchResponse]);

  const generateDynamicColumns = (questions, data) => {
    const dynamicColumns = [];
    questions.map((question, i) => {
      dynamicColumns.push({
        title: question,
        dataIndex: `response${i + 1}`,
      });
    });

    return dynamicColumns;
  };

  const baseColumns = [
    {
      title: "Date",
      dataIndex: "date",
      render: (text) => <p>{formatDateTime(text)}</p>,
    },
    {
      title: "Username",
      dataIndex: "username",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Topup Package",
      dataIndex: "topupPackage",
    },
  ];

  const columns = [...baseColumns, ...additionalCol];

  return (
    <div className="table">
      <Button onClick={exportScv}>CSV</Button>
      <Table
        rowKey="objectId"
        className="table-section"
        columns={columns}
        dataSource={responses}
        pagination={false}
        scroll={{ x: true }}
      />
      <Col>
        <Pagination
          total={pagination?.totalSize}
          pageSize={pagination?.limit}
          onChange={(currentPage, limit) => {
            setPagination({ ...pagination, limit, currentPage });
          }}
          current={pagination.currentPage}
        />
      </Col>
    </div>
  );
};

export default QuestionaireResponseList;
