import React from "react";
import { Input, Select, Space } from "antd";

const EmailFilters = ({ filters, onFiltersChange }) => {
  const searchBy = (
    <Select
      defaultValue="recipient"
      className="select-before"
    >
      <Select.Option value="recipient">Recipient Email</Select.Option>
    </Select>
  );

  return (
    <Space size="large">
      <div>
        Search :{` `}
        <Input.Search
          addonBefore={searchBy}
          placeholder="Search"
          onSearch={(term) =>
            onFiltersChange({ ...filters, recipient: term })
          }
        />
      </div>
    </Space>
  );
};

export default EmailFilters;
