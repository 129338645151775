import React, { useEffect, useState } from "react"
import axios from "axios"
import { Breadcrumb, Row, Col, Pagination, Collapse } from "antd"
import { CaretRightOutlined } from '@ant-design/icons'

import EmailFilters from "./EmailFilters"
import EmailList from "./EmailList"

const routes = [
    { path: "", breadcrumbName: "App" },
    { path: "/email", breadcrumbName: "Email" }
  ];

const Email = () => {
    const [emails, setEmails] = useState()
    const [pagination, setPagination] = useState({ currentPage: 1, limit: 10 })
    const [filters, setFilters] = useState({})

    useEffect(() => {
        const fetchEmails = async () => {
          const res = await axios.get(`/api/emails`, {
            params: {
              limit: pagination.limit,
              page: pagination.currentPage,
              recipient: filters.recipient
            }
          })
          setEmails(res.data.data)
          setPagination(res.data.pagination)
        }
    
        fetchEmails()
    }, [pagination.currentPage, pagination.limit, filters])

    return (
        <div>
          <div className="header">
            <Row>
              <Col span={12}>
                <h2 className="detail-header-text">Email Logs</h2>
              </Col>
              <Col span={12}>
                <Breadcrumb
                style={{ margin: "10px 0", textAlign: "right" }}
                routes={routes}
              />
              </Col>
            </Row>
          </div>
    
          <Collapse
            expandIcon={({ isActive }) => (
              <CaretRightOutlined rotate={isActive ? 90 : 0} />
            )}
          >
            <Collapse.Panel header="Filters" key="filters">
              <EmailFilters
                filters={filters}
                onFiltersChange={(filters) => setFilters(filters)}
              />
            </Collapse.Panel>
          </Collapse>
    
          {emails && <EmailList 
            emails={emails}
          />}
    
          <Row justify="end">
            <Col>
              <Pagination 
                total={pagination?.totalSize} 
                pageSize={pagination?.limit}
                onChange={((currentPage, limit) => { setPagination({ ...pagination, limit, currentPage }) })}
                current={pagination.currentPage} 
              />
            </Col>
          </Row>
        </div>
    )
}

export default Email