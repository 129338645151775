import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Typography, Breadcrumb } from 'antd'

const routes = [{ path: "", breadcrumbName: "Admin" }];

const PageHeader = ({ title, secondaryTitle, childRoutes, additionalInfo: AdditionalInfo }) => {
  return (
    <div className="header">
      <Row>
        <Col span={12}>
          <h2 className="header-text">
            {title}{` `}
            {AdditionalInfo && <AdditionalInfo /> }
          </h2>
          {secondaryTitle && <Typography.Text type="secondary">
            {secondaryTitle}
          </Typography.Text>}
        </Col>

        <Col span={12}>
          <Breadcrumb
            style={{ margin: "16px 0", textAlign: "right" }}
            routes={routes.concat(childRoutes)}
          />
        </Col>
      </Row>
    </div>
  )
}

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  secondaryTitle: PropTypes.string,
  childRoutes: PropTypes.array,
  additionalInfo: PropTypes.element
}

export default PageHeader