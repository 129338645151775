import React, { useState, useEffect } from "react";
import { Form, Button, message, Spin } from "antd";
import axios from "axios";
import moment from "moment";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import { useParams } from "react-router-dom";

const endOfThisMonth = moment().endOf("month");
const oneYearAgo = moment().subtract(12, "months").startOf("month");

const EditQuestionaire = () => {
  const [filters, setFilters] = useState({
    startDate: oneYearAgo,
    endDate: endOfThisMonth,
  });
  const { id } = useParams();
  const [questions, setQuestions] = useState([]);
  const [title, setTitle] = useState("");
  const [theObject, setObject] = useState({});
  const [loading, setLoading] = useState(true);
  const [form] = Form.useForm();
  const [saving, setSaving] = useState(false);
  const [theQuestionaire, setTheQuestionaire] = useState({
    title: "",
    description: "",
    photo: "",
    questions: [],
  });

  useEffect(() => {
    const fetchResult = async () => {
      try {
        setLoading(true);
        const res = await axios.get(`/api/questionaire/${id}`, {
          params: {
            startDate: filters.startDate.toISOString(),
            endDate: filters.endDate.toISOString(),
          },
        });
        const fetchedQuestions = res.data.data.questions.questions;
        const title = res.data.data.questions.title;
        setQuestions(fetchedQuestions);
        setTitle(title);
        setObject(res.data.data.questions);

        console.log(res.data.data.questions.photo[0].content);
        setTheQuestionaire({
          title: res.data.data.questions.title,
          description: res.data.data.questions.description,
          photo: res.data.data.questions.photo || "",
          questions: res.data.data.questions.questions,
        });
      } catch (error) {
        message.error("Failed to fetch questionnaire data");
      } finally {
        setLoading(false);
      }
    };

    fetchResult();
  }, [id, filters.startDate, filters.endDate]);

  useEffect(() => {
    const survey = new Model(json(theQuestionaire));
    survey.onUploadFiles.add(handleFileUpload);
    survey.showCompletedPage = false;
    survey.onComplete.add(alertResults);
    survey.onValueChanged.add(function (sender, options) {
      const questionName = options.value[0].name
      const noOfChoices = options.value[0]["choices"]
      const question = survey
        .getQuestionByName(options.name).panels[0].getQuestionByName("answer");
      
      survey
        .getQuestionByName(options.name)
        .panels[0].getQuestionByName("answer").items = [];

      for (let i = 0; i < noOfChoices; i++) {
        question.addItem("answer" + (i + 1));
      }
        
    });
    setSurveyModel(survey);
  }, [theQuestionaire]);

  const [surveyModel, setSurveyModel] = useState(null);

  const json = (questionaire) => {
    return {
      showQuestionNumbers: "off",
      questionErrorLocation: "bottom",
      width: "100vh",
      elements: [
        {
          type: "text",
          title: "Questionaire Title",
          defaultValue: questionaire.title,
          name: "title",
        },
        {
          type: "text",
          title: "Description",
          defaultValue: questionaire.description,
          name: "description",
        },
        {
          type: "html",
          name: "photoPreview",
          title: "Questionaire Icon",
          html: `
            <div style="text-align: center; margin: 20px;">
              <div style="font-weight: bold; margin-bottom: 10px;">Questionaire Icon:</div>
              ${
                questionaire.photo
                  ? `<img src="${questionaire.photo[0].content}" alt="Photo Preview" style="height: 280px; width: auto; border: 1px solid #ccc; padding: 10px; box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);" />`
                  : "No photo uploaded"
              }
            </div>
          `,
        },
        {
          type: "file",
          title: "Upload New Widget Icon",
          name: "photo",
          acceptedTypes: "image/*",
          storeDataAsText: false,
          waitForUpload: true,
          maxSize: 512000,
          hideNumber: true,
          imageHeight: 280,
          imageWidth: 280,
          validators: [
            {
              type: "filevalidator",
              maxSize: 512000,
              allowedTypes: ["image/jpeg", "image/png"],
            },
          ],
        },
        ...questionaire.questions.map((question, index) => ({
          type: "paneldynamic",
          name: `questions_${index}`,
          title: `Question ${index + 1}`,
          keyName: "name",
          showQuestionNumbers: "off",
          templateTitle: "",
          templateElements: [
            {
              type: "dropdown",
              name: "type",
              title: "Select question type",
              isRequired: true,
              choices: [
                { value: 0, text: "text" },
                { value: 1, text: "dropdown" },
                { value: 2, text: "radiogroup" },
                { value: 3, text: "checkbox" },
                { value: 4, text: "comment" },
                { value: 5, text: "boolean" },
                { value: 6, text: "rating" },
              ],
              defaultValue: question.type || 0,
            },
            {
              type: "text",
              name: "name",
              title: "Name:",
              isRequired: true,
              visibleIf: "{panel.type} notEmpty",
              defaultValue: question.name || "",
            },
            {
              type: "text",
              name: "title",
              title: "Title:",
              isRequired: true,
              visibleIf: "{panel.type} notEmpty",
              defaultValue: question.title || "",
            },
            {
              type: "text",
              name: "rateMin",
              title: "Rate Min:",
              visibleIf: "{panel.type} = 6",
              inputType: "number",
              isRequired: true,
              defaultValue: question.rateMin || "",
            },
            {
              type: "text",
              name: "rateMax",
              title: "Rate Max:",
              visibleIf: "{panel.type} = 6",
              inputType: "number",
              isRequired: true,
              defaultValue: question.rateMax || "",
            },
            {
              type: "text",
              name: "descRateMin",
              title: "Description of Rate Min:",
              visibleIf: "{panel.type} = 6",
              isRequired: true,
              defaultValue: question.descRateMin || "",
            },
            {
              type: "text",
              name: "descRateMax",
              title: "Description of Rate Max:",
              visibleIf: "{panel.type} = 6",
              isRequired: true,
              defaultValue: question.descRateMax || "",
            },
            {
              type: "dropdown",
              name: "choices",
              title: "Choices:",
              visibleIf: "{panel.type} <= 3 && {panel.type} >= 1",
              isRequired: true,
              choices: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
              defaultValue: question.choices || 0,
            },
            {
              type: "boolean",
              name: "required",
              visibleIf: "{panel.type} notEmpty",
              title: "Required",
              isRequired: false,
              defaultValue: question.required || false,
            },
            {
              type: "multipletext",
              colCount: 2,
              isRequired: true,
              items: Object.keys(question.answer || {}).map((key) => ({
                name: key,
                title: `Answer Choice ${key.slice(-1)}`,
                isRequired: true,
              })),
              name: "answer",
              title: "Answer Choice",
              visible: false,
              visibleIf: "{panel.choices} > 0",
              defaultValue: question.answer || {},
            },
          ],
          minPanelCount: 1,
          maxPanelCount: 1,
        })),
      ],
    };
  };

  const alertResults = async (sender) => {
    setSaving(true);

    try {
      const questionsArray = Object.keys(sender.data)
        .filter((key) => key.startsWith("questions_"))
        .flatMap((key) => sender.data[key]);

      const formattedData = {
        title: sender.data.title,
        description: sender.data.description,
        photo:
          sender.data.photo && sender.data.photo.length > 0
            ? sender.data.photo
            : theQuestionaire.photo,
        questions: questionsArray,
      };

      await axios.patch(`/api/questionaire/edit-question/${id}`, formattedData);

      message.success("Question successfully updated");
      window.location = `/questionaire/result/${id}`;
    } catch (error) {
      message.error("Failed to update questionnaire");
    } finally {
      setSaving(false);
    }
  };

  const handleFileUpload = async (_, options) => {
    const formData = new FormData();

    if (options.files) {
      options.files.forEach((file) => formData.append("file", file));
    } else {
      message.error("No files to upload.");
      return;
    }

    try {
      await axios
        .post("/api/questionaire/upload", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((res) => {
          options.callback(
            "success",
            options.files.map((file) => ({
              file: file,
              content: res.data.success,
            }))
          );
        });
    } catch (error) {
      message.error("Failed to upload files");
    }
  };

  return (
    <div>
      {loading ? (
        <Spin tip="Loading..." />
      ) : (
        surveyModel && <Survey model={surveyModel} />
      )}
    </div>
  );
};

export default EditQuestionaire;
