import React from 'react'
import { Tag, Tooltip } from 'antd'

const status = [
    { key: "Verified", name: "Verified", color: "blue"},
    { key: "Unverified", name: "Unverified", color: "red"},
];
   
const VerificationStatusTag = ({statusKey, title}) => {
    const theStatus = status.find(theStatus => theStatus.key === statusKey)
    if (theStatus) {
      return (
        <React.Fragment>
          <Tooltip placement="topLeft" title={title}>
           <Tag color={theStatus.color}>{theStatus.name}</Tag>
          </Tooltip>
        </React.Fragment>
      )
    }
    return null
  }
  
export default VerificationStatusTag