import React, { useState, useEffect, useCallback } from "react";
import { Tabs, message } from "antd";
import { DollarOutlined, FileProtectOutlined, CarOutlined } from '@ant-design/icons';
import axios from "axios";

import PageHeader from "../Layout/PageHeader";
import ConversionSettings from './ConversionSettings'
import CourierSettings from './CourierSettings'
import SettingsAuditLogs from './SettingsAuditLogs'

const INITIAL_SETTINGS = {
  conversion: {},
  courier: {},
}

const Settings = () => {
  const [currentSettings, setCurrentSettings] = useState()
  const [auditLogs, setAuditLogs] = useState()

  const fetchCurrentSettings = useCallback(async () => {
    const res = await axios.get(`/api/settings/current`)

    setCurrentSettings(res.data.data || INITIAL_SETTINGS)
  }, [])

  useEffect(() => {
    fetchCurrentSettings()
  }, [fetchCurrentSettings])

  const fetchAuditLogs = useCallback(async () => {
    const res = await axios.get(`/api/settings/audit-logs`)

    setAuditLogs(res.data.data)
  }, [])

  useEffect(() => {
    fetchAuditLogs()
  }, [fetchAuditLogs])

  const saveHandler = async (type, settings) => {
    const newSettings = {
      ...currentSettings,
      [type]: settings
    }
  
    try {
      await axios.put('api/settings', newSettings)

      message.success("Settings updated")

      await fetchCurrentSettings()
      fetchAuditLogs()
    } catch (error) {
      message.error(error.response.data.message || error.message)
    }
  } 

  return (
    <React.Fragment>
      <PageHeader 
        title="Settings" 
        childRoutes={[{ path: "/settings", breadcrumbName: "Settings" }]}
      />

      <div className="table">
      <Tabs type="card" defaultActiveKey="conversion">
        <Tabs.TabPane tab={(<span><DollarOutlined />Conversion</span>)} key="conversion">
          {currentSettings && <ConversionSettings settings={currentSettings.conversion} onSave={saveHandler} />}
        </Tabs.TabPane>

        <Tabs.TabPane tab={(<span><CarOutlined />Courier</span>)} key="courier">
          {currentSettings && <CourierSettings settings={currentSettings.courier} onSave={saveHandler} />}
        </Tabs.TabPane>

        <Tabs.TabPane tab={(<span><FileProtectOutlined />Audit Logs</span>)} key="audit-logs">
          {auditLogs && <SettingsAuditLogs auditLogs={auditLogs} />}
        </Tabs.TabPane>
      </Tabs>
      </div>
    </React.Fragment>
  )
}

export default Settings;