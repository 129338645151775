import React, { useEffect } from "react";
import axios from "axios"
import { TableauViz, TableauEventType } from '@tableau/embedding-api';
import { message } from "antd";

import PageHeader from "../Layout/PageHeader";
import config from "../../config/app"

const TableauAnalysis = () => {
  const handleAuthError = () => {
    message.error("Authentication failed. Please try to refresh page.")
  }

  useEffect(() => {
    const viz = new TableauViz();

    viz.src = config.mainTableauDashboard;
    viz.toolbar = 'hidden';
    viz.hideTabs = true
    viz.width = "100%"
    viz.height = "800"
    viz.addEventListener(TableauEventType.VizLoadError, handleAuthError);

    axios.get("/api/tableau-jwt")
      .then(res => {
        console.log(res.data.token)

        viz.token = res.data.token
        document.getElementById('tableauViz').appendChild(viz);
      })

  }, []);

  return (
    <div>
      <PageHeader title="Tableau Analytics" childRoutes={[]} />

      <div id="tableauViz"></div>
    </div>
  );
};

export default TableauAnalysis;
