import React, { useState, useEffect } from "react";
import moment from "moment";
import "./User.css";
import "react-vis/dist/style.css";
import UserFilters from "./UserFilters";
import {Pagination, Row, Col, Collapse, Table, Button, Modal, message } from "antd";
import axios from "axios";
import {
  CaretRightOutlined,
  WarningTwoTone,
  DownloadOutlined,
} from "@ant-design/icons";
import UserBadgeTag from "../shared/UserBadgeTag";

const today = moment().startOf("day");
const oneMonthAgo = moment().subtract(30, "days").startOf("day");

const table = [
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "Phone Number",
    dataIndex: "phone",
    key: "phone",
  },
  {
    title: "Credit Balance",
    dataIndex: "balance",
    key: "balance",
    render: (text) => text.toFixed(2),
  },
  {
    title: "Total Order",
    dataIndex: "totalOrder",
    key: "totalOrder",
  },
  {
    title: "Total Returned Order",
    dataIndex: "totalReturnedOrder",
    key: "totalReturnedOrder",
  },
  {
    title: "RTO Rate",
    dataIndex: "rtoRate",
    key: "rtoRate",
    render: (text) => text.toFixed(2) + "%",
    sorter: (a, b) => a.rtoRate - b.rtoRate,
  },
  {
    title: "Is Danger RTO",
    dataIndex: "rtoRate",
    key: "rtoRate",
    render: (text) =>
      text > 5 ? <WarningTwoTone twoToneColor="#FF0000" /> : "-",
  },
  {
    title: "Is Danger Balance",
    dataIndex: "balance",
    key: "balance",
    render: (text) =>
      text < -0.4 ? <WarningTwoTone twoToneColor="#FF0000" /> : "-",
  },
  {
    title: "Badge",
    dataIndex: "badge",
    key: "badge",
    render: (text, row) =>
      text ? <UserBadgeTag statusKey={text}>Verified</UserBadgeTag> : "-",
  },
  {
    title: "Account Manager",
    dataIndex: "manager",
    key: "manager",
    render : (text) => text ? text : "-"
  },
];

const DangerUserList = ({ users }) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const [pagination, setPagination] = useState({ currentPage: 1, limit: 10 })
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({
    startDate: oneMonthAgo,
    endDate: today,
  });
  const [dangerUser, setDangerUser] = useState([]);

  useEffect(() => {
    const dateParams = {
      startDate: filters.startDate?.startOf("day")?.toISOString(),
      endDate: filters.endDate?.endOf("day")?.toISOString(),
    };

    const fetchDangerUser = async () => {
      setLoading(true);
      const res = await axios.get(`/api/users/danger-user`, {
        params: {
          ...dateParams,
          limit: pagination.limit,
          page: pagination.currentPage,
        },
      });
      setDangerUser(res.data.data);
      setPagination(res.data.pagination)
    };

    fetchDangerUser().then(() => {
      setLoading(false);
    });
  }, [pagination.currentPage, pagination.limit,filters.startDate, filters.endDate, filters.search]);

  const downloadDangerUser = async () => {
    const dateParams = {
      startDate: filters.startDate?.startOf("day")?.toISOString(),
      endDate: filters.endDate?.endOf("day")?.toISOString(),
    };

    const messageContent = (
      <p>
        Are you sure you want to download {pagination.totalSize} danger user data ?<br />
      </p>
    );

    Modal.confirm({
      content: messageContent,
      title: `Download Danger User List ? `,
      async onOk() {
        await axios
          .get("api/users/danger-user/csv", {
            params: {
              ...dateParams,
              limit: pagination.limit,

            },
          })
          .then((res) => {
            message.info(`Please check your email after 5-20 minute`);
          })
          .catch((error) => {
            message.error(error.message);
          });
      },
    });
  };

  return (
    <div className="table mt-4">
      <Collapse
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
      >
        <Collapse.Panel header="Filters" key="filters">
          <UserFilters
            filters={filters}
            onFiltersChange={(filters) => setFilters(filters)}
            tabName="dangerUser"
          />
        </Collapse.Panel>
      </Collapse>
      <Row className="mt-2 mb-2" justify="space-between">
        <Button onClick={downloadDangerUser} loading={isDownloading}>
          CSV <DownloadOutlined></DownloadOutlined>
        </Button>
        <Col>
          <Pagination 
            total={pagination?.totalSize} 
            pageSize={pagination?.limit}
            onChange={((currentPage, limit) => { setPagination({ ...pagination, limit, currentPage }) })}
            current={pagination.currentPage} 
          />
        </Col>
      </Row>
      <Table
        className="table-section"
        columns={table}
        dataSource={dangerUser}
        pagination={false}
        scroll={{ x: true }}
        loading={loading}
      />
      <Row justify="end">
        <Col>
          <Pagination 
            total={pagination?.totalSize} 
            pageSize={pagination?.limit}
            onChange={((currentPage, limit) => { setPagination({ ...pagination, limit, currentPage }) })}
            current={pagination.currentPage} 
          />
        </Col>
      </Row>
    </div>
  );
};

export default DangerUserList;
