import React, { useState, useEffect, useCallback } from "react";
import { Col, Row, Pagination, Collapse } from "antd";
import axios from "axios";
import { CaretRightOutlined } from "@ant-design/icons";

import PageHeader from '../Layout/PageHeader'
import PayoutList from './PayoutList';
import PayoutFilters from './PayoutFilters';

const Payout = () => {
  const [loading, setLoading] = useState({ index: false })
  const [payouts, setPayouts] = useState()
  const [filters, setFilters] = useState({ })
  const [pagination, setPagination] = useState({ currentPage: 1, limit: 50 })

  const fetchPayouts = useCallback(async () => {
    setLoading(prevLoading => ({ ...prevLoading, index: true }))

    const params = {
      limit: pagination.limit,
      page: pagination.currentPage,
      ...(filters.search && { searchField: filters.searchField, search: filters.search }),
      ...(filters.isSuccess !== null) && { isSuccess: filters.isSuccess },
      startDate: filters.startDate?.startOf('day')?.toISOString(),
      endDate: filters.endDate?.endOf('day')?.toISOString(),
    }
    const res = await axios.get("/api/payout", { params }) 

    setPayouts(res.data.data || [])
    setPagination(res.data.pagination)
    setLoading(prevLoading => ({ ...prevLoading, index: false }))
  }, [pagination.currentPage, pagination.limit, filters])

  useEffect(() => {
    fetchPayouts()
  }, [pagination.currentPage, pagination.limit, fetchPayouts])

  return (
    <div>
      <PageHeader 
        title="Payout"
        childRoutes={[{ path: "/payout", breadcrumbName: "Payout" }]}
      />

      <Collapse expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}>
        <Collapse.Panel header="Filters" key="filters">
          <PayoutFilters
            filters={filters} 
            onFiltersChange={filters => setFilters(filters)} 
          />
        </Collapse.Panel>
      </Collapse>

      <Row className="mt-2 mb-2" justify="space-between">
        <Col>
          <Pagination 
            total={pagination?.totalSize} 
            pageSize={pagination?.limit}
            onChange={((currentPage, limit) => { setPagination({ ...pagination, limit, currentPage }) })}
            current={pagination.currentPage} 
          />
        </Col>
      </Row>
      
      <PayoutList
        payouts={payouts}
        loading={loading.index}
      />

      <Pagination 
          total={pagination?.totalSize} 
          pageSize={pagination?.limit}
          onChange={((currentPage, limit) => { setPagination({ ...pagination, limit, currentPage }) })}
          current={pagination.currentPage} 
      />
    </div>
  )
}

export default Payout;
