import React from "react";
import { connect } from "react-redux";
import "./UserDetails.css";
import "react-vis/dist/style.css";
import moment from "moment";
import jwt from 'jwt-decode'
import {
  Breadcrumb,
  Row,
  Col,
  Tabs,
  Divider,
  Button,
  Input,
  InputNumber,
  Card,
  Modal,
  Form,
  Pagination,
  message,
  Switch,
  Select,
  Typography
} from "antd";
import axios from "axios";
import {
  UserOutlined,
  PhoneFilled,
  MailFilled,
  IdcardFilled,
  CreditCardFilled,
  PayCircleOutlined,
  BankFilled,
} from "@ant-design/icons";
import AuditLogList from "./AuditLogList";
import UserCodOrder from "./UserCodOrder";
import UserReturnedOrder from "./UserReturnedOrder";
import UserAnalytic from "./UserAnalytic";
import UserOrder from "./UserOrder"
import ServiceProviderTag from "../shared/ServiceProviderTag";
import TransactionFilters from '../Transactions/TransactionFilters'
import { formatDateTime } from '../../helpers/date'
import UserTransaction from "./UserTransaction";
import UserAdminAdjustment from "./UserAdminAdjustment";
import UserOnlinePayment from "./UserOnlinePayment";
import { startCase } from 'lodash'
import UserBetaFeatures from "./UserBetaFeatures";
import TopupPackageTag from "../shared/TopupPackageTag";
import { ADMIN_ADJUSTMENT_REMARKS } from "../../constants/adminAdjustmentRemarks"

const { TabPane } = Tabs;

class UserDetails extends React.Component {
  state = {
    data: [],
    pagination: {
      current: 1,
      pageSize: 10,
    },
    loading: false,
    userId: "",
    searchText: "",
    searchedColumn: "",
    selectedRowKeys: [],
    orderData: [],
    adminData: [],
    weightDisputeData: [],
    purchaseData: [],
    refundData: [],
    topupData: [],
    credit: false,
    charge: false,
    editDetails: false,
    showPackageModal: false,
    availablePackages: [],
    availableFeePackages: [],
    availableCourierPackages: [],
    memberships: false,
    consignment_loader: true,
    payment_data : {},
    permission: "Admin",
    auditLogs: {
      pagination: {},
      data: {}
    },
    filters: {},
    assignConfig: false,
  };

  async loadAuditLog() {
    const params = new URLSearchParams([['page', this.state.auditLogs.pagination.currentPage]]);

    await axios.get(`/api/user/${this.state.userId}/audit-logs`, { params }).then(res => {
      this.setState({
        auditLogs: {
          data: res.data.data,
          pagination: res.data.pagination
        }
      })
    })
  }

  onAuditLogPageChange(pageNumber) {
    this.setState({
      auditLogs: {
        ...this.state.auditLogs,
        pagination: {
          ...this.state.auditLogs.pagination,
          currentPage: pageNumber
        }
      }
    }, function () {
      this.loadAuditLog()
    })
  }

  handleOk() {
    this.state.credit = false;
  }

  handleCancel() {
    this.state.credit = false;
  }

  onSelectChange = (selectedRowKeys) => {
    console.log("selectedRowKeys changed: ", selectedRowKeys);
    this.setState({ selectedRowKeys });
  }
  async loadTransactionsData() {
    const getURL2 = `api/transactions`;
    this.setState({
      merchantId: this.state.merchant.objectId,
    });
    const response2 = await axios.get(getURL2, {
      params: {
        merchantId: this.state.merchant.objectId,
        limit: 1000,
        ...(this.state.filters.startDate !== null) && { startDate: this.state.filters.startDate?.startOf('day')?.toISOString() },
        ...(this.state.filters.endDate !== null) && { endDate: this.state.filters.endDate?.endOf('day')?.toISOString() },
        ...(this.state.filters.search && { searchField: this.state.filters.searchField, search: this.state.filters.search }),
      }
    });
    let tableData = response2.data.data.sort((a, b) =>
      a.createdAt > b.createdAt ? -1 : 1
    );
    tableData = tableData.map((obj) => {
      return {
        id: obj.objectId,
        method: obj.method,
        amount: obj.amount,
        ref_no: obj.ref_no,
        merchant: obj.merchant,
        order: obj.order,
        successAt: (obj.successAt
          ? formatDateTime(obj.successAt)
          : "Failed"),
        remark: obj.remarks ? obj.remarks : "-"
      };
    });
    this.setState({
      purchaseData: tableData.filter((data) => {
        return data.method === "consignment_purchase";
      }),
    });
    this.setState({
      refundData: tableData.filter((data) => {
        return data.method === "refund";
      }),
    });
    this.setState({
      codRefundData: tableData.filter((data) => {
        return data.method === "refund-cod-charge" || data.method === "refund-next-day-remittance-charge";
      }),
    });
    this.setState({
      adminData: tableData.filter((data) => {
        return data.method === "Admin Manual";
      }),
    });
    this.setState({
      weightDisputeData: tableData.filter((data) => {
        return data.method === "weight-dispute";
      }),
    });
    this.setState({
      topupData: tableData.filter((data) => {
        return data.method === "Top Up";
      }),
    });
    this.setState({ loading: false });
  }

  async componentDidMount() {
    const {user_id} = jwt(this.props.token);
    const user_perm = await axios.get('/api/users/getPermission', {
      params: {
        user_id : user_id
      }
    });
    const perm = JSON.stringify(user_perm.data).toLowerCase();
    if(perm.includes('admin')){
      this.setState({
        permission : "Admin"
      })
    }
    if(perm.includes('operation')){
      this.setState({
        permission : "Operation"
      })
    }
    if(perm.includes('finance')){
      this.setState({
        permission : "Finance"
      })
    }
    let userId = window.location.pathname.substring(6);
    this.setState({
      userId: userId,
    });

    this.loadAuditLog();

    await axios
      .get(`/api/user/${userId}`)
      .then((response) => {
        this.setState({
          alias: response.data.alias,
          username: response.data.username,
          email: response.data.email,
          phone: response.data.phone,
          merchant: response.data.merchant,
          type: response.data.perms[0] === "NORMAL_USER" ? "Normal" : "Admin",
          bankName: response.data.bankName,
          createdAt: formatDateTime(response.data.createdAt),
          credits: response.data.merchant.credits,
          memberType: response.data.merchant.memberType,
          topupPackage: response.data.merchant.topupPackage,
          name: response.data.merchant.name,
          BRN: response.data.merchant.BRN,
          totalTopup: response.data.merchant.totalTopup,
          bankAccount: response.data.merchant.bankAccount,
          referralCode: response.data.referralCode,
          bankHolderName: response.data.bankHolderName,
          betaFeatures: response.data.betaFeatures?response.data.betaFeatures : [],
          heardFrom : response.data.heardFrom,
          accountManager : response.data.manager?.email || "",
          countryCode: response.data.countryCode,
          tagging:response.data.tagging,
        });
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`/api/topup-packages`)
      .then((response) => {
        this.setState({
          availablePackages: response.data.meta?.packages || []
        });
      })
      .catch((err) => {
        console.log(err);
      });
    
    axios
      .get(`/api/fee-packages`)
      .then((response) => {
        this.setState({
          availableFeePackages: response.data.meta?.packages || []
        });
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`/api/courier-packages`)
      .then((response) => {
        this.setState({
          availableCourierPackages: response.data.meta?.packages || []
        });
      })
      .catch((err) => {
        console.log(err);
      });

    this.loadTransactionsData()
  }

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  refundFor(orderId) {
    return axios.patch("api/order/refund", {
      orderId,
    });
  }

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };


  download(id) {
    this.setState({
      consignment_loader: false
    })
    axios
        .get("consign-pdf/" + id, {
            responseType: "blob"
        })
        .then(response => {
            const blob = new Blob([response.data], {
                type: "application/pdf"
            });
            console.log(response.data);
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = id + ".pdf";
            link.click();
            URL.revokeObjectURL(link.href);
            this.setState({
              consignment_loader: true
            })
        })
        .catch(err => {
            console.log("Dasryt");
        });
  }

  render() {
    const { loading, selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };
    const routes = [
      {
        path: "",
        breadcrumbName: "App",
      },
      {
        path: "/user",
        breadcrumbName: "User",
      },
      {
        path: `${this.state.userId}`,
        breadcrumbName: `${this.state.userId}`,
      },
    ];
    const onlineTable = [
      {
        title: "ID",
        dataIndex: "id",
      },
      {
        title: "Reference ID",
        dataIndex: "ref_no",
        key: "ref_no",
        render: (text) => (text ? text : "-"),
      },
      {
        title: "Amount",
        dataIndex: "amount",
      },
      {
        title: "Method",
        dataIndex: "method",
      },
      {
        title: "Success At (YYYY-MM-DD)",
        dataIndex: "creditSuccessAt",
      },
      {
        title: "Remarks",
        dataIndex: "remark"
      }
    ];
    const transactionsTable = [
      {
        title: "ID",
        dataIndex: "id",
      },
      {
        title: "Order ID",
        dataIndex: "order",
        key: "orderId",
        render: (text, row) => text?.objectId || row.ref_no || "-"
      },
      {
        title: "Tracking No",
        dataIndex: "order",
        key: "trackingNo",
        render: text => text?.consign_no || "-"
      },
      {
        title: "Courier",
        dataIndex: "order",
        render: (text, row) => text?.serviceProvider ? <ServiceProviderTag serviceProviderKey={text.serviceProvider} /> : ""
      },
      {
        title: "Amount",
        dataIndex: "amount",
      },
      {
        title: "Method",
        dataIndex: "method",
      },
      {
        title: "Success At (YYYY-MM-DD)",
        dataIndex: "creditSuccessAt",
      },
      {
        title: "Remarks",
        dataIndex: "remark"
      }
    ];
    const adminTable = [
      {
        title: "ID",
        dataIndex: "id",
      },
      {
        title: "Amount",
        dataIndex: "amount",
      },
      {
        title: "Method",
        dataIndex: "method",
      },
      {
        title: "Success At (YYYY-MM-DD)",
        dataIndex: "creditSuccessAt",
      },
      {
        title: "Remarks",
        dataIndex: "remark"
      }
    ];
    const diffInDays = this.state.merchant?.packageBoughtAt 
      ? (this.state.merchant?.packageValidDays || 180) - moment().diff(moment(this.state.merchant.packageBoughtAt.iso), 'days')
      : null
    const diffLockedInDays = this.state.merchant?.packageLockedAt 
      ? (this.state.merchant?.packageLockedDays || 0) - moment().diff(moment(this.state.merchant.packageLockedAt.iso), 'days')
      : null
    
    return (
      <div>
        <div className="header">
          <Row>
            <Col span={12}>
              <h2 className="detail-header-text">User Details</h2>
            </Col>
            <Col span={12}>
              <Breadcrumb
                style={{ margin: "10px 0", textAlign: "right" }}
                routes={routes}
              />
            </Col>
          </Row>
        </div>

        <Row>
          <Col span={12}>
          </Col>
          <Col span={12} style={{ textAlign: "right" }}>
          {this.state.permission === "Admin"? <Button
              type="primary"
              size="middle"
              style={{margin : 10}}
              onClick={() => {
                this.setState({ assignConfig : true });
              }}
            >
              DHL Custom Config
            </Button> : this.state.permission === "Operation" ? <Button
              type="primary"
              size="middle"
              style={{margin : 10}}
              onClick={() => {
                this.setState({ assignConfig : true });
              }}
            >
              DHL Custom Config
            </Button> : ""}
            <Modal
              visible={this.state.assignConfig}
              title="Dhl Custom Config"
              onOk={async (val) => {
                const credit = parseFloat(val.credit);
                await axios.patch(
                  `/api/merchant/${this.state.merchant.objectId}/service-provider-configs`, 
                  {
                    "dhl": {
                        "api": "https://api.dhlecommerce.dhl.com",
                        "clientID": "LTY0NzI2OTAwOA==",
                        "password": "customerpassword@23113120194",
                        "soldto": "5298362955",
                        "pickup": "5298362955",
                        "prefix": "MYJZS"
                    }
                }
                  
                ).then(function (response) {
                  message.info(
                    `This user have been assign to the DHL custom configs.`
                  );
                }).catch((err)=>{
                  message.error("You are not authorized to perform this operation.")
                })
                this.setState({ assignConfig: false });
              }}
              onCancel={() => {
                this.setState({ assignConfig: false });
              }}
            >
              <div>
               Assign this user to the DHL custom configs ? 
              </div>
            </Modal>
          { this.state.permission === "Admin" && (
            <Button
              type="primary"
              size="middle"
              style={{ margin: 10 }}
              onClick={() => {
                this.setState({ showPackageModal: true });
              }}
            >
              Manage Package
            </Button>
          ) }
            <Modal
              title="Package"
              visible={this.state.showPackageModal}
              footer={null}
              onCancel={() => {
                this.setState({ showPackageModal: false });
              }}
            >
              <p>
                <strong>Current</strong> :{" "}
                { startCase(this.state.merchant?.topupPackage || "-") }
              </p>

              <p>
                <strong>Bought At</strong> :{" "}
                { this.state.merchant?.packageBoughtAt 
                  ?  formatDateTime(this.state.merchant.packageBoughtAt) + " (" +
                    moment(this.state.merchant.packageBoughtAt.iso).fromNow() + ")"
                  : "-" 
                }
              </p>
              <p>
                <strong>Valid For</strong> :{" "}
                { this.state.merchant?.packageValidDays || 180 } days
              </p>
              <p className="mb-2">
                <strong>Expires In</strong> :{" "}
                { diffInDays > 0 && diffInDays + " days" }
                { diffInDays < 0 && <Typography.Text type="danger">Expired ({Math.abs(diffInDays)} days ago)</Typography.Text> }
              </p>

              <p>
                <strong>Locked At</strong> :{" "}
                { this.state.merchant?.packageLockedAt 
                  ?  formatDateTime(this.state.merchant.packageLockedAt) + " (" +
                    moment(this.state.merchant.packageLockedAt.iso).fromNow() + ")"
                  : "-" 
                }
              </p>
              <p>
                <strong>Locked For</strong> :{" "}
                { this.state.merchant?.packageLockedDays || 0 } days
              </p>
              <p className="mb-2">
                <strong>Unlocked In</strong> :{" "}
                { diffLockedInDays > 0 && diffLockedInDays + " days" }
                { diffLockedInDays < 0 && <Typography.Text type="danger">Unlocked ({Math.abs(diffLockedInDays)} days ago)</Typography.Text> }
              </p>

              <Divider>Edit</Divider>
              <Form
                name="changePackageForm"
                labelCol={{
                  span: 6,
                }}
                wrapperCol={{
                  span: 20,
                }}
                initialValues={{
                  remember: true,
                  packageLockedDays: this.state.merchant?.packageLockedDays || 0,
                  package: this.state.merchant?.topupPackage,
                  feePackage: this.state.merchant?.feePackage,
                  courierPackage: this.state.merchant?.courierPackage,
                  packageValidDays: (this.state.merchant?.packageValidDays || 180)
                }}
                onFinish={async (val) => {
                  await axios
                    .put(`api/merchants/${this.state.merchant.objectId}/package`, {
                      "package": val.package,
                      feePackage: val.feePackage,
                      courierPackage: val.courierPackage,
                      packageLockedDays: val.packageLockedDays,
                      packageValidDays: val.packageValidDays
                    })
                    .then(function (response) {
                      message.info(
                        `The Package type has been updated. Refresh to verify the change.`
                      );
                    })
                    .catch((err) => {
                      message.error(err.response?.data?.message || "Something went wrong.");
                    });
                  this.setState({ charge: false });
                }}
                onFinishFailed={() => {}}
              >
                <Form.Item
                  label="Package"
                  name="package"
                >
                  <Select
                    placeholder="Choose Package"
                    style={{ width: 200 }}
                  >
                    { this.state.availablePackages.map(pkg => <Select.Option value={pkg}>{ startCase(pkg) }</Select.Option> ) }
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Fee Package"
                  name="feePackage"
                >
                  <Select
                    placeholder="Choose Package"
                    style={{ width: 200 }}
                  >
                    { this.state.availableFeePackages.map(pkg => <Select.Option value={pkg}>{ startCase(pkg) }</Select.Option> ) }
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Courier Package"
                  name="courierPackage"
                >
                  <Select
                    placeholder="Choose Package"
                    style={{ width: 200 }}
                  >
                    { this.state.availableCourierPackages.map(pkg => <Select.Option value={pkg}>{ startCase(pkg) }</Select.Option> ) }
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Valid For"
                  name="packageValidDays"
                  extra="days"
                >
                  <InputNumber min={1} step={1} />
                </Form.Item>
                <Form.Item
                  label="Locked For"
                  name="packageLockedDays"
                  extra="days"
                >
                  <InputNumber min={0} step={1} />
                </Form.Item>

                <Form.Item
                  wrapperCol={{
                    offset: 1,
                    span: 16,
                  }}
                >
                  <Button type="primary" htmlType="submit">
                    Update
                  </Button>
                </Form.Item>
              </Form>
            </Modal>

            <Modal
              title="Change Membership"
              visible={this.state.memberships}
              onOk={() => {
                this.setState({ memberships: false });
              }}
              onCancel={() => {
                this.setState({ memberships: false });
              }}
            >
              <Form
                name="changeMembershipForm"
                labelCol={{
                  span: 6,
                }}
                wrapperCol={{
                  span: 20,
                }}
                initialValues={{
                  remember: true,
                }}
                onFinish={async (val) => {
                  await axios
                    .patch("api/merchant/update/member", {
                      merchantId: this.state.merchant.objectId,
                      memberType: val.newMembership,
                    })
                    .then(function (response) {
                      message.info(
                        `The Member type has change to ${response.data.merchant.memberType}`
                      );
                    })
                    .catch((err) => {
                      message.error(
                        "You are not authorized to perform this operation."
                      );
                    });
                  //console.log(this.state);
                  this.setState({ charge: false });
                }}
                onFinishFailed={() => {}}
              >
                <p>
                  Current Membership :{" "}
                  {this.state.memberType ? this.state.memberType : "-"}
                </p>
                <Form.Item
                  label="Member Type"
                  name="newMembership"
                  rules={[
                    {
                      required: true,
                      message: "Please choose the memberType",
                    },
                  ]}
                >
                  <Select
                    placeholder="Choose Membertype"
                    style={{ width: 200 }}
                    allowClear
                    //onChange={value => onFiltersChange({...filters, channel: value})}
                  >
                    <Select.Option value="Vip">VIP 10000</Select.Option>
                    <Select.Option value="Vip_5000">VIP 5000</Select.Option>
                    <Select.Option value="Vip_1000">VIP 1000</Select.Option>
                    <Select.Option value="Membership">Merbership</Select.Option>
                    <Select.Option value="Normal">Normal</Select.Option>
                    <Select.Option value="fookai_6_50">Fookai 6.50</Select.Option>
                    <Select.Option value="fookai_special">Fookai Sp.</Select.Option>
                    <Select.Option value="first_5_kg_special">First 5kg Sp.</Select.Option>
                  </Select>
                </Form.Item>

                <Form.Item
                  wrapperCol={{
                    offset: 1,
                    span: 16,
                  }}
                >
                  <Button type="primary" htmlType="submit">
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </Modal>
            <Button
              type="primary"
              size="middle"
              style={{ margin: 10 }}
              onClick={() => {
                this.setState({ editDetails: true });
              }}
            >
              Edit Details
            </Button>
            <Modal
              title="Edit Details"
              visible={this.state.editDetails}
              footer={null}
              onCancel={() => {
                this.setState({ editDetails: false });
              }}
              onOk={() => {
                this.setState({ editDetails: false });
              }}
            >
              <p>
                <strong>Phone Number</strong> :{" "}
                {this.state.countryCode + this.state.phone}
              </p>
              <p>
                <strong>Email</strong> : {this.state.email}
              </p>
              <Divider>Edit</Divider>
              <Form
                name="changePackageForm"
                labelCol={{
                  span: 6,
                }}
                wrapperCol={{
                  span: 20,
                }}
                initialValues={{
                  email: this.state.email,
                  phone: this.state.phone,
                  countryCode: this.state.countryCode,
                  username: this.state.email,
                }}
                onFinish={async (val) => {
                  this.setState({ editDetails: false });
                  await axios
                    .put(`/api/user/update`, {
                      userId: this.state.userId,
                      userData: {
                        email: val.email,
                        phone: val.phone,
                        countryCode: val.countryCode,
                        username: val.email,
                      },
                    })
                    .then(function (response) {
                      message.info(
                        `The user details has been updated. Refresh to verify the change.`
                      );
                    })
                    .catch((err) => {
                      message.error(
                        err.response?.data?.message || "Something went wrong."
                      );
                    });
                  this.setState({ charge: false });
                }}
                onFinishFailed={() => {}}
              >
                <Form.Item label="Phone" required>
                  <Input.Group compact>
                    <Form.Item
                      name="countryCode"
                      noStyle
                      rules={[
                        { required: true, message: "Country code is required" },
                      ]}
                    >
                      <Select style={{ width: "30%" }}>
                        <Select.Option value="+60">+60</Select.Option>
                        <Select.Option value="+65">+65</Select.Option>
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name="phone"
                      noStyle
                      rules={[
                        { required: true, message: "Phone number is required" },
                        { 
                          pattern: /^\d{9,}$/, 
                          message: "Phone number must be at least 9 digits and numeric only"
                        }
                      ]}
                    >
                      <Input style={{ width: "70%" }} />
                    </Form.Item>
                  </Input.Group>
                </Form.Item>
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    { required: true, message: "Email is required" },
                    { type: 'email', message: "Please enter a valid email address" }
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  wrapperCol={{
                    offset: 1,
                    span: 16,
                  }}
                >
                  <Button type="primary" htmlType="submit">
                    Update
                  </Button>
                </Form.Item>
              </Form>
            </Modal>
          {this.state.permission === "Admin"? <Button
              type="primary"
              size="middle"
              style={{margin : 10}}
              onClick={() => {
                this.setState({ charge : true });
              }}
            >
              Charge Card
            </Button> : this.state.permission === "Finance" ? <Button
              type="primary"
              size="middle"
              style={{margin : 10}}
              onClick={() => {
                this.setState({ charge : true });
              }}
            >
              Charge Card
            </Button> : ""}
            <Modal
              title="Charge Card"
              visible={this.state.charge}
              onOk={() => {
                this.setState({ charge: false });
              }}
              onCancel={() => {
                this.setState({ charge: false });
              }}
            >
              <Form
                name="chargeform"
                labelCol={{
                  span: 4,
                }}
                wrapperCol={{
                  span: 20,
                }}
                initialValues={{
                  remember: true,
                }}
                onFinish={async (val) => {
                  const credit = parseFloat(val.credit);
                  await axios.post(
                    "/api/user/charge",
                    {
                      amt: credit,
                      merchant: this.state.merchant.objectId,
                      remarks: val.remarks
                    }
                  ).catch((err)=>{
                    message.error("You are not authorized to perform this operation.")
                  })
                  //console.log(this.state);
                  this.setState({ charge: false });
                }}
                onFinishFailed={() => {}}
              >
                <Form.Item
                  label="Amount"
                  name="credit"
                  rules={[
                    {
                      required: true,
                      message: "Please input your amount!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Remarks"
                  name="remarks"
                  rules={[
                    {
                      required: true,
                      message: "Please input your remarks!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  wrapperCol={{
                    offset: 1,
                    span: 16,
                  }}
                >
                  <Button type="primary" htmlType="submit">
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </Modal>
            {this.state.permission === "Admin" ? <Button
              type="primary"
              size="middle"
              onClick={() => {
                this.setState({ credit: true });
              }}
            >
              Manage Credit
            </Button> : this.state.permission === "Finance" ? <Button
              type="primary"
              size="middle"
              onClick={() => {
                this.setState({ credit: true });
              }}
            >
              Manage Credit
            </Button> : ""}
            <Modal
              title="Manage Credit"
              visible={this.state.credit}
              onOk={() => {
                this.setState({ credit: false });
              }}
              onCancel={() => {
                this.setState({ credit: false });
              }}
            >
              <Form
                name="creditform"
                labelCol={{
                  span: 4,
                }}
                wrapperCol={{
                  span: 20,
                }}
                initialValues={{
                  remember: true,
                }}
                onFinish={async (val) => {
                  const credit = parseFloat(val.credit);
                  await axios.post(
                    "/api/user/credit",
                    {
                      credit: credit,
                      user_id: this.state.userId,
                      remarks: val.remarks,
                      reference: val.reference,
                    }
                  ).catch(err=>{
                    message.error("You are not authorized to perform this operation.")
                  })
                  this.setState({ credit: false });
                }}
                onFinishFailed={() => {}}
              >
                <Form.Item
                  label="Credit"
                  name="credit"
                  rules={[
                    {
                      required: true,
                      message: "Please input your credit!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Remarks"
                  name="remarks"
                  rules={[
                    {
                      required: true,
                      message: "Please select your remarks!",
                    },
                  ]}
                >
                  <Select>
                    {ADMIN_ADJUSTMENT_REMARKS.map((remark)=>{
                      return <Select.Option key={remark}>{remark}</Select.Option>
                    })}
                  </Select>
                </Form.Item>

                <Form.Item
                  label="Reference"
                  name="reference"
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  wrapperCol={{
                    offset: 1,
                    span: 16,
                  }}
                >
                  <Button type="primary" htmlType="submit">
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </Modal>
            <Button type="primary" size="middle" className="back-btn">
              Back
            </Button>
          </Col>
        </Row>
        <div className="table">
          <Tabs defaultActiveKey="1" animated={true}>
            <TabPane tab="USER DETAILS" key="1">
              <Row className="mb-2 cod-stats" gutter={16}>
                <Col span={8}>
                  <Card style={{ width: 300 }}>
                    <div style={{ textAlign: "center", color: "#575757" }}>
                      <UserOutlined style={{ fontSize: 70 }} />
                      <p className="user-info">
                        {this.state.alias}
                        <br />
                        <PhoneFilled />
                        &ensp;{this.state.phone}
                        <br />
                        <MailFilled />
                        &ensp;{this.state.email}
                        <br />
                        <IdcardFilled />
                        &ensp;{this.state.type}
                      </p>
                    </div>
                  </Card>
                </Col>

                <Col span={8}>
                  <Card style={{ width: 300 }}>
                    <div style={{ textAlign: "center", color: "#575757" }}>
                      <PayCircleOutlined style={{ fontSize: 70 }} />
                      <p className="user-info">
                        <br />
                        <BankFilled />
                        &ensp;{this.state.bankName} ({this.state.bankAccount})
                        <br />
                        <CreditCardFilled />
                        &ensp;{this.state.merchant?.cardNumber || "-"}
                      </p>
                    </div>
                  </Card>
                </Col>
              </Row>

                  <div style={{ padding: "0 20px" }}>
                    <Row gutter={[8, 8]}>
                      <Col span={8}>Date Registration Account:</Col>
                      <Col span={12}>{this.state.createdAt? this.state.createdAt: "-" }</Col>
                    </Row>
                    <Row gutter={[8, 8]}>
                      <Col span={8}>Merchant Name :</Col>
                      <Col span={12}>{this.state.name? this.state.name: "-"}</Col>
                    </Row>
                    <Row gutter={[8, 8]}>
                      <Col span={8}>Topup Package :</Col>
                      <Col span={12}><TopupPackageTag packageKey={this.state.topupPackage} /></Col>
                    </Row>
                    <Row gutter={[8, 8]}>
                      <Col span={8}>Referral Code :</Col>
                      <Col span={12}>{this.state.referralCode? this.state.referralCode : "-"}</Col>
                    </Row>
                    <Row gutter={[8, 8]}>
                      <Col span={8}>Business Registration Number :</Col>
                      <Col span={12}>{this.state.BRN? this.state.BRN : "-"}</Col>
                    </Row>
                    <Row gutter={[8, 8]}>
                      <Col span={8}>Credits Balance:</Col>
                      <Col span={12}>{this.state.credits? "RM"+ this.state.credits : "-"}</Col>
                    </Row>
                    <Row gutter={[8, 8]}>
                      <Col span={8}>Total Topup :</Col>
                      <Col span={12}>{this.state.totalTopup? "RM"+ this.state.totalTopup : "-" }</Col>
                    </Row>
                    <Row gutter={[8, 8]}>
                      <Col span={8}>Bank Name :</Col>
                      <Col span={12}>{this.state.bankName? this.state.bankName: "-" }</Col>
                    </Row>
                    <Row gutter={[8, 8]}>
                      <Col span={8}>Bank Account :</Col>
                      <Col span={12}>{this.state.bankAccount? this.state.bankAccount: "-" }</Col>
                    </Row>
                    <Row gutter={[8, 8]}>
                      <Col span={8}>Bank Holder Name :</Col>
                      <Col span={12}>{this.state.bankHolderName? this.state.bankHolderName: "-" }</Col>
                    </Row>
                    <Row gutter={[8, 8]}>
                      <Col span={8}>Credit Card No. :</Col>
                      <Col span={12}>{this.state.merchant?.cardNumber || "-" }</Col>
                    </Row>
                    <Row gutter={[8, 8]}>
                      <Col span={8}>Total Order Parcel :</Col>
                      <Col span={12}>{this.state.total? this.state.total : "0" }</Col>
                    </Row>
                    <Row gutter={[8, 8]}>
                      <Col span={8}>Total Topup Transaction :</Col>
                      <Col span={12}>{this.state.topupData? this.state.topupData.length : "-"}</Col>
                    </Row>
                    <Row gutter={[8, 8]}>
                      <Col span={8}>Pending Remittance : </Col>
                      <Col span={12}>{this.state.payment_data.pendingRemittance ? `RM ${this.state.payment_data.pendingRemittance}` : "-" }</Col>
                    </Row>
                    <Row gutter={[8, 8]}>
                      <Col span={8}>Completed Remittance : </Col>
                      <Col span={12}>{this.state.payment_data.paymentAdvice? `RM ${this.state.payment_data.paymentAdvice}` : "-" }</Col>
                    </Row>
                    <Row gutter={[8, 8]}>
                      <Col span={8}>Where you heard abour us : </Col>
                      <Col span={12}>{this.state.heardFrom ? this.state.heardFrom : "-" }</Col>
                    </Row>
                    <Row gutter={[8, 8]}>
                      <Col span={8}>Account Manager : </Col>
                      <Col span={12}>{this.state.accountManager ? this.state.accountManager : "-" }</Col>
                    </Row>
                    <Row gutter={[8, 8]}>
                      <Col span={8}>Credit Tagging : </Col>
                      <Col span={12}>{this.state.tagging ? this.state.tagging : "-" }</Col>
                    </Row>
                  </div>
            </TabPane>
            <TabPane tab="ANALYTICS" key="analytics">
              { this.state.merchantId && <UserAnalytic merchantId={this.state.merchantId} /> }
            </TabPane>
            <TabPane tab="TRANSACTIONS" key="all-transactions">
              <Tabs
                defaultActiveKey="1"
                type="card"
                animated={true}
                size="small"
              >
                <TabPane tab="ALL TRANSACTION" key="10" animated={false}>
                  {this.state.merchantId && (
                    <UserTransaction
                      merchantId={this.state.merchantId}
                      loading={loading}
                      method={""}
                    />
                  )}
                </TabPane>
                <TabPane tab="CONSIGNMENT PURCHASE" key="2" animated={false}>
                  {this.state.merchantId && (
                    <UserTransaction
                      merchantId={this.state.merchantId}
                      loading={loading}
                      method={"consignment_purchase"}
                    />
                  )}
                </TabPane>
                <TabPane tab="REFUND" key="3">
                  {this.state.merchantId && (
                    <UserTransaction
                      merchantId={this.state.merchantId}
                      loading={loading}
                      method={"refund"}
                    />
                  )}
                </TabPane>
                <TabPane tab="COD REFUND" key="4">
                  {this.state.merchantId && (
                    <UserTransaction
                      merchantId={this.state.merchantId}
                      loading={loading}
                      method={"refund-cod-charge"}
                    />
                  )}
                </TabPane>
                <TabPane tab="NEXT DAY REFUND" key="5">
                  {this.state.merchantId && (
                    <UserTransaction
                      merchantId={this.state.merchantId}
                      loading={loading}
                      method={"refund-next-day-remittance-charge"}
                    />
                  )}
                </TabPane>
                <TabPane tab="ONLINE PAYMENT" key="6">
                  {this.state.merchantId && (
                    <UserOnlinePayment
                      merchantId={this.state.merchantId}
                      loading={loading}
                    />
                  )}
                </TabPane>
                <TabPane tab="ADMIN ADJUSTMENT" key="7">
                  {this.state.merchantId && (
                    <UserAdminAdjustment
                      merchantId={this.state.merchantId}
                      loading={loading}
                    />
                  )}
                </TabPane>
                <TabPane tab="WEIGHT DISPUTE" key="8">
                  {this.state.merchantId && (
                    <UserTransaction
                      merchantId={this.state.merchantId}
                      loading={loading}
                      method={"weight-dispute"}
                    />
                  )}
                </TabPane>
                <TabPane tab="WEIGHT DISPUTE REFUND" key="weightDisputeRefund">
                  {this.state.merchantId && (
                    <UserTransaction
                      merchantId={this.state.merchantId}
                      loading={loading}
                      method={"refund-weight-dispute"}
                    />
                  )}
                </TabPane>
                <TabPane tab="Return Handling FEE REFUND" key="returnFeeRefund">
                  {this.state.merchantId && (
                    <UserTransaction
                      merchantId={this.state.merchantId}
                      loading={loading}
                      method={"Refund Return Handling Fee"}
                    />
                  )}
                </TabPane>
              </Tabs>
            </TabPane>
            <TabPane tab="ALL ORDER" key="order-history">
              { this.state.merchantId && <UserOrder merchantId={this.state.merchantId} userId={this.state.userId} /> }
            </TabPane>
            <TabPane tab="COD ORDER" key="7">
              { this.state.merchantId && <UserCodOrder merchantId={this.state.merchantId} userId={this.state.userId}/> }
            </TabPane>
            <TabPane tab="RETURNED ORDER" key="returned-order">
              { this.state.merchantId && <UserReturnedOrder merchantId={this.state.merchantId} userId={this.state.userId}/> }
            </TabPane>
            <TabPane tab="AUDIT LOG" key="8">
              <Pagination 
                style={{textAlign: 'right'}}
                defaultCurrent={1} 
                total={this.state.auditLogs.pagination.totalSize}
                onChange={pageNumber => this.onAuditLogPageChange(pageNumber)}
              />

              <AuditLogList auditLogs={this.state.auditLogs.data} />

              <Pagination 
                style={{textAlign: 'right'}}
                defaultCurrent={1} 
                total={this.state.auditLogs.pagination.totalSize}
                onChange={pageNumber => this.onAuditLogPageChange(pageNumber)}
              />
            </TabPane>
            <TabPane tab="FEATURES" key="beta-features">
              <UserBetaFeatures userId={this.state.userId} betaFeatures={this.state.betaFeatures}/>
            </TabPane>
          </Tabs>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  token: state.auth.token,
});

export default connect(mapStateToProps)(UserDetails);
