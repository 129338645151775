import React from "react"
import { Select, Space } from "antd"

const UnrecognizedStatusOrderFilters = ({filters, onFiltersChange}) => {
  return (
    <Space size="large">
      <div>
        Courier :{` `}
        <Select 
          placeholder="ALL"
          style={{ width: 200 }}
          allowClear 
          onChange={value => onFiltersChange({...filters, serviceProvider: value})}
        >
          <Select.Option value="dhl">DHL</Select.Option>
          <Select.Option value="flash">Flash</Select.Option>
          <Select.Option value="jnt">JnT</Select.Option>
          <Select.Option value="ninjavan">Ninjavan</Select.Option>
          <Select.Option value="pickupp">Pickupp</Select.Option>
          <Select.Option value="teleport">Teleport</Select.Option>
          <Select.Option value="sfstandard">SF Standard</Select.Option>
          <Select.Option value="sfeconomy">SF Economy</Select.Option>
          <Select.Option value="fedex">Fedex</Select.Option>
          <Select.Option value="aramex">Aramex</Select.Option>
          <Select.Option value="poslaju">Poslaju</Select.Option>
          <Select.Option value="citylink">Citylink</Select.Option>
          <Select.Option value="lineclear">Lineclear</Select.Option>
          <Select.Option value="best">Best</Select.Option>
          <Select.Option value="imile">IMile</Select.Option>
          <Select.Option value="redly">Redly</Select.Option>
        </Select>
      </div>
    </Space>
  )
}

export default UnrecognizedStatusOrderFilters;
