import React, {useState } from "react"
import { Select, DatePicker, Space, Input} from "antd"

const ReturnedOrderFilters = ({filters, onFiltersChange}) => {
  const [searchField, setSearchField] = useState("trackingNo");

  const searchBy = (
    <Select
      defaultValue="trackingNo"
      className="select-before"
      onChange={(value) => setSearchField(value)}
    >
      <Select.Option value="trackingNo">Tracking No</Select.Option>
      <Select.Option value="email">Receiver Email</Select.Option>
    </Select>
  );
  
  return (
    <Space size="large">
      <div>
        Order At :{` `}
        <DatePicker.RangePicker
          allowClear={false}
          allowEmpty={false}
          value={[filters.startDate, filters.endDate]}
          onChange={dates => (dates 
              ? onFiltersChange({...filters, startDate: dates[0], endDate: dates[1]}) 
              : onFiltersChange({...filters, startDate: undefined, endDate: undefined})
          )}
        />
      </div>

      <div>
        Refunded At :{` `}
        <DatePicker.RangePicker
          value={[filters.codRefundedStartDate, filters.codRefundedEndDate]}
          onChange={dates => (dates 
              ? onFiltersChange({...filters, codRefundedStartDate: dates[0], codRefundedEndDate: dates[1]}) 
              : onFiltersChange({...filters, codRefundedStartDate: undefined, codRefundedEndDate: undefined})
          )}
        />
      </div>

      <div>
        COD :{` `}
        <Select 
          placeholder="ALL"
          style={{ width: 200 }}
          allowClear 
          onChange={value => onFiltersChange({...filters, codOnly: value})}
        >
          <Select.Option value="true">Yes</Select.Option>
        </Select>
      </div>

      <div>
        COD Refunded :{` `}
        <Select 
          placeholder="ALL"
          style={{ width: 200 }}
          allowClear 
          onChange={value => onFiltersChange({...filters, isCodRefunded: value})}
        >
          <Select.Option value="true">Yes</Select.Option>
          <Select.Option value="false">No</Select.Option>
        </Select>
      </div>

      <div>
        Next Day Remittance :{` `}
        <Select 
          placeholder="ALL"
          style={{ width: 200 }}
          allowClear 
          onChange={value => onFiltersChange({...filters, isNextDayRemittance: value})}
        >
          <Select.Option value="true">Yes</Select.Option>
          <Select.Option value="false">No</Select.Option>
        </Select>
      </div>

      <div>
        Search :{` `}
        <Input.Search
          addonBefore={searchBy}
          placeholder="Search"
          style={{ width: 500 }}
          onSearch={(term) =>
            onFiltersChange({ ...filters, search: term, searchField })
          }
        />
      </div>
    </Space>
  )
}

export default ReturnedOrderFilters;
