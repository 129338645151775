import React from "react";
import axios from "axios";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import "react-vis/dist/style.css";

const json = {
  showQuestionNumbers: "off",
  questionErrorLocation: "bottom",
  width: "100vh",
  elements: [
    {
      type: "text",
      title: "Questionaire Title",
      defaultValue: "Default Title Here",
      name: "title",
    },
    {
      type: "text",
      title: "Description",
      name: "description",
    },
    {
      type: "file",
      title: "Please upload your photo (widget)",
      name: "photo",
      acceptedTypes: "image/*",
      storeDataAsText: false,
      waitForUpload: true,
      maxSize: 512000,
      hideNumber: true,
      imageHeight: 280,
      imageWidth: 280,
    },
    {
      type: "paneldynamic",
      name: "questions",
      title: "Questions",
      keyName: "name",
      showQuestionNumbers: "off",
      templateTitle: "Question {panelIndex}",
      templateElements: [
        {
          type: "dropdown",
          name: "type",
          title: "Select question type",
          isRequired: true,
          choices: [
            {
              value: 0,
              text: "text",
            },
            {
              value: 1,
              text: "dropdown",
            },
            {
              value: 2,
              text: "radiogroup",
            },
            {
              value: 3,
              text: "checkbox",
            },
            {
              value: 4,
              text: "comment",
            },
            {
              value: 5,
              text: "boolean",
            },
            {
              value: 6,
              text: "rating",
            },
          ],
        },
        {
          type: "text",
          name: "name",
          title: "Name:",
          isRequired: true,
          visibleIf: "{panel.type} notEmpty",
        },
        {
          type: "text",
          name: "title",
          title: "Title:",
          isRequired: true,
          visibleIf: "{panel.type} notEmpty",
        },
        {
          type: "text",
          name: "rateMin",
          visibleIf: "{panel.type} = 6",
          title: "Rate Min:",
          inputType: "number",
          isRequired: true,
        },
        {
          type: "text",
          name: "rateMax",
          title: "Rate Max:",
          visibleIf: "{panel.type} = 6",
          inputType: "number",
          isRequired: true,
        },
        {
          type: "text",
          name: "descRateMin",
          visibleIf: "{panel.type} = 6",
          title: "Description of Rate Min:",
          isRequired: true,
        },
        {
          type: "text",
          name: "descRateMax",
          visibleIf: "{panel.type} = 6",
          title: "Description of Rate Max:",
          isRequired: true,
        },
        {
          type: "dropdown",
          name: "choices",
          title: "Choices:",
          visibleIf: "{panel.type} <=3 && {panel.type} >=1",
          isRequired: true,
          choices: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        },
        {
          type: "boolean",
          name: "required",
          visibleIf: "{panel.type} notEmpty",
          title: "Required",
          isRequired: false,
        },
        {
          type: "multipletext",
          colCount: 2,
          isRequired: true,
          items: [
            {
              name: "answer1",
              title: "Answer Choice 1",
              isRequired: true,
            },
            {
              name: "answer2",
              title: "Answer Choice 2",
              isRequired: true,
            },
            {
              name: "answer3",
              title: "Answer Choice 3",
              isRequired: true,
            },
            {
              name: "answer4",
              title: "Answer Choice 4",
              isRequired: true,
            },
            {
              name: "answer5",
              title: "Answer Choice 5",
              isRequired: true,
            },
            {
              name: "answer6",
              title: "Answer Choice 6",
              isRequired: true,
            },
            {
              name: "answer7",
              title: "Answer Choice 7",
            },
            {
              name: "answer8",
              title: "Answer Choice 8",
              isRequired: true,
            },
            {
              name: "answer9",
              title: "Answer Choice 9",
              isRequired: true,
            },
            {
              name: "answer10",
              title: "Answer Choice 10",
              isRequired: true,
            },
          ],
          name: "answer",
          title: "Answer Choice",
          visible: false,
          visibleIf: "{panel.choices}>0",
        },
      ],
      minPanelCount: 1,
      panelAddText: "Add question",
      panelRemoveText: "Remove item",
      addNewQuestions: true,
    },
  ],
};

const Questionaire = () => {
  const survey = new Model(json);
  const alertResults = async (sender) => {
    await axios
      .post("/api/questionaire/create-question", {
        questions: sender.data,
      })
      .then(() => {
        alert("Question succesfully updated");
        window.location = "/questionaire/list";
      });
  };

  function addFields(name, newValue, totalPanel) {
    for (let i = 0; i < totalPanel; i++) {
      const noOfChoices = newValue[i]["choices"];
      const question = survey
        .getQuestionByName("questions")
        .panels[i].getQuestionByName(name.toLowerCase());
      survey
        .getQuestionByName("questions")
        .panels[i].getQuestionByName(name.toLowerCase()).items = [];

      for (let i = 0; i < noOfChoices; i++) {
        question.addItem(name.toLowerCase() + (i + 1));
      }
    }
  }
  survey.onUploadFiles.add(async (_, options) => {
    let formData = new FormData();
    options.files.forEach(async (file) => {
      formData.append("file", file);
    });
    let response;
    try {
      await axios
        .post("/api/questionaire/upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          options.callback(
            "success",
            options.files.map((file) => {
              return {
                file: file,
                content: res.data.success,
              };
            })
          );
        });
    } catch (error) {
      console.log(error);
    }
  });
  survey.showCompletedPage = false;
  survey.onComplete.add(alertResults);
  survey.onValueChanged.add(function (sender, options) {
    const newValue = options.value;
    const totalPanel = survey.getQuestionByName("questions").value.length;
    addFields("answer", newValue, totalPanel);
  });

  return <Survey model={survey} />;
};

export default Questionaire;
