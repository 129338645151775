export const ADMIN_ADJUSTMENT_REMARKS = [
  "Manual COD transaction",
  "Manual Weight Dispute Refund",
  "Manual Bank Top Up",
  "Finance Adjustment(Manual Credit Order)",
  "Finance Adjustment(Claim Reimbursement Issue)",
  "Finance Adjustment(COD Advance Issue)",
  "Finance Adjustment(Topup Bonus Manual)",
  "Finance Adjustment(Returned Parcel (BA ISSUE))",
  "Finance Adjustment(Credit Customer Top Up)",
];
