import React from "react"
import { Select, DatePicker, Space, notification } from "antd"

const CodOrderFilters = ({filters, onFiltersChange}) => {
  return (
    <Space size="large">
      <div>
        Order At :{` `}
        <DatePicker.RangePicker
          allowClear={false}
          allowEmpty={false}
          value={[filters.startDate, filters.endDate]}
          onChange={dates => {
            if (dates && dates[1].diff(dates[0], 'days') > 90) {
              notification.error({
                message: "Date Range cannot be more than 90 days.",
                placement: "bottomRight"
              })
              return false
            }

            dates 
              ? onFiltersChange({...filters, startDate: dates[0], endDate: dates[1]}) 
              : onFiltersChange({...filters, startDate: undefined, endDate: undefined})
          }}
        />
      </div>

      <div>
        COD Currency :{` `}
        <Select
          style={{ width: 120 }}
          defaultValue={filters.codCurrencyCode}
          onChange={value => onFiltersChange({...filters, codCurrencyCode: value})}
        >
          <Select.Option value="RM">RM</Select.Option>
          <Select.Option value="SGD">SGD</Select.Option>
        </Select>
      </div>

      <div>
        Next Day Remittance :{` `}
        <Select 
          placeholder="ALL"
          style={{ width: 120 }}
          allowClear 
          onChange={value => onFiltersChange({...filters, isNextDayRemittance: value})}
        >
          <Select.Option value="true">Yes</Select.Option>
          <Select.Option value="false">No</Select.Option>
        </Select>
      </div>
    </Space>
  )
}

export default CodOrderFilters;
