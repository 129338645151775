import React from "react"
import { Table } from 'antd'

import { formatDateTime } from '../../helpers/date'

import ServiceProviderTag from '../shared/ServiceProviderTag'

const UnrecognizedStatusOrderList = ({ unrecognizedStatusOrders, loading }) =>{

  const table = [
    {
      title: "Consign No",
      dataIndex: "consign_no",
    },
    {
      title: "Courier",
      dataIndex: "serviceProvider",
      render: text => text ? <ServiceProviderTag serviceProviderKey={text} /> : ""
    },
    {
      title: "Order Status",
      dataIndex: ["status"],
      render: text => text ? <strong>{text}</strong> : "-"
    },
    {
      title: "Internal Order Status",
      dataIndex: ["internalStatus"],
      render: text => text ? <strong>{text}</strong> : "-"
    },
    {
      title: "Checkout At (YYYY-MM-DD)",
      dataIndex: "paymentSuccessAt",
      render: text => text ? formatDateTime(text) : "-"
    },
    {
      title: "Last Status At (YYYY-MM-DD)",
      dataIndex: "lastUpdateStatus",
      render: text => text ? formatDateTime(text) : "-"
    },
  ];

  return (
      <div className="table">
        <Table
          rowKey="objectId"
          className="table-section"
          columns={table}
          dataSource={unrecognizedStatusOrders}
          pagination={false}
          scroll={{ x: true }}
          loading={loading}
        />
      </div>
    )
}

export default UnrecognizedStatusOrderList
  