import React from 'react';
import ReactDOM from 'react-dom';
import { Route, Router, browserHistory } from 'react-router';
import store from './app/store';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import { syncHistoryWithStore } from 'react-router-redux';
import axios from "axios"
import './index.css';

import App from './components/App/App';
require('dotenv').config();

axios.defaults.baseURL = process.env.REACT_APP_SERVER_URL;
axios.interceptors.request.use(function (config) {
  const token = `Bearer ${store.getState().auth.token}`
  config.headers.Authorization = token;

  return config;
});

const history = syncHistoryWithStore(browserHistory, store);

ReactDOM.render(
  // <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <Router history={history}>
          <Route path="*" component={App}/>
          {/* <Route path="*" component={NotFoundPage}/> */}
        </Router>
      </Provider>
    </BrowserRouter>,
  // </React.StrictMode>,

  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
