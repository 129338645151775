import React from 'react'
import { Tag, Tooltip } from 'antd'

const status = [
    { key: "Whale", name: "Whale", color: "purple", title:"Make usage more than RM1000 in the last 7 days"},
    { key: "Rising Star", name: "Rising Star", color: "yellow", title:"Registered last 7 days and Usage more than RM500"},
];
   
const UserBadgeTag = ({statusKey}) => {

    const theStatus = status.find(theStatus => theStatus.key === statusKey)
    if (theStatus) {
      return(
        <React.Fragment>
          <Tooltip placement="topLeft" title={theStatus.title}>
           <Tag color={theStatus.color}>{theStatus.name}</Tag>
          </Tooltip>
        </React.Fragment>
      ) 
    }
    return null
  }
  
export default UserBadgeTag