import React from 'react'
import { Tag, Tooltip } from 'antd'

const status = [
    { key: "Approved", name: "Approved", color: "green"},
    { key: "Rejected", name: "Rejected", color: "red"},
    { key: "Cancel Requested by User", name: "Requested", color: "blue"}
];
   
const CancelOrderStatusTag = ({statusKey, title}) => {
    console.log(statusKey)
    const theStatus = status.find(theStatus => theStatus.key === statusKey)
    if (theStatus) {
      return <Tag color={theStatus.color}>{theStatus.name}</Tag>
    }
    return null
  }
  
export default CancelOrderStatusTag