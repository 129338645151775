import React from "react"
import axios from 'axios'
import moment from 'moment'
import { CheckOutlined, CloseCircleOutlined } from '@ant-design/icons'
import ServiceProviderTag from '../shared/ServiceProviderTag'
import CancelRequestStatusTag from "../shared/CancelRequestStatusTag"
import { formatDateTime } from '../../helpers/date'
import { Pagination, Row, Col, Collapse, Drawer, Table, Button, Link, Tooltip, Modal, message, Tag} from 'antd'

const CancelRequestList = ({ cancelRequest, onApproveRejectedCancel, loading }) =>{

  const table = [
    {
      title: "ID",
      dataIndex: "objectId",
    },
    {
      title: "Merchant",
      dataIndex: ["merchant","name"],
    },
    {
      title: "Order ID",
      dataIndex: "order",
      render: (text, row) => text?.objectId || row.ref_no || "-"
    },
    {
      title:"Sender Email",
      dataIndex: ["order","pickup","email"]
    },
    {
      title:"Receiver Email",
      dataIndex: ["order","receiver","email"]
    },
    {
      title: "Tracking No",
      dataIndex: ["order","consign_no"],
    },
    {
      title: "Courier",
      dataIndex: "order",
      render: (text, row) => text?.serviceProvider ? <ServiceProviderTag serviceProviderKey={text.serviceProvider} /> : ""
    },
    {
      title: "Order Status",
      dataIndex: ["order","status"],
    },
    {
      title: "Amount",
      dataIndex: "amount",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text, row) => text? <CancelRequestStatusTag statusKey={text} /> :""
    },
    {
      title: "Request At (YYYY-MM-DD)",
      dataIndex: "createdAt",
      render: text => formatDateTime(text)
    },
    {
      title: "Reviewed At (YYYY-MM-DD)",
      dataIndex: "successAt",
      render: text => text ? formatDateTime(text) : ""

    },
    { 
      title: "Action", 
      dataIndex: "action",
      render: (_, row) =>
        <React.Fragment>
          <Tooltip placement="topLeft" title="ApproveRequest">
              <Button
              shape="circle" 
              icon={<CheckOutlined style={{ fontSize: "20px" }} 
              onClick={() => approveHandler(row)}/>} 
              disabled={row.isReviewed}
              />
          </Tooltip>
          <Tooltip placement="topLeft" title="RejectRequest">
              <Button
              shape="circle" 
              icon={<CloseCircleOutlined style={{ fontSize: "20px" }} 
              onClick={() => rejectHandler(row)}/>} 
              disabled={row.isReviewed}
              />
          </Tooltip>
        </React.Fragment>
    },
  ];
  const approveHandler = cancelRequest => {
  
    Modal.confirm({
      title: `Are you sure to approve the cancellation for this Order?`,
      onOk() {
        axios.post(`/api/cancel-requests/${cancelRequest.objectId}/approve`).then(res => {
          onApproveRejectedCancel()
          message.info(`The cancelation request  (${cancelRequest.objectId}) has been approve.`)     
        }).catch(error => {
          message.error(error.response.data.message || error.message)
        })
      },
    });
  }
  const rejectHandler = cancelRequest => {
  
    Modal.confirm({
      title: `Are you sure to reject the cancellation for this Order?`,
      onOk() {
        axios.post(`/api/cancel-requests/${cancelRequest.objectId}/reject`).then(res => {
          onApproveRejectedCancel()
          message.info(`The cancelation request (${cancelRequest.objectId}) has been rejected.`)     
        }).catch(error => {
          message.error(error.response.data.message || error.message)
        })
      },
    });
  }
  return (
      <div className="table">
        <Table
          rowKey="objectId"
          className="table-section"
          columns={table}
          dataSource={cancelRequest}
          pagination={false}
          scroll={{ x: true }}
          loading={loading}
        />
      </div>
    )
}

export default CancelRequestList
  