import React, { useState, useEffect } from "react";
import { Row, Col, Card, Typography, Collapse, Spin, message } from "antd";
import { Line, Chart } from 'react-chartjs-2';
import { Chart as ChartJS, registerables } from 'chart.js';
import axios from "axios";
import moment from 'moment'

import PageHeader from "../Layout/PageHeader";
import CodAnalysisFilters from './CodAnalysisFilters'

ChartJS.register(...registerables);

const endOfThisMonth = moment().endOf('month')
const threeMonthsAgo = moment().subtract(3, 'months').startOf('month')

const CodAnalysis = () => {
  const [filters, setFilters] = useState({ startDate: threeMonthsAgo, endDate: endOfThisMonth, codCurrencyCode: 'RM' })
  const [monthlyCodStats, setMonthlyCodStats] = useState([])
  const [loading, setLoading] = useState({ cod: false })

  useEffect(() => {
    const filterParams = {
      startDate: filters.startDate?.startOf('month')?.toISOString(),
      endDate: filters.endDate?.endOf('month')?.toISOString(),
      codCurrencyCode: filters.codCurrencyCode
    }

    const fetchMonthlyCodOrderStats = async () => {
      setLoading(prevLoading => ({ ...prevLoading, cod: true }))

      try {
        const res = await axios.get(`/api/analysis/monthly-cod-order`, {
          params: { ...filterParams }
        })
        setMonthlyCodStats(res.data.data)
      } catch (error) {
        message.error(error.response?.data?.message || error.message, 0);
      }

      setLoading(prevLoading => ({ ...prevLoading, cod: false }))
    }

    fetchMonthlyCodOrderStats()
  }, [filters])

  return (
    <React.Fragment>
      <PageHeader 
        title="COD Analytics" 
        childRoutes={[{ path: "/cod-analysis", breadcrumbName: "COD Analytics" }]}
      />

      <Collapse>
        <Collapse.Panel 
          header={`Filters (${filters.startDate.format('MMM YYYY')} - ${filters.endDate.format('MMM YYYY')}, ${filters.codCurrencyCode})`} 
          key="filters"
        >
          <CodAnalysisFilters
            filters={filters} 
            onFiltersChange={filters => setFilters(filters)} 
          />
        </Collapse.Panel>
      </Collapse>

      <Typography.Title level={4} className="mt-4">Cash On Delivery</Typography.Title>
      <Row gutter={16}>
        <Col md={12}>
          <Card title={`Total Remitted Amount (${filters.codCurrencyCode})`}>
          {loading.cod 
            ? <Spin /> 
            : <Line
              data={{
                labels: monthlyCodStats[0]?.data?.map(stat => `${stat.year}-${stat.month}`),
                datasets: [{
                  label: 'COD Amount',
                  backgroundColor: 'rgb(99, 99, 132)',
                  fill: true,
                  order: 1,
                  borderColor: 'rgb(99, 99, 132)',
                  data: monthlyCodStats.find(stat => stat.category === 'remitted')?.data?.map(stat => stat.codTotal),
                }, {
                  label: 'Remitted Amount',
                  fill: true,
                  backgroundColor: 'rgb(255, 99, 132)',
                  borderColor: 'rgb(255, 99, 132)',
                  data: monthlyCodStats.find(stat => stat.category === 'remitted')?.data?.map(stat => stat.remittedTotal),
                }]
              }}
              options={{
                interaction: {
                  mode: 'index'
                }
              }}
            />}
          </Card>
        </Col>

        <Col md={12}>
          <Card title="Total Remitted Count">
          {loading.cod 
            ? <Spin /> 
            : <Line
              data={{
                labels: monthlyCodStats[0]?.data?.map(stat => `${stat.year}-${stat.month}`),
                datasets: [{
                  label: 'COD Count',
                  backgroundColor: 'rgb(99, 99, 132)',
                  fill: true,
                  order: 1,
                  borderColor: 'rgb(99, 99, 132)',
                  data: monthlyCodStats.find(stat => stat.category === 'remitted')?.data?.map(stat => stat.codCount),
                }, {
                  label: 'Remitted Count',
                  fill: true,
                  backgroundColor: 'rgb(255, 99, 132)',
                  borderColor: 'rgb(255, 99, 132)',
                  data: monthlyCodStats.find(stat => stat.category === 'remitted')?.data?.map(stat => stat.remittedCount),
                }]
              }}
              options={{
                interaction: {
                  mode: 'index'
                }
              }}
            />}
          </Card>
        </Col>
      </Row>

      <Row gutter={16} className="mt-4">
        <Col md={12}>
          <Card title={`Total Next Day Remittance Amount (${filters.codCurrencyCode})`}>
          {loading.cod 
            ? <Spin /> 
            : <Line
              data={{
                labels: monthlyCodStats[0]?.data?.map(stat => `${stat.year}-${stat.month}`),
                datasets: [{
                  label: 'COD Amount',
                  backgroundColor: 'rgb(99, 99, 132)',
                  fill: true,
                  order: 1,
                  borderColor: 'rgb(99, 99, 132)',
                  data: monthlyCodStats.find(stat => stat.category === 'remitted')?.data?.map(stat => stat.codTotal),
                }, {
                  label: 'Next Day Remittance Amount',
                  fill: true,
                  backgroundColor: 'rgb(255, 99, 132)',
                  borderColor: 'rgb(255, 99, 132)',
                  data: monthlyCodStats.find(stat => stat.category === 'ndr')?.data?.map(stat => stat.ndrTotal),
                }]
              }}
              options={{
                interaction: {
                  mode: 'index'
                }
              }}
            />}
          </Card>
        </Col>

        <Col md={12}>
          <Card title="Total Next Day Remittance Count">
          {loading.cod 
            ? <Spin /> 
            : <Line
              data={{
                labels: monthlyCodStats[0]?.data?.map(stat => `${stat.year}-${stat.month}`),
                datasets: [{
                  label: 'COD Count',
                  backgroundColor: 'rgb(99, 99, 132)',
                  fill: true,
                  order: 1,
                  borderColor: 'rgb(99, 99, 132)',
                  data: monthlyCodStats.find(stat => stat.category === 'remitted')?.data?.map(stat => stat.codCount),
                }, {
                  label: 'Next Day Remittance Count',
                  fill: true,
                  backgroundColor: 'rgb(255, 99, 132)',
                  borderColor: 'rgb(255, 99, 132)',
                  data: monthlyCodStats.find(stat => stat.category === 'ndr')?.data?.map(stat => stat.ndrCount),
                }]
              }}
              options={{
                interaction: {
                  mode: 'index'
                }
              }}
            />}
          </Card>
        </Col>

        <Col md={12}>
          <Card title="Next Day Remittance Order (Total COD Amount & Count)">
          {loading.cod 
            ? <Spin /> 
            : <Chart
              type="line"
              data={{
                labels: monthlyCodStats[0]?.data?.map(stat => `${stat.year}-${stat.month}`),
                datasets: [{
                  label: 'Total COD Amount (MYR)',
                  type: "line",
                  yAxisID: 'A',
                  backgroundColor: 'rgb(255, 99, 132)',
                  borderColor: 'rgb(255, 99, 132)',
                  data: monthlyCodStats.find(stat => stat.category === 'ndr')?.data?.map(stat => stat.ndrTotal),
                },
                {
                  label: 'Count',
                  type: "bar",
                  yAxisID: 'B',
                  backgroundColor: 'rgb(132, 99, 255)',
                  borderColor: 'rgb(132, 99, 255)',
                  data: monthlyCodStats.find(stat => stat.category === 'ndr')?.data?.map(stat => stat.ndrCount),
                }]
              }}
              options={{
                scales: {
                  A: {
                      type: 'linear',
                      position: 'left',
                      ticks: { color: 'rgb(255, 99, 132)' },
                  },
                  B: {
                      type: 'linear',
                      position: 'right',
                      ticks: { color: 'rgb(132, 99, 255)' },
                  }
                }
              }}
            />}
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default CodAnalysis;
