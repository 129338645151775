import React, { useState } from "react"
import axios from "axios"
import { Breadcrumb, Row, Col, Input, Table, Card, Statistic, Collapse, message, Tag } from "antd"
import { PhoneOutlined, CaretRightOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

import ServiceProviderTag from '../shared/ServiceProviderTag'

const routes = [
  { path: "", breadcrumbName: "App" },
  { path: "/receiver/order-stats", breadcrumbName: "Receiver Order Stats" }
];

const columns = [
  { title: "Order ID", dataIndex: "_id" },
  {
    title: "Tracking No",
    dataIndex: "consign_no",
    render: text => text || "-"
  },
  {
    title: "Service Provider",
    dataIndex: "serviceProvider",
    render: text => <ServiceProviderTag serviceProviderKey={text} />
  },
  { title: "COD", dataIndex: "cod" },
  { 
    title: "Status", 
    dataIndex: "status", 
    render: (status, order) => order.isReturnRequested 
      ? <span style={{ color: 'red' }}><ExclamationCircleOutlined /> {status}</span> 
      : status,
  },
];

const ReceiverOrderStats = () => {
  const [loading, setLoading] = useState(false)
  const [receivers, setReceivers] = useState(null)

  const fetchReceivers = async search => {
    if ((search || "").length < 6) {
      message.error('Please input at least 6 characters of phone number');
      return
    }

    setLoading(true)

    try {
      const res = await axios.get(`/api/receivers/order-stats`, { params: { phone: search } })
      setReceivers(res.data.data)
    } catch (error) {
      message.error(error.message || error)
      setReceivers(null)
    }

    setLoading(false)
  }

  return (
    <div>
      <div className="header">
        <Row>
          <Col span={12}>
            <h2 className="detail-header-text">Receiver Order Stats</h2>
          </Col>
          <Col span={12}>
            <Breadcrumb
            style={{ margin: "10px 0", textAlign: "right" }}
            routes={routes}
          />
          </Col>
        </Row>
      </div>

      <div>
        <Input.Search 
          prefix={<PhoneOutlined />}
          enterButton="Search Receiver Phone No"
          placeholder="Input at least 6 characters of Receiver's Phone Number" 
          onSearch={fetchReceivers} 
          size="large"
          loading={loading}
        />
      </div>

      { receivers && <div className="mt-4">
        <h3>Found {receivers.length} receivers</h3>
        <Collapse
          className="mt-4"
          bordered={false}
          expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
        >
          { receivers.map(receiver => {
            const returnRequestedCount = receiver.orders.filter(order => order.isReturnRequested)?.length
            const rto = (returnRequestedCount / (receiver.ordersCount || 0)) * 100

            return (
              <Collapse.Panel header={`${receiver.fullPhone} (${receiver.fullNames.join(', ')})`} key={receiver.fullPhone}>
                <Row className="mb-2 cod-stats" gutter={16}>
                  <Col span={6}>
                    <Card>
                      <Statistic
                        title="RTO"
                        value={rto}
                        precision={2}
                        valueStyle={{ color: rto > 1 ? 'orange' : '' }}
                        prefix={<ExclamationCircleOutlined />}
                        suffix="%"
                      />
                    </Card>
                  </Col>
                  <Col span={6}>
                    <Card>
                      <Statistic
                        title="Total Orders"
                        value={receiver.ordersCount}
                      />
                    </Card>
                  </Col>
                  <Col span={6}>
                    <Card>
                      <Statistic
                        title="Returned Orders"
                        value={returnRequestedCount}
                      />
                    </Card>
                  </Col>
                </Row>

                <div className="table mt-4" >
                  <Table
                    rowKey="objectId"
                    className="table-section"
                    columns={columns}
                    dataSource={receiver.orders}
                    scroll={{ x: true }}
                  />
                </div>
              </Collapse.Panel>
            )
          })}
        </Collapse>
      </div> }
    </div>
  )
}

export default ReceiverOrderStats