import React from "react"
import { DatePicker, Space } from "antd"

const AnalysisFilters = ({filters, onFiltersChange}) => {
  return (
    <Space size="large">
      <div>
        Month Range :{` `}
        <DatePicker.RangePicker
          allowClear={false}
          allowEmpty={false}
          picker="month"
          value={[filters.startDate, filters.endDate]}
          onChange={dates => (dates 
              ? onFiltersChange({...filters, startDate: dates[0], endDate: dates[1]}) 
              : onFiltersChange({...filters, startDate: undefined, endDate: undefined})
          )}
        />
      </div>
    </Space>
  )
}

export default AnalysisFilters;
