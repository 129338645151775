import React, { useState } from "react"
import { DatePicker, Space, Select, Input, notification } from "antd"

const OrderFilters = ({filters, onFiltersChange}) => {
  const [searchField, setSearchField] = useState("trackingNo")

  const searchBy = (
    <Select defaultValue="trackingNo" className="select-before" onChange={value => setSearchField(value)}>
      <Select.Option value="trackingNo">Tracking No</Select.Option>
      <Select.Option value="name">Receiver</Select.Option>
      <Select.Option value="orderId">Order Id</Select.Option>
    </Select>
  );

  return (
    <Space size="large">
      <div>
        Order At :{` `}
        <DatePicker.RangePicker
          allowClear={false}
          allowEmpty={false}
          value={[filters.startDate, filters.endDate]}
          onChange={dates => {
            if (dates && dates[1].diff(dates[0], 'days') > 90) {
              notification.error({
                message: "Date Range cannot be more than 90 days.",
                placement: "bottomRight"
              })
              return false
            }

            dates 
              ? onFiltersChange({...filters, startDate: dates[0], endDate: dates[1]}) 
              : onFiltersChange({...filters, startDate: undefined, endDate: undefined})
          }}
        />
      </div>

      <div>
        Channel :{` `}
        <Select 
          placeholder="ALL"
          style={{ width: 200 }}
          allowClear 
          onChange={value => onFiltersChange({...filters, channel: value})}
        >
          <Select.Option value="dashboard">Dashboard</Select.Option>
          <Select.Option value="dashboard-bulk">Dashboard (Bulk)</Select.Option>
          <Select.Option value="external">External</Select.Option>
          <Select.Option value="external-bulk">External (Bulk)</Select.Option>
          <Select.Option value="shopify">Shopify</Select.Option>
          <Select.Option value="woocommerce">WooCommerce</Select.Option>
        </Select>
      </div>

      <div>
        Search :{` `}
        <Input.Search 
          addonBefore={searchBy}
          placeholder="Search" 
          onSearch={term => onFiltersChange({...filters, search: term, searchField }) } 
        />
      </div>

      <div>
        Courier :{` `}
        <Select 
          placeholder="ALL"
          style={{ width: 200 }}
          allowClear 
          onChange={value => onFiltersChange({...filters, serviceProvider: value})}
        >
          <Select.Option value="dhl">DHL</Select.Option>
          <Select.Option value="flash">Flash</Select.Option>
          <Select.Option value="jnt">JnT</Select.Option>
          <Select.Option value="ninjavan">Ninjavan</Select.Option>
          <Select.Option value="pickupp">Pickupp</Select.Option>
          <Select.Option value="teleport">Teleport</Select.Option>
          <Select.Option value="sfstandard">SF Standard</Select.Option>
          <Select.Option value="sfeconomy">SF Economy</Select.Option>
          <Select.Option value="fedex">Fedex</Select.Option>
          <Select.Option value="aramex">Aramex</Select.Option>
          <Select.Option value="poslaju">Poslaju</Select.Option>
          <Select.Option value="citylink">Citylink</Select.Option>
          <Select.Option value="lineclear">Lineclear</Select.Option>
          <Select.Option value="best">Best</Select.Option>
          <Select.Option value="imile">IMile</Select.Option>
          <Select.Option value="redly">Redly</Select.Option>
        </Select>
      </div>

      <div>
        Internal Status :{` `}
        <Select 
          placeholder="ALL"
          style={{ width: 200 }}
          allowClear 
          onChange={value => onFiltersChange({...filters, internalStatus: value})}
        >
          <Select.Option value="Waiting Pickup">Waiting Pickup</Select.Option>
          <Select.Option value="Refunded">Refunded</Select.Option>
          <Select.Option value="Picked up">Picked up</Select.Option>
          <Select.Option value="In transit">In transit</Select.Option>
          <Select.Option value="Processing">Processing</Select.Option>
          <Select.Option value="Delivered">Delivered</Select.Option>
          <Select.Option value="On Delivery">On Delivery</Select.Option>
          <Select.Option value="Problematic Processing">Problematic Processing</Select.Option>
          <Select.Option value="Custom matter">Custom matter</Select.Option>
          <Select.Option value="Self Collect">Self Collect</Select.Option>
          <Select.Option value="Return in transit">Return in transit</Select.Option>
          <Select.Option value="Returned">Returned</Select.Option>
          <Select.Option value="Shipment Data Received">Shipment Data Received</Select.Option>
          <Select.Option value="Closed">Closed</Select.Option>
          <Select.Option value="Cancelled">Cancelled</Select.Option>
          <Select.Option value="Cancelled by User">Cancelled by User</Select.Option>
          <Select.Option value="COD amount deposited">COD amount deposited</Select.Option>
          <Select.Option value="COD amount remitted">COD amount remitted</Select.Option>
        </Select>
      </div>
    </Space>
  )
}

export default OrderFilters;