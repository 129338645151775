import React, { useState } from "react"
import { Select, DatePicker, Space,Input } from "antd"

const CancelRequestFilters = ({filters, onFiltersChange}) => {

  const [searchField, setSearchField] = useState("trackingNo")
  const searchBy = (
    <Select defaultValue="trackingNo" className="select-before" onChange={value => setSearchField(value)}>
      <Select.Option value="trackingNo">Tracking No</Select.Option>
      <Select.Option value="receiverEmail">Receiver Email</Select.Option>
      <Select.Option value="senderEmail">Sender Email</Select.Option>
      <Select.Option value="merchantEmail">Merchant Email</Select.Option>
    </Select>
  );

  return (
    <Space size="large">
       <div>
        Requested At :{` `}
        <DatePicker.RangePicker
          value={[filters.startDate, filters.endDate]}
          onChange={dates => (dates 
              ? onFiltersChange({...filters, startDate: dates[0], endDate: dates[1]}) 
              : onFiltersChange({...filters, startDate: undefined, endDate: undefined})
          )}
        />
      </div>

      <div>
        Reviewed At :{` `}
        <DatePicker.RangePicker
          value={[filters.reviewedStartDate, filters.reviewedEndDate]}
          onChange={dates => (dates 
              ? onFiltersChange({...filters, reviewedStartDate: dates[0], reviewedEndDate: dates[1]}) 
              : onFiltersChange({...filters, reviewedStartDate: undefined, reviewedEndDate: undefined})
          )}
        />
      </div>

      <div>
        Courier :{` `}
        <Select 
          placeholder="ALL"
          style={{ width: 200 }}
          allowClear 
          onChange={value => onFiltersChange({...filters, serviceProvider: value})}
        >
          <Select.Option value="poslaju">Poslaju</Select.Option>
          <Select.Option value="citylink">Citylink</Select.Option>
          <Select.Option value="redly">Redly</Select.Option>
        </Select>
      </div>

      <div>
        Status :{` `}
        <Select 
          placeholder="ALL"
          style={{ width: 120 }}
          allowClear 
          onChange={value => onFiltersChange({...filters, status: value})}
        >
          <Select.Option value="Requested">REQUESTED</Select.Option>
          <Select.Option value="Approved">APPROVED</Select.Option>
          <Select.Option value="Rejected">REJECTED</Select.Option>
        </Select>
      </div>

      <div>
        Search :{` `}
        <Input.Search 
          addonBefore={searchBy}
          placeholder="Search" 
          onSearch={term => onFiltersChange({...filters, search: term, searchField }) } 
        />
      </div>
    </Space>
  )
}

export default CancelRequestFilters;
