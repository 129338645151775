import React from "react";
import { Bar, Pie, HorizontalBar } from "react-chartjs-2";
import { Chart as ChartJS, registerables } from "chart.js";

ChartJS.register(...registerables);
const colors = [
  "magenta",
  "yellow",
  "green",
  "geekblue",
  "purple",
  "gold",
  "cyan",
  "lime",
  "green",
  "red",
];
const ChartRenderer = ({ type, data, index }) => {
  switch (type) {
    case "rating":
      return (
        <Bar
          data={{
            labels: Array.from(
              { length: data.question.rateMax - data.question.rateMin + 1 },
              (_, index) => data.question.rateMin + index
            ),
            datasets: [
              {
                backgroundColor: colors[index % colors.length],
                borderColor: colors[index % colors.length],
                data: Array.from(
                  { length: data.question.rateMax - data.question.rateMin + 1 },
                  (_, index) => data.question.rateMin + index
                ).map((index) => data.counts[index] || 0),
              },
            ],
          }}
          options={{
            plugins: {
              legend: {
                display: false,
              },
            },
            scales: {
              x: {
                title: {
                  display: true,
                  text: "Rating",
                },
              },
              y: {
                title: {
                  display: true,
                  text: "Number of Respondent",
                },
                ticks: {
                  precision: 0,
                },
              },
            },
          }}
        />
      );
    case "radiogroup":
      const radioLabels = Object.keys(data.counts).filter(
        (key) => key !== "undefined"
      );
      const radioData = radioLabels.map((label) => data.counts[label]);

      return (
        <Pie
          data={{
            labels: radioLabels,
            datasets: [
              {
                data: radioData,
                backgroundColor: colors,
                borderColor: "white",
                borderWidth: 1,
              },
            ],
          }}
          options={{
            responsive: true,
            plugins: {
              legend: {
                position: "right",
              },
              labels: {
                render: "percentage",
                fontColor: "#fff",
                precision: 0,
                position: "border",
                overlap: true,
              },
            },
          }}
        />
      );
    case "dropdown":
    case "checkbox":
      const checkboxLabels = Object.keys(data.question.answer)
        .filter((key) => key !== "undefined")
        .map((key) => data.question.answer[key]);
      const checkboxData = checkboxLabels.map(
        (label) => data.counts[label] || 0
      );

      if (
        checkboxLabels.length === 0 ||
        checkboxData.every((count) => count === 0)
      ) {
        return <p>No valid data available.</p>;
      }

      const backgroundColors = checkboxLabels.map(
        (_, i) => colors[i % colors.length]
      );

      return (
        <Bar
          data={{
            labels: checkboxLabels,
            datasets: [
              {
                label: data.title,
                data: checkboxData,
                backgroundColor: backgroundColors,
                borderColor: "black",
                borderWidth: 1,
              },
            ],
          }}
          options={{
            indexAxis: "y",
            responsive: true,
            plugins: {
              legend: {
                position: "right",
                labels: {
                  generateLabels: function (chart) {
                    const labels = chart.data.labels;
                    return labels.map((label, i) => {
                      return {
                        text: label,
                        fillStyle: chart.data.datasets[0].backgroundColor[i],
                      };
                    });
                  },
                },
              },
            },
            scales: {
              x: {
                title: {
                  display: true,
                  text: "Number of Respondents",
                },
                ticks: {
                  precision: 0,
                },
              },
              y: {
                title: {
                  display: true,
                  text: "Choices",
                },
              },
            },
          }}
        />
      );
    case "multipletext":
    case "text":
      return (
        <ul>
          {data.feedbacks
            .filter((feedback) => feedback !== "" && feedback)
            .map((feedback, index) => (
              <li key={index}>{feedback}</li>
            ))}
        </ul>
      );
    default:
      return null;
  }
};

export default ChartRenderer;
