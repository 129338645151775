import React, { useState, useEffect, useCallback } from "react";
import { Table, Pagination, Input, Select, Row, Col } from 'antd'
import axios from "axios";

import { formatDateTime } from "../../helpers/date";

const table = [
  {
    title: "Merchant",
    dataIndex: ["merchant", "user", "email"],
  },
  {
    title: "Redeemed At (YYYY-MM-DD)",
    dataIndex: "createdAt",
    render: text => formatDateTime(text)
  },
  {
    title: "Redeemed Amount",
    dataIndex: "amount",
    render: text => `RM ${text.toFixed(2)}`
  },
]

const DiscountCodeRedemptionList = ({ discountCode }) => {
  const [redemptions, setRedemptions] = useState([])
  const [filters, setFilters] = useState({ searchField: 'userEmail' })
  const [pagination, setPagination] = useState({ currentPage: 1, limit: 20 });

  const searchBy = (
    <Select defaultValue="userEmail" className="select-before" onChange={value => setFilters({...filters, searchField: value })}>
      <Select.Option value="userEmail">Email (Full)</Select.Option>
    </Select>
  );

  const fetchRedemptions = useCallback(async () => {
    const params = {
      ...(filters.search && { searchField: filters.searchField, search: filters.search }),
      limit: pagination.limit,
      page: pagination.currentPage,
    };

    const res = await axios.get(`/api/discount-codes/${discountCode.objectId}/redemptions`, { params })

    setRedemptions(res.data.data)
    setPagination(res.data.pagination)
  }, [discountCode, pagination.limit, pagination.currentPage, filters])

  useEffect(() => {
    fetchRedemptions()
  }, [fetchRedemptions, pagination.limit, pagination.currentPage])

  return (
    <div>
      <Row className="mt-2 mb-2" justify="space-between">
        <Col>
          <Pagination
            size="small"
            total={pagination?.totalSize}
            pageSize={pagination?.limit}
            onChange={(currentPage, limit) => {
              setPagination({ ...pagination, limit, currentPage });
            }}
            current={pagination.currentPage}
          />
        </Col>

        <Col>
          <Input.Search 
            addonBefore={searchBy}
            placeholder="Search" 
            onSearch={term => setFilters({...filters, search: term }) } 
          />
        </Col>
      </Row>

      <Table 
        rowKey="objectId"
        className="table-section"
        columns={table}
        dataSource={redemptions}
        pagination={false}
      />
    </div>
  )
}

export default DiscountCodeRedemptionList