import React from 'react'
import { Table, Tabs } from "antd"

const columnTypes = {
  prices: ["Id", "Region", "State", "min", "max", "dhl", "citylink", "jnt", "poslaju", "cj", "ninjavan", "pickupp", "fmx", "abx", "teleport", "flash","imile","redly"],
  international: ["Id", "Region", "State", "min", "max", "teleport", "sfeconomy", "sfstandard", "ninjavan","redly"],
};

const tables = {
  "prices": { name: 'Prices', columns: columnTypes.prices },
  "prices_vip_1000": { name: 'Prices VIP 1000', columns: columnTypes.prices },
  "prices_vip_5000": { name: 'Prices VIP 5000', columns: columnTypes.prices },
  "prices_vip": { name: 'Prices VIP', columns: columnTypes.prices },
  "membership": { name: 'Membership', columns: columnTypes.prices },
  "international_sg": { name: 'International SG', columns: columnTypes.international },
  "international_brunei": { name: 'International BN', columns: columnTypes.international },
  "first_5_kg_special": { name: 'First 5kg Special', columns: columnTypes.prices },
  "fookai_special": { name: 'Fookai Special', columns: columnTypes.prices },
  "fookai_6_50": { name: 'Fookai 6.50', columns: columnTypes.prices },
}

const PackagePricingTable = ({ pricing, isLoading }) => {
  return (
    <React.Fragment>
      {pricing && 
        <Tabs defaultActiveKey="pricing" animated={true}>
          { Object.keys(pricing).map(type => (
            <Tabs.TabPane tab={tables[type].name} key={type}>
              <Table
                rowKey="Id"
                className="table-section"
                columns={tables[type].columns.map(column => ({ title: column, dataIndex: column }))}
                dataSource={pricing[type]}
                loading={isLoading}
                pagination={{ pageSize: 50 }}
              />
            </Tabs.TabPane>
          )) }
        </Tabs>
      }
    </React.Fragment>
  )
}

export default PackagePricingTable