import React from 'react';
import { connect } from 'react-redux';
// import { Router, IndexRoute, browserHistory } from 'react-router';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import Login from '../Login/Login';
import { Menu, Layout } from 'antd';
import {
  PieChartOutlined,
  MailOutlined,
  ContainerOutlined,
  LogoutOutlined,
  UserOutlined,
  CarryOutOutlined,
  DollarOutlined,
  SettingOutlined,
  LineChartOutlined,
  MoneyCollectOutlined,
  FilePdfOutlined,
  NotificationOutlined,
  RiseOutlined,
  DashboardOutlined,
} from '@ant-design/icons';
import './App.css';
import 'antd/dist/antd.css'
import { LOGOUT } from './../../features/counter/authSlice';

import Dashboard from '../Dashboard/Dashboard';
import User from '../User/User';
import UserDetails from '../User/UserDetails';
import AllOrder from '../Order/AllOrder';
import NextDayRemittanceOrder from '../Order/NextDayRemittanceOrder';
import CodOrder from '../Order/CodOrder';
import ReturnedOrder from '../Order/ReturnedOrder';
import DiscountCode from '../DiscountCode/DiscountCode';
import BannerInactive from '../Banner/Inactive';
import BannerActive from '../Banner/Active';
import BulkDownloadConsignNote from '../ConsignNote/BulkDownloadConsignNote';
import Analysis from '../Analysis/Analysis';
import TableauAnalysis from '../Analysis/TableauAnalysis';
import CodAnalysis from '../Analysis/CodAnalysis';
import CourierAnalysis from '../Analysis/CourierAnalysis';
import Remittance from '../Remittance/Remittance'
import Payout from '../Remittance/Payout'
import CheckRemittance from '../Remittance/CheckRemittance'
import AddQuestionaire from "../Questionaire/AddQuestionaire";
import Questionaire from "../Questionaire/Questionaire";
import QuestionaireDetails from "../Questionaire/QuestionaireDetails";
import Email from '../Email/Email';
import Export from '../Export/Export'
import Transaction from '../Transactions/Transaction';
import Insurance from '../Insurance/Insurance';
import FlyersRequest from '../FlyersRequest/FlyersRequest';
import Settings from '../Settings/Settings'
import ReceiverOrderStats from '../Receiver/ReceiverOrderStats';
import Package from '../Package/Package';
import PackagePricing from '../Package/PackagePricing';
import TopupPackagePricing from '../Package/TopupPackagePricing';
import FeePackagePricing from '../Package/FeePackagePricing';
import CourierPackagePricing from '../Package/CourierPackagePricing';
import CancelRequest from '../Order/CancelRequest';
import UnrecognizedStatusOrder from '../Order/UnrecognizedStatusOrder';
import AccountManager from '../AccountManager/AccountManager';
import AccountManagerDetails from '../AccountManager/AccountManagerDetails';
import ReferralAccount from '../ReferralAccount/ReferralAccount';
import ReferreeAccountList from '../ReferralAccount/ReferreeAccountList';
import FlashPOD from '../FlashPOD/FlashPOD';
import WeightDispute from '../WeightDispute/WeightDispute';
import WeightDisputeByMerchant from '../WeightDispute/WeightDisputeByMerchant';
import NotificationAnalysis from '../Analysis/NotificationAnalysis';
import SubAccount from '../SubAccount/SubAccount';
import SubAccountDetails from '../SubAccount/SubAccountDetails';
import PayoutStatus from '../Payout/PayoutStatus';
const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;

class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      collapse: false
    };
  }

  render() {
    const { isAuthenticated } = this.props;
    // const dispatch = useDispatch();

    // console.log(isAuthenticated)
    return (
      <div>
        {isAuthenticated ?
          <Router>
            <Layout>
              <Sider
                width={220} 
                breakpoint="lg"
                collapsedWidth="0"
                collapsible={true}
                onBreakpoint={broken => {
                  // console.log(broken);
                }}
                onCollapse={(collapsed, type) => {
                  // console.log(collapsed, type);
                }}
              >
                {/* <div className="ant-layout-logo" >LOGO</div> */}
                <Menu
                  defaultSelectedKeys={['3']}
                  // defaultOpenKeys={['sub1']}
                  mode="inline"
                  theme="dark"
                  collapsed={this.state.collapsed}
                >
                  <SubMenu key="analyticsMenu" title="Analytics" icon={<LineChartOutlined />}>
                    <Menu.Item key="dashboard">
                      <Link to={`/`} activeclassname="active">
                        <DashboardOutlined />
                        <span>Dashboard</span>
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="tableau-analytics">
                      <Link to={`/tableau-analysis`} activeclassname="active">
                        <PieChartOutlined />
                        <span>Tableau</span>
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="general-analytics">
                      <Link to={`/analysis`} activeclassname="active">
                        <PieChartOutlined />
                        <span>General</span>
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="order-analytics">
                      <Link to={`/courier-order-analysis`} activeclassname="active">
                        <PieChartOutlined />
                        <span>Courier Order</span>
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="cod-analytics">
                      <Link to={`/cod-analysis`} activeclassname="active">
                        <MoneyCollectOutlined />
                        <span>COD</span>
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="notification-analytics">
                      <Link to={`/notification-analysis`} activeclassname="active">
                        <MoneyCollectOutlined />
                        <span>Notification</span>
                      </Link>
                    </Menu.Item>
                  </SubMenu>
                  <Menu.Item key="1">
                    <Link to={`/user`} activeclassname="active">
                      <UserOutlined />
                      <span>User</span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="subaccount">
                    <Link to={`/sub-account`} activeclassname="active">
                      <UserOutlined />
                      <span>Sub Account</span>
                    </Link>
                  </Menu.Item>
                  <SubMenu key="orderMenu" icon={<MailOutlined />} title="Order">
                    <Menu.Item key="allOrder">
                      <Link to={`/all-order`} activeclassname="active">
                        <PieChartOutlined />
                        <span>All Order</span>
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="nextDayRemittanceOrder">
                      <Link to={`/order/next-day-remittance-order`} activeclassname="active">
                        <PieChartOutlined />
                        <span>Next-day Remittance</span>
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="codOrder">
                      <Link to={`/order/cod-order`} activeclassname="active">
                        <PieChartOutlined />
                        <span>COD</span>
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="returnedOrder">
                      <Link to={`/order/returned-order`} activeclassname="active">
                        <PieChartOutlined />
                        <span>Returned</span>
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="cancelOrderRequest">
                      <Link to={`/order/cancel-request`} activeclassname="active">
                        <PieChartOutlined />
                        <span>Cancel Request</span>
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="unrecognizedStatusOrder">
                      <Link to={`/order/unrecognized-status`} activeclassname="active">
                        <PieChartOutlined />
                        <span>Unknown Status</span>
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="flashPOD">
                      <Link to={`/order/flash-pod`} activeclassname="active">
                        <PieChartOutlined />
                        <span>Flash POD</span>
                      </Link>
                    </Menu.Item>
                  </SubMenu>
                  <Menu.Item key="4">
                    <Link to={`/transaction`} activeclassname="active">
                      <ContainerOutlined />
                      <span>Transaction</span>
                    </Link>
                  </Menu.Item>
                  <SubMenu key="packageMenu" icon={<MailOutlined />} title="Package">
                    <Menu.Item key="allPackage">
                      <Link to={`/package`} activeclassname="active">
                        <PieChartOutlined />
                        <span>All Package</span>
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="topupPackagePricing">
                      <Link to={`/topup-package/pricing`} activeclassname="active">
                        <DollarOutlined />
                        <span>Pricing</span>
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="feePackagePricing">
                      <Link to={`/fee-package/pricing`} activeclassname="active">
                        <DollarOutlined />
                        <span>Fee Pricing</span>
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="courierPackagePricing">
                      <Link to={`/courier-package/pricing`} activeclassname="active">
                        <DollarOutlined />
                        <span>Courier Pricing</span>
                      </Link>
                    </Menu.Item>
                  </SubMenu>
                  <SubMenu key="setting" icon={<UserOutlined />} title="System">
                    <Menu.Item key="system">
                      <Link to={`/system`} activeclassname="active">
                        <SettingOutlined />
                        <span>Settings</span>
                      </Link>
                    </Menu.Item>
                    <SubMenu
                      key="questionaire-group"
                      icon={<UserOutlined />}
                      title="Questionaire"
                    >
                      <Menu.Item key="questionaire-result">
                        <Link to={`/questionaire/list`} activeclassname="active">
                          <ContainerOutlined />
                          <span>Questionaire List</span>
                        </Link>
                      </Menu.Item>
                      <Menu.Item key="questionaire">
                        <Link to={`/questionaire/create`} activeclassname="active">
                          <ContainerOutlined />
                          <span>Add Questionaire</span>
                        </Link>
                      </Menu.Item>
                    </SubMenu>
                    <Menu.Item key="email">
                      <Link to={`/email`} activeclassname="active">
                        <MailOutlined />
                        <span>Email</span>
                      </Link>
                    </Menu.Item>
                  </SubMenu>
                  <SubMenu key="bannerMenu" title="Marketing" icon={<NotificationOutlined />}>
                    <Menu.Item key="discountCodes">
                      <Link to={`/discount-code`} activeclassname="active">
                        <DollarOutlined />
                        <span>Discount Code</span>
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="accManager">
                    <Link to={`/account-manager`} activeclassname="active">
                      <ContainerOutlined />
                      <span>Account Manager</span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="referralAccount">
                    <Link to={`/referral-account`} activeclassname="active">
                      <ContainerOutlined />
                      <span>Referral Account</span>
                    </Link>
                  </Menu.Item>
                  </SubMenu>
                  <SubMenu key="finance" icon={<UserOutlined />} title="Finance">
                  <SubMenu key="remittance" icon={<MoneyCollectOutlined />} title="Remittance">
                    <Menu.Item key="manual-remit">
                      <Link to={`/remittance`} activeclassname="active">
                        <CarryOutOutlined />
                        <span>Manual</span>
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="payout-list">
                      <Link to={`/payout`} activeclassname="active">
                        <MoneyCollectOutlined />
                        <span>Payout</span>
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="check-remittance">
                      <Link to={`/check-remittance`} activeclassname="active">
                        <ContainerOutlined />
                        <span>Check</span>
                      </Link>
                    </Menu.Item>
                  </SubMenu>
                  <SubMenu key="weight-dispute-group" icon={<RiseOutlined />} title="Weight Dispute">
                    <Menu.Item key="weight-dispute">
                      <Link to={`/weight-dispute`} activeclassname="active">
                        <RiseOutlined />
                        <span>Weight Dispute</span>
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="weight-dispute-by-merchant">
                      <Link to={`/weight-dispute/merchant`} activeclassname="active">
                        <UserOutlined />
                        <span>By Merchant</span>
                      </Link>
                    </Menu.Item>
                  </SubMenu>
                  </SubMenu>
                  <SubMenu key="operation" icon={<UserOutlined />} title="Operation">
                  <SubMenu key="consign-notes" icon={<FilePdfOutlined />} title="Consign Notes">
                    <Menu.Item key="bulk-download-consign-notes">
                      <Link to={`/consign-notes/bulk-download`} activeclassname="active">
                        <FilePdfOutlined />
                        <span>Bulk Download</span>
                      </Link>
                    </Menu.Item>
                  </SubMenu>
                  <Menu.Item key="insurance">
                    <Link to={`/insurance`} activeclassname="active">
                      <ContainerOutlined />
                      <span>Insurance</span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="export">
                    <Link to={`/export`} activeclassname="active">
                      <ContainerOutlined />
                      <span>Export</span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="flyersRequest">
                    <Link to={`/flyers-request`} activeclassname="active">
                      <ContainerOutlined />
                      <span>Flyers Request</span>
                    </Link>
                  </Menu.Item>
                  </SubMenu>
                  <SubMenu key="feature-test" icon={<RiseOutlined />} title="Feature Test">
                    <SubMenu key="receiver" icon={<UserOutlined />} title="Receiver">
                      <Menu.Item key="receiver-order-stats">
                        <Link to={`/receiver/order-stats`} activeclassname="active">
                          <MailOutlined />
                          <span>Order Stats</span>
                        </Link>
                      </Menu.Item>
                    </SubMenu>
                  </SubMenu>
                  <Menu.Item key="5">
                    <Link to={`/`} activeclassname="active" forcerefresh={"true"} onClick={ () => this.props.LOGOUT()}>
                      <LogoutOutlined />
                      <span>Logout{isAuthenticated}</span>
                    </Link>
                  </Menu.Item>
                  {/** Remove player from Navbar later */}
                  {/* <Menu.Item key="4">
                    <Link to={`/players`} activeClassName="active">
                      <UserOutlined />
                      <span>Players</span>
                    </Link>
                  </Menu.Item> */}
                </Menu>
              </Sider>
              <Layout>
                <Header className="site-layout-sub-header-background" style={{ padding: 0 }} />
                {/* <Breadcrumb className="antd-breadcrumb">
                  <Breadcrumb.Item>Home</Breadcrumb.Item>
                </Breadcrumb> */}
                <Content style={{ margin: '24px 16px 0' }}>
                  <div style={{ padding: 24, minHeight: 360 }}>
                    {/* {this.props.children} */}
                    <Switch>
                      <Route exact path="/">
                        <Dashboard />
                      </Route>
                      <Route exact path="/user">
                        <User />
                      </Route>
                      <Route exact path="/discount-code">
                        <DiscountCode />
                      </Route>
                      <Route exact path="/banner/active">
                        <BannerActive />
                      </Route>
                      <Route exact path="/banner/inactive">
                        <BannerInactive />
                      </Route>
                      <Route exact path="/all-order">
                        <AllOrder />
                      </Route>
                      <Route exact path="/order/next-day-remittance-order">
                        <NextDayRemittanceOrder />
                      </Route>
                      <Route exact path="/order/cod-order">
                        <CodOrder />
                      </Route>
                      <Route exact path="/order/cancel-request">
                        <CancelRequest />
                      </Route>
                      <Route exact path="/order/flash-pod">
                        <FlashPOD />
                      </Route>
                      <Route exact path="/weight-dispute">
                        <WeightDispute />
                      </Route>
                      <Route exact path="/weight-dispute/merchant">
                        <WeightDisputeByMerchant />
                      </Route>
                      <Route exact path="/order/unrecognized-status">
                        <UnrecognizedStatusOrder />
                      </Route>
                      <Route exact path="/order/returned-order">
                        <ReturnedOrder />
                      </Route>
                      <Route exact path="/consign-notes/bulk-download">
                        <BulkDownloadConsignNote />
                      </Route>
                      <Route exact path="/analysis">
                        <Analysis />
                      </Route>
                      <Route exact path="/tableau-analysis">
                        <TableauAnalysis />
                      </Route>
                      <Route exact path="/courier-order-analysis">
                        <CourierAnalysis />
                      </Route>
                      <Route exact path="/notification-analysis">
                        <NotificationAnalysis />
                      </Route>
                      <Route exact path="/cod-analysis">
                        <CodAnalysis />
                      </Route>
                      <Route exact path="/transaction">
                        <Transaction />
                      </Route>
                      <Route exact path="/insurance">
                        <Insurance />
                      </Route>
                      <Route exact path="/package">
                        <Package />
                      </Route>
                      <Route exact path="/package/pricing">
                        <PackagePricing />
                      </Route>
                      <Route exact path="/topup-package/pricing">
                        <TopupPackagePricing />
                      </Route>
                      <Route exact path="/fee-package/pricing">
                        <FeePackagePricing />
                      </Route>
                      <Route exact path="/courier-package/pricing">
                        <CourierPackagePricing />
                      </Route>
                      <Route exact path="/user/:id">
                        <UserDetails />
                      </Route>
                      <Route exact path="/remittance">
                        <Remittance />
                      </Route>
                      <Route exact path="/payout">
                        <Payout />
                      </Route>
                      <Route exact path="/check-remittance">
                        <CheckRemittance />
                      </Route>
                      <Route exact path="/account-manager">
                        <AccountManager />
                      </Route>
                      <Route exact path="/referral-account">
                        <ReferralAccount />
                      </Route>
                      <Route exact path="/account-manager/:id">
                        <AccountManagerDetails />
                      </Route>
                      <Route exact path="/referral-account/:id">
                        <ReferreeAccountList />
                      </Route>
                      <Route exact path="/questionaire/create">
                        <AddQuestionaire />
                      </Route>
                      <Route exact path="/questionaire/list">
                        <Questionaire />
                      </Route>
                      <Route exact path="/questionaire/result/:id">
                        <QuestionaireDetails />
                      </Route>
                      <Route exact path="/email">
                        <Email />
                      </Route>
                      <Route exact path="/export">
                        <Export />
                      </Route>
                      <Route exact path="/flyers-request">
                        <FlyersRequest />
                      </Route>
                      <Route exact path="/system">
                        <Settings />
                      </Route>
                      <Route exact path="/receiver/order-stats">
                        <ReceiverOrderStats />
                      </Route>
                      <Route exact path="/sub-account">
                        <SubAccount />
                      </Route>
                      <Route exact path="/sub-account/:id">
                        <SubAccountDetails />
                      </Route>
                      <Route exact path="/payout/status/:payoutId">
                        <PayoutStatus />
                      </Route>
                      {/* <Route exact path="/campaign/create">
                        <CampaignCreatePage />
                      </Route>
                      <Route exact path="/campaign/:campaignid/edit" component={CampaignUpdatePage}>
                      </Route> */}
                    </Switch>
                  </div>
                </Content>
                <Footer style={{ textAlign: 'center' }}>ParcelDaily © 2021</Footer>
              </Layout>
            </Layout>
          </Router>
          :
          <Login />
        }
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
});


const mapDispatchToProps = dispatch => {
  return {
    LOGOUT: () => dispatch(LOGOUT()),

  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);