import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom";
import axios from "axios"
import { Card, Button, Row, Col, notification } from "antd"
import { CheckOutlined, LoadingOutlined, FilePdfFilled, CloseOutlined } from "@ant-design/icons";

const PayoutStatus = () => {
  const { payoutId } = useParams();
  const [loading, setLoading] = useState(true)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(null)

  const downloadReport = () => {
    axios.post(`/api/payout/${payoutId}/advice-report`, {}).then(response => {
      window.open(response.data.url)
    })
    .catch(async (err) => {
      notification.error({
        message: `Error when downloading Payout Report`,
        description: err.response?.data.message || err.message
      })
    })
  }

  useEffect(() => { 
    const checkStatus = async () => {
      if (payoutId === "loading") {
          return
      }
  
      try {
        const { data: { data: payout } } = await axios.get(`/api/payout/${payoutId}`);
  
        if (payout.successAt) {
            setLoading(false)
            setSuccess(true)
        } else if (payout.error) {
            setLoading(false)
            setError(payout.error)
        }
      } catch (error) {
          setLoading(false)
          notification.error({
              message: `Error when checking Payout`,
              description: error.response ? error.response.data.message : error.message
          })
      }
    }

    checkStatus() 
  }, [payoutId])

  return (
    <div>
      <div className="header">
        <Row>
          <Col span={12}>
            <h2 className="detail-header-text">Payout Status</h2>
          </Col>
        </Row>
      </div>

      <div style={{ fontSize: "15em", textAlign: 'center' }}>
        {loading && <LoadingOutlined />}
        {success && <CheckOutlined />}
        {error && <CloseOutlined />}
      </div>

      {error && <h2 className="text-center">{error}</h2>}

      {success && 
        <Row justify="center">
          <Card>
            <p style={{ textAlign: 'center' }}>
              <FilePdfFilled style={{ fontSize: "8em"}} />
            </p>
            <Button 
              size="large"
              onClick={downloadReport}
            >
              Payment Advice
            </Button>
          </Card>
        </Row>
      }
    </div>
  )
}

export default PayoutStatus