import React from 'react'
import { List } from "antd";

import AuditLogItem from './AuditLogItem';

const AuditLogList = ({ auditLogs }) => {
  return (
    <List
      dataSource={auditLogs}
      renderItem={auditLog => (
        <List.Item>
          <AuditLogItem auditLog={auditLog} />
        </List.Item>
      )}
    />
  )
}

export default AuditLogList