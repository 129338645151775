import React, { useState, useEffect } from "react";
import {
  Collapse,
  Spin,
  Tabs,
  Form,
  Input,
  Button,
  message,
  Tooltip,
  Space,
  Switch,
} from "antd";
import axios from "axios";
import moment from "moment";
import { useParams } from "react-router-dom";

import QuestionaireResponseList from "./QuestionaireResponseList";
import QuestionaireResult from "./QuestionaireResult";
import EditQuestionaire from "./EditQuestionaire";

import PageHeader from "../Layout/PageHeader";
import { useForm } from "antd/lib/form/Form";

const endOfThisMonth = moment().endOf("month");
const oneYearAgo = moment().subtract(12, "months").startOf("month");

const QuestionaireDetails = () => {
  const { id } = useParams();
  const [filters, setFilters] = useState({
    startDate: oneYearAgo,
    endDate: endOfThisMonth,
  });
  const [questionaire, setQuestionaire] = useState([]);
  const [loading, setLoading] = useState(true);
  const [forms, setForms] = useState([]);

  const [form] = useForm();

  useEffect(() => {
    const fetchQuestionaire = async () => {
      setLoading(true);
      try {
        const res = await axios.get(`/api/questionaire/result/${id}`);
        console.log(res.data.data.questions);

        const questions = res.data.data.questions;
        setQuestionaire(questions);
        // setForms(res.data.data.questions.map(() => Form.useForm())); // Initialize forms for each question
        setLoading(false);
      } catch (error) {
        message.error("Failed to fetch questionnaire data");
        setLoading(false);
      }
    };
    // fetchQuestionaire();
  }, [filters.startDate, filters.endDate, id]);

  const handleSave = async (values, index) => {
    try {
      const updatedQuestions = [...questionaire];
      updatedQuestions[index] = { ...updatedQuestions[index], ...values };
      setQuestionaire(updatedQuestions);

      await axios.put(`/api/questionaire/${id}`, {
        questions: updatedQuestions,
      });

      message.success("Question updated successfully");
    } catch (error) {
      message.error("Failed to save changes");
    }
  };

  return (
    <div>
      <PageHeader title="Questionnaire Details" childRoutes={[]} />
      <div className="table mt-4">
        <Tabs defaultActiveKey="1" animated={true}>
          <Tabs.TabPane tab="Questions" key="all-transactions">
            <EditQuestionaire />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Response List" key="response-list">
            <QuestionaireResponseList id={id} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Graph" key="graph">
            <QuestionaireResult />
          </Tabs.TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default QuestionaireDetails;
