import React, { useState, useEffect, useCallback } from "react";
import {
  Button,
  Col,
  Card,
  Modal,
  message,
  Row,
  Breadcrumb,
  Space,
  Typography,
  Collapse,
} from "antd";
import axios from "axios";
import moment from "moment";
import { DownloadOutlined, CaretRightOutlined } from "@ant-design/icons";

import "react-vis/dist/style.css";
import ExportFilters from "./ExportFilters";
const today = moment();
const threeMonthsAgo = moment().subtract(3, "months");

const Export = () => {
  const [isDownloading, setIsDownloading] = useState(false);
  const [filters, setFilters] = useState({});

  const downloadSenderAddress = async () => {
    setIsDownloading(true);
    let total;
    const dateParams = {
      startDate: filters.startDate?.startOf("month")?.toISOString(),
      endDate: filters.endDate?.endOf("month")?.toISOString(),
    };
    await axios
      .get("/api/addresses", {
        params: { ...dateParams },
      })
      .then((res) => {
        total = res.data.pagination.totalSize;
      });
    const messageContent = (
      <p>
        Are you sure you want to download {total} Sender Address Info data ?
        <br />
      </p>
    );

    Modal.confirm({
      content: messageContent,
      title: `Download Sender Address List ? `,
      async onOk() {
        await axios
          .get("/api/address/csv", {
            params: { ...dateParams },
          })
          .then((res) => {
            message.info(`Please check your email after 5-20 minute`);
          })
          .catch((error) => {
            message.error(error.message);
          });
      },
    });
    setIsDownloading(false);
  };
  const downloadReceiverAddress = async () => {
    setIsDownloading(true);
    let total;
    const dateParams = {
      startDate: filters.startDate?.startOf("month")?.toISOString(),
      endDate: filters.endDate?.endOf("month")?.toISOString(),
    };
    await axios
      .get("/api/client-addresses", {
        params: { ...dateParams },
      })
      .then((res) => {
        total = res.data.pagination.totalSize;
      });
    const messageContent = (
      <p>
        Are you sure you want to download {total} Receiver Address data ?<br />
      </p>
    );

    Modal.confirm({
      content: messageContent,
      title: `Download Receiver Address List ? `,
      async onOk() {
        await axios
          .get("/api/clientAddress/csv", {
            params: { ...dateParams },
          })
          .then((res) => {
            message.info(`Please check your email after 5-20 minute`);
          })
          .catch((error) => {
            message.error(error.message);
          });
      },
    });
    setIsDownloading(false);
  };

  const downloadRiderInfo = async () => {
    setIsDownloading(true);
    let total;
    const dateParams = {
      startDate: filters.startDate?.startOf("month")?.toISOString(),
      endDate: filters.endDate?.endOf("month")?.toISOString(),
    };
    await axios
      .get("/api/riderInfo/count", {
        params: { ...dateParams },
      })
      .then((count) => {
        total = count.data.count;
        console.log("HERE", total);
      });
    const messageContent = (
      <p>
        Are you sure you want to download {total} Rider Info data ?<br />
      </p>
    );

    Modal.confirm({
      content: messageContent,
      title: `Download Rider Info List ? `,
      async onOk() {
        await axios
          .get("/api/riderInfo/csv", {
            params: { ...dateParams },
          })
          .then((res) => {
            message.info(`Please check your email after 5-20 minute`);
          })
          .catch((error) => {
            message.error(error.message);
          });
      },
    });
    setIsDownloading(false);
  };

  const downloadTopupReport = async () => {
    setIsDownloading(true);
    let total;
    const dateParams = {
      startBeforeDate: filters.startBeforeDate?.startOf("month")?.toISOString(),
      endBeforeDate: filters.endBeforeDate?.endOf("month")?.toISOString(),
      startAfterDate: filters.startAfterDate?.startOf("month")?.toISOString(),
      endAfterDate: filters.endAfterDate?.endOf("month")?.toISOString(),
    };

    const messageContent = (
      <p>
        Are you sure you want to download Topup Report data ?<br />
      </p>
    );

    Modal.confirm({
      content: messageContent,
      title: `Download Topup Report ? `,
      async onOk() {
        await axios
          .get("/api/export-merhant-topup", {
            params: { ...dateParams },
          })
          .then((res) => {
            message.info(`Please check your email after 5-20 minute`);
          })
          .catch((error) => {
            message.error(error.message);
          });
      },
    });
    setIsDownloading(false);
  };

  const isTopupReportFiltersValid = () =>
    filters.startBeforeDate &&
    filters.endBeforeDate &&
    filters.startAfterDate &&
    filters.endAfterDate;

  return (
    <Space direction="vertical" style={{ display: "flex" }}>
      <div className="header">
        <Row>
          <Col span={12}>
            <h2 className="detail-header-text">Export</h2>
          </Col>
          <Col span={12}>
            <Breadcrumb style={{ margin: "10px 0", textAlign: "right" }} />
          </Col>
        </Row>
      </div>

      <Collapse
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
      >
        <Collapse.Panel header="Sender Address" key="senderFilters">
          <ExportFilters
            filters={filters}
            onFiltersChange={(filters) => setFilters(filters)}
          />
          <Row justify="end" style={{ marginTop: 16 }}>
            <Col>
              <Button type="primary" onClick={downloadSenderAddress}>
                Download Sender Address CSV <DownloadOutlined />
              </Button>
            </Col>
          </Row>
        </Collapse.Panel>
      </Collapse>
      <Collapse
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
      >
        <Collapse.Panel header="Receiver Address" key="senderFilters">
          <ExportFilters
            filters={filters}
            onFiltersChange={(filters) => setFilters(filters)}
          />
          <Row justify="end" style={{ marginTop: 16 }}>
            <Col>
              <Button type="primary" onClick={downloadReceiverAddress}>
                Download Receiver Address CSV <DownloadOutlined />
              </Button>
            </Col>
          </Row>
        </Collapse.Panel>
      </Collapse>

      <Collapse
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
      >
        <Collapse.Panel header="Rider Info" key="senderFilters">
          <ExportFilters
            filters={filters}
            onFiltersChange={(filters) => setFilters(filters)}
          />
          <Row justify="end" style={{ marginTop: 16 }}>
            <Col>
              <Button type="primary" onClick={downloadRiderInfo}>
                Download Rider Info CSV <DownloadOutlined />
              </Button>
            </Col>
          </Row>
        </Collapse.Panel>
      </Collapse>

      <Collapse
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
      >
        <Collapse.Panel header="Topup Report" key="senderFilters">
          <ExportFilters
            filters={filters}
            onFiltersChange={(filters) => setFilters(filters)}
            type="topup"
          />
          <Row justify="end" style={{ marginTop: 16 }}>
            <Col>
              <Button
                type="primary"
                onClick={downloadTopupReport}
                disabled={!isTopupReportFiltersValid()}
              >
                Download Topup Report CSV <DownloadOutlined />
              </Button>
            </Col>
          </Row>
        </Collapse.Panel>
      </Collapse>
    </Space>
  );
};

export default Export;
