import React from "react";
import { connect } from "react-redux";
import "./Banner.css";
import "react-vis/dist/style.css";
import {
  SearchOutlined,
} from "@ant-design/icons";
import moment from "moment";
import {
  Button,
  Breadcrumb,
  Row,
  Col,
  Modal,
  Input,
  InputNumber,
  DatePicker,
  Upload,
  Space,
  message,
  Table,
  Popconfirm
} from "antd";
import axios from "axios";

function getBase64(file){
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

function gcd (a,b){
  if (b == 0)
    return a
  return gcd (b, a % b)
}

function getImg(url){
  return new Promise((resolve, reject) => {
    const _loadedImageUrl = url;
    const image = document.createElement('img');
    image.src = _loadedImageUrl;
    image.onload = () => resolve(image);
    image.onerror = error => reject(error);
  });
}

class Inactive extends React.Component {
  state = {
    data: [],
    userData:[],
    pagination: {
      current: 1,
      pageSize: 10,
    },
    visible: false,
    confirmLoading: false,
    loading: false,
    total: 0,
    fileList: [],
    previewImage: '',
    bannerList: [],
    allowBulkDelete: false,
    totalBannerActive: 0,
    previewVisible: false,
    previewTitle: '',
    bannerName: '',
    validAspectRatio: true,
    sequence: 1,
    dateRange: [],
    editedId: '',
    selectedRowKeys: [],
    momentDateRange: [],
    imgUrl: '',
    userId: {},
    header: {
      Authorization: "Bearer " + this.props.token,
    },
    actionUrl: axios.defaults.baseURL + 'api/banners/upload'
  };

  componentDidMount = () => {
      this.getInactiveList();
      this.getActiveCount();   
  };

  getInactiveList = () => {
    this.setState({ loading: true});
    axios
    .get(`/api/banners/inactive`, {
      headers: {
        Authorization: "Bearer " + this.props.token, //the token is a variable which holds the token
      },
    }).then((res)=>{
      const bannerList = res.data.success;
      bannerList.length == 0 && this.setState({allowBulkDelete: false})
      bannerList.forEach(element => {
        element.startD = moment(element.startDate.iso).format("MMMM Do YYYY"); 
        element.endD = moment(element.endDate.iso).format("MMMM Do YYYY"); 
      });
      this.setState({
         bannerList : res.data.success,
         loading: false
       })
    }).catch(()=>{
       message.error("Error in retrieving banner list")
    })
  };

  onSelectChange = (selectedRowKeys) => {
    this.setState({ allowBulkDelete: selectedRowKeys.length > 0 ? true : false})
    this.setState({ selectedRowKeys });
  };

  getActiveCount = () => {
    axios
    .get(`/api/banners/count/active`, {
      headers: {
        Authorization: "Bearer " + this.props.token, //the token is a variable which holds the token
      }
      }).then((res)=>{
        this.setState({totalBannerActive : res.data.success})
        this.setState({sequence : res.data.success + 1})
      }).catch((err)=>{
        console.log(err)
      })
  };

  handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
    });
  };

  showModal = () =>{
    this.setState({visible: true});
  };

  handleCancel = () =>{
    this.setState({visible: false});
    this.clearModal();
  };

  bannerName = (e) =>{
    this.setState({bannerName: e.target.value});
  };

  sequence = (e) =>{
    if (e)
      this.setState({sequence: e});
  };

  dateRange = (e) =>{
    this.setState({momentDateRange: e})
    if (e?.length > 1){
       this.state.dateRange[0] = moment(e[0]).format();
       this.state.dateRange[1] = moment(e[1]).format();
    }else{
      this.state.dateRange.length = 0;
    }
  };

  handlePreviewCancel = () =>{
    this.setState({previewVisible: false});
  };

  handleBeforeUpload = async file => {
    const allowedType = "image/png,image/jpg,image/jpeg"
    var isAllowed = allowedType.includes(file.type);
      if (!isAllowed) {
        message.error('File not supported');
        return Upload.LIST_IGNORE;
      }else{
         const result = await getBase64(file)
         const image = await getImg(result)
         const { width, height } = image;
         const expectedLow = (0.202 * width)-1;
         const expectedHigh = (0.202 * width)+1;

         if (height < expectedLow || height > expectedHigh){
            if (height < expectedLow || height > expectedHigh){
              const gcdResult = gcd(width, height);
              const heightRatio = height / gcdResult;
              const widthRatio = width / gcdResult;
              message.error(`Uploaded file is in ${widthRatio}:${heightRatio} aspect ratio. Banner must be in 510:103`);
              return Upload.LIST_IGNORE;
            }   
            return Upload.LIST_IGNORE;
         }          
      }
  };

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleChange = ({ fileList }) => {
    this.setState({ fileList });
    if (fileList[0]?.status === "done"){
       this.setState({ imgUrl: fileList[0]?.response.success });
    }else{
       this.setState({ imgUrl: '' });
    }  
  }

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  clearModal = () =>{
    this.setState({
      imgUrl: "",
      editedId: "",
      bannerName: "",
      fileList: [],
      dateRange: [],
      momentDateRange: [],
      sequence: this.state.totalBannerActive + 1 
    })
  }

  edit = (id)=>{
     const found = this.state.bannerList.find(element => element.objectId === id)
     this.setState({
      editedId: id,
      imgUrl: found.imgUrl,
      bannerName: found.name,
      startDate: found.startDate,
      endDate: found.endDate,
      dateRange: [found.startDate.iso,found.endDate.iso],
      momentDateRange: [moment(found.startDate.iso),
                        moment(found.endDate.iso)
                       ],
      sequence: found.sequence, 
    })
    this.state.fileList.push({url: found.imgUrl})
    this.showModal();   
  }

  delete = (id)=>{
    axios.put(`/api/banners/delete/${id}`,{
    },{
    headers: {
      Authorization: "Bearer " + this.props.token, //the token is a variable which holds the token
    },        
    }).then((res)=>{
      this.getInactiveList();
      this.getActiveCount();
      message.success(`Banner ${res.data.success.name} is deleted successfully`)
    }).catch((err)=>{
       console.log(err)
       message.error("Failed to delete due to error")
    })
  }  

  deleteMany = () =>{
    axios.put(`/api/banners/deleteMany`,{
      idArr: this.state.selectedRowKeys
    },{
    headers: {
      Authorization: "Bearer " + this.props.token, //the token is a variable which holds the token
    },        
    }).then((res)=>{
      this.getInactiveList();
      this.getActiveCount();
      message.success(`Selected banners deleted successfully`)
    }).catch((err)=>{
       console.log(err)
       message.error("Failed to delete due to error")
    })
  }

  activate = (id,currentEndDate)=>{
    const past = moment(currentEndDate.iso).isBefore(moment(),"day");      
    
    axios.put(`/api/banners/edit/${id}`,{
      startDate: moment().format(),
      endDate: past ? moment().add(1,'days') : moment(currentEndDate.iso).format()      
    },{
    headers: {
      Authorization: "Bearer " + this.props.token, //the token is a variable which holds the token
    },        
    }).then((res)=>{
      this.getInactiveList();
      this.getActiveCount();
      message.success(`Banner ${res.data.success.name} is activated successfully`)
    }).catch((err)=>{
       console.log(err)
       message.error("Failed to activate due to error")
    })
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              this.setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) => (this.state.searchedColumn === dataIndex ? text : text),
  });

  handleOk = async () => {
    if (this.state.fileList.length > 0 && this.state.imgUrl && this.state.bannerName && this.state.dateRange.length > 1){
      this.setState({confirmLoading: true});
      const requestBody = {
          imgUrl: this.state.imgUrl,
          startDate: this.state.dateRange[0],
          endDate: this.state.dateRange[1],
          sequence: this.state.sequence 
      }
      if (this.state.editedId){
        await axios.put(`/api/banners/edit/${this.state.editedId}`,{
          ...requestBody,
          name: this.state.bannerName
        },{
        headers: {
          Authorization: "Bearer " + this.props.token, //the token is a variable which holds the token
        },        
        }).then((res)=>{
          this.getInactiveList();
          this.getActiveCount();
          this.clearModal();
          this.handleCancel();
          this.setState({confirmLoading: false});

          message.success(`Banner ${res.data.success.name} is edited successfully`)    
        }).catch((err)=>{
           console.log(err)
           this.setState({confirmLoading: false});
           message.error("Failed to edit due to error")
        })

      }else{
        await axios.post('/api/banners/create',{
          ...requestBody,
          bannerName: this.state.bannerName
         },{
         headers: {
           Authorization: "Bearer " + this.props.token, //the token is a variable which holds the token
         },        
       })
       .then((res) => {
         const name = res.data.success.name;
         this.getInactiveList();
         this.getActiveCount();
         this.clearModal();
         this.handleCancel();
         message.success(`Banner ${name} created successfully`);
       }).catch((err) => {
         console.log(err)
         message.error("Error has been encountered")
       }).finally(()=>{
         this.setState({confirmLoading: false});
       });
    }     
    }else{
      message.error(`Please complete the fields before proceed`);
    }
  };

  render = () =>{
    const { RangePicker } = DatePicker;
    const {loading, bannerList, momentDateRange, sequence, selectedRowKeys, totalBannerActive, previewVisible, previewImage, fileList, previewTitle, actionUrl, header } = this.state;
    const uploadDescription = (
       <div>
          <b>Drag or click to upload</b><br/> 
          <p>*Only jpg, jpeg and png are allowed*</p>
          <p>*Acceptable aspect ratio is 510:103*</p>       
       </div>
    );
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };
    const routes = [
      {
        path: "",
        breadcrumbName: "App",
      },
      {
        path: "/banner",
        breadcrumbName: "Banner",
      },
      {
        path: "/inactive",
        breadcrumbName: "Inactive",
      }
    ];
    const columns = [
      {
        title: "Banner Name",
        dataIndex: "name",
        fixed: "left",
        ...this.getColumnSearchProps("name"),
      },
      {
        title: "Start Date",
        dataIndex: "startD",
        ...this.getColumnSearchProps("startD"),
      },
      {
        title: "End Date",
        dataIndex: "endD",
        ...this.getColumnSearchProps("endD"),
      },
      { 
        title: "Sequence",
        dataIndex: "sequence",
        ...this.getColumnSearchProps("sequence")
      },
      {
        title: "Actions",
        key: "action",
        render: (_text,record) =>(
          <Space>
             <Button type="primary" onClick={() => this.edit(record.objectId)}>Edit</Button>
             <Button type="primary" onClick={(() => window.open(record.imgUrl))}>View</Button>
              <Popconfirm
                title="Are you sure to delete this task?"
                onConfirm={(() =>this.delete(record.objectId))}
                okText="Yes"
                cancelText="No"
              >
                <Button type="primary" danger>Delete</Button>
              </Popconfirm>
             <Button type="primary" onClick={(() =>this.activate(record.objectId,record.endDate))}>Activate</Button>
          </Space>
        )
      }
    ];
    return (
      <>
        <div className="header">
        <Row>
          <Col span={12}>
            <h2 className="detail-header-text">Inactive Banners</h2>
          </Col>
          <Col span={12}>
            <Breadcrumb
              style={{ margin: "10px 0", textAlign: "right" }}
              routes={routes}
            />
          </Col>
        </Row>
      </div>
      <div className="table">
        <Row>
            <Col offset={18} span={6}>
              <Button type="primary" onClick={this.showModal}>Create new banner</Button>
            </Col>
        </Row>
        <Row>
            <Popconfirm
              title="Are you sure to delete this task?"
              onConfirm={(() =>this.deleteMany())}
              okText="Yes"
              cancelText="No"
            >
              { this.state.allowBulkDelete ? <Button type="primary" danger>Delete All</Button> : null }
            </Popconfirm>
        </Row>
        <Table
            rowSelection={rowSelection}
            pagination={{ alignment: "right" }}
            columns={columns.filter((col) => col.dataIndex !== "brn")}
            dataSource={bannerList}
            onChange={this.onChange}
            scroll={{ x: true }}
            loading={loading}
            rowKey="objectId"
        />
        <Modal
          title={this.state.editedId ? "Edit banner" : "Create new banner"}
          visible={this.state.visible}
          onOk={this.handleOk}
          confirmLoading={this.state.confirmLoading}
          onCancel={this.handleCancel}
          width={520}
        > 
          <Space direction="vertical">
            <Row>
              <Col span={11}>
                <Input placeholder="Banner Name" value={this.state.bannerName} onChange={this.bannerName}></Input>            
              </Col>
              <Col span={11} offset={2}>
                <RangePicker 
                      format="DD-MM-YYYY"
                      disabledDate={(current) => {                 
                        return (
                          moment().add(-1, "day") >= current
                        );
                      }}
                      value={momentDateRange} onChange={this.dateRange}/>           
              </Col>
            </Row>
            <Row>
              <Col>
                Sequence: <InputNumber min={1} max={totalBannerActive+1} value={sequence} onChange={this.sequence} />
              </Col>
            </Row>
            <Row>
                <Col>
                    <Upload
                      name="file"
                      action={actionUrl}
                      listType="picture-card"
                      fileList={fileList}
                      onPreview={this.handlePreview}
                      onChange={this.handleChange}
                      headers={header}
                      beforeUpload={this.handleBeforeUpload}
                    >
                       {fileList.length > 0 ? null : uploadDescription }                     
                    </Upload>
                </Col>
            </Row> 
          </Space>                      
        </Modal>
        <Modal
          visible={previewVisible}
          title={previewTitle}
          footer={null}
          onCancel={this.handlePreviewCancel}
        >
          <img alt="no image" style={{ width: '100%' }} src={previewImage} />
        </Modal>
      </div>
     </>
    );
  }
}

const mapStateToProps = (state) => ({
  token: state.auth.token,
});

export default connect(mapStateToProps)(Inactive);
