import React, { useState } from "react";
import { Col, Row, Breadcrumb, Space, Collapse, Table, Alert, Typography, Button } from "antd";
import { CaretRightOutlined, CheckOutlined, MinusOutlined, DownloadOutlined } from "@ant-design/icons";

import "react-vis/dist/style.css";
import DragUpload from "../Form/DragUpload";

const columns = [
  {
    title: "Tracking Number",
    dataIndex: "consign_no",
  },
  {
    title: "Received Amount",
    align: 'center',
    dataIndex: "receivedAmount",
    render: amount => amount ? amount.toFixed(2) : <MinusOutlined style={{ color: 'red' }} />
  },
  {
    title: "Remitted",
    align: 'center',
    dataIndex: "isRemitted",
    render: isRemitted => isRemitted
      ? <CheckOutlined style={{ color: 'green' }} />
      : <MinusOutlined style={{ color: 'red' }} />
  },
  {
    title: "Remitted Amount",
    align: 'center',
    dataIndex: "remittedAmount",
    render: (amount, remittance) => amount 
      ? `${amount.toFixed(2)}` + (remittance.codCurrency ? ` (${remittance.codCurrency?.currency})` : ``) 
      : <MinusOutlined style={{ color: 'red' }} />
  },
  {
    title: "Amount Difference",
    align: 'center',
    dataIndex: "amountDifference",
    render: (diff, remittance) => {
      if (remittance.codCurrency) {
        return "N/A"
      }

      if (!diff) {
        return <MinusOutlined style={{ color: diff === 0 ? 'green' : 'red' }} />
      }

      return diff > 0
        ? <Typography.Text type="success">+{diff.toFixed(2)}</Typography.Text>
        : <Typography.Text type="danger">{diff.toFixed(2)}</Typography.Text>
    }
  },
  {
    title: "Via Payout",
    align: 'center',
    dataIndex: "isFromPayout",
    render: isFromPayout => isFromPayout
      ? <CheckOutlined style={{ color: 'green' }} />
      : <MinusOutlined style={{ color: 'red' }} />
  },
];

const exportCsv = remittances => {
  const headers = [
    "Tracking Number", 
    "Received Amount", 
    "Remitted", 
    "Remitted Amount", 
    "Remit Currency",
    "Amount Difference", 
    "Via Payout"
  ]
  const formattedRemittances = remittances.map(remittance => ([
    remittance.consign_no,
    remittance.receivedAmount,
    remittance.isRemitted ? "Y" : "N",
    remittance.remittedAmount || "",
    remittance.remittedAmount ? (remittance.codCurrency?.currency || "MYR") : "",
    remittance.codCurrency 
      ? "NA"
      : remittance.amountDifference,
    remittance.isFromPayout ? "Y": "N",
  ]))

  const csvContent = "data:text/csv;charset=utf-8," 
    + `${headers.join(",")}\n`
    + formattedRemittances.map(row => row.join(",")).join("\n");

  const encodedUri = encodeURI(csvContent);
  const link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", `Check-Remittance_${(new Date()).toISOString()}.csv`);
  document.body.appendChild(link);
  
  link.click();
}

const CheckRemittance = () => {
  const [remittances, setRemittances] = useState([])
  const [meta, setMeta] = useState({})

  return (
    <Space direction="vertical" style={{ display: 'flex' }}>
      <div className="header">
        <Row>
          <Col span={12}>
            <h2 className="detail-header-text">Check Remittance</h2>
          </Col>
          <Col span={12}>
            <Breadcrumb
            style={{ margin: "10px 0", textAlign: "right" }}
          />
          </Col>
        </Row>
      </div>

      <Collapse expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />} activeKey={"upload"}>
        <Collapse.Panel header="Upload Remittances for Checking" key="upload">
          <DragUpload 
            additionalDesc={`Excel file with "Tracking Number" and "Received Amount" columns.`}
            action={`${process.env.REACT_APP_SERVER_URL}/api/remittances/bulk-check`} 
            accept=".xls, .xlsx"
            method="post"
            onUploadSuccess={res => { setRemittances(res.data.data); setMeta(res.data.meta)}}
          />
        </Collapse.Panel>
      </Collapse>

      { meta.notFounds && (meta.notFounds.length > 0) && 
        <Alert
          message={`${meta.notFounds.length} Tracking Number not found`}
          description={`${meta.notFounds.join(", ")}`}
          type="warning"
        />
      }

      { remittances.length > 0 && 
        <Row className="mt-2 mb-2" justify="space-between">
          <Space>
            <span color="blue">
              <Button onClick={() => exportCsv(remittances)}>
                Export Result<DownloadOutlined></DownloadOutlined>
              </Button>
            </span>

            <span></span>
          </Space>
        </Row>
      }

      <div className="table">
        <Table
          pagination={{ pageSize: 100}}
          columns={columns}
          dataSource={remittances}
        />
      </div>

    </Space>
  )
}

export default CheckRemittance;
