import React, { useState, useEffect, useCallback } from 'react'
import { Pagination, Row, Col, Collapse } from 'antd'
import moment from 'moment'
import { CaretRightOutlined } from '@ant-design/icons'
import axios from 'axios'

import OrdersList from '../Order/OrdersList'
import OrderButtons from '../Order/OrderButtons'
import OrderFilters from '../Order/OrderFilters'

const today = moment()
const threeMonthsAgo = moment().subtract(3, 'months')

const UserOrder = ({ merchantId,userId }) => {
  const [orders, setOrders] = useState([])
  const [filters, setFilters] = useState({ startDate: threeMonthsAgo, endDate: today })
  const [pagination, setPagination] = useState({ currentPage: 1, limit: 10 })
  const [isLoading, setIsLoading] = useState(false)
  const [selectedRows, setSelectedRows] = useState([])

  const fetchOrders = useCallback(async () => {
    setIsLoading(true)

    const params = {
      userId,
      merchantId,
      ...(filters.startDate !== null) && { startDate: filters.startDate?.startOf('day')?.toISOString() },
      ...(filters.endDate !== null) && { endDate: filters.endDate?.endOf('day')?.toISOString() },
      ...(filters.channel) && { channel: filters.channel },
      ...(filters.search && { searchField: filters.searchField, search: filters.search }),
      ...(filters.serviceProvider && { serviceProvider: filters.serviceProvider }),
      ...(filters.internalStatus && { internalStatus: filters.internalStatus }),
      limit: pagination.limit,
      page: pagination.currentPage
    }

    const res = await axios.get("/api/orders", { params })

    setOrders(res.data.orders)
    setPagination(res.data.pagination)
    setIsLoading(false)
  }, [pagination.currentPage, pagination.limit, merchantId, filters])

  useEffect(() => {
    fetchOrders()
  }, [pagination.currentPage, pagination.limit, fetchOrders])

  return (
    <div>
      <Collapse expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}>
        <Collapse.Panel header="Filters" key="filters">
          <OrderFilters
            filters={filters} 
            onFiltersChange={filters => setFilters(filters)} 
          />
        </Collapse.Panel>
      </Collapse>

      <Row className="mt-2 mb-2" justify="space-between">
        <OrderButtons 
          selectedRowKeys={selectedRows}
          orders={orders}
          filters={{
            merchantId,
            ...(filters.startDate !== null) && { startDate: filters.startDate?.startOf('day')?.toISOString() },
            ...(filters.endDate !== null) && { endDate: filters.endDate?.endOf('day')?.toISOString() },
            ...(filters.channel) && { channel: filters.channel },
            ...(filters.search && { searchField: filters.searchField, search: filters.search }),
            ...(filters.serviceProvider && { serviceProvider: filters.serviceProvider }),
            ...(filters.internalStatus && { internalStatus: filters.internalStatus }),
            pagination:pagination,

          }}
          onRefunded={() => fetchOrders()}
        />

        <Col>
          <Pagination 
            total={pagination?.totalSize} 
            pageSize={pagination?.limit}
            onChange={((currentPage, limit) => { setPagination({ ...pagination, limit, currentPage }) })}
            current={pagination.currentPage} 
          />
        </Col>
      </Row>

      <OrdersList 
        orders={orders} 
        onSelectRows={selectedRowKeys => setSelectedRows(selectedRowKeys)}
        selectedRows={selectedRows}
        loading={isLoading}
      />

      <Row justify="end">
        <Col>
          <Pagination 
            total={pagination?.totalSize} 
            pageSize={pagination?.limit}
            onChange={((currentPage, limit) => { setPagination({ ...pagination, limit, currentPage }) })}
            current={pagination.currentPage} 
          />
        </Col>
      </Row>
    </div>
  )
}

export default UserOrder