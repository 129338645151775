import React, { useState, useEffect } from "react"
import { Table, Select, Input, Button, Tooltip, Space, Modal, message } from "antd"
import { SaveOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { formatDateTime } from "../../helpers/date";

const FlyersRequestList = props => {
  const [flyerRequests, setFlyerRequests] = useState(props.flyerRequests)

  useEffect(() => { setFlyerRequests(props.flyerRequests) }, [props.flyerRequests])

  const updateHandler = id => {
    const updated = flyerRequests.find(flyerRequest => flyerRequest.objectId === id)

    if (updated.status === 'POSTED') {
      if (!updated.trackingNo) {
        message.error("Tracking No is required when Flyers Request has been posted.")
        return
      }
    }

    props.onUpdate(id, updated)
  }

  const statusSelectedHandler = (selectedStatus, id) => {
    const updatedFlyerRequests = flyerRequests.map(flyerRequest => {
      if (flyerRequest.objectId === id) {
        return { 
          ...flyerRequest, 
          status: selectedStatus,
          fulfilledAt: (selectedStatus === 'POSTED') ? {iso: new Date()} : null,
          trackingNo: (selectedStatus === 'POSTED') ? flyerRequest.trackingNo : null,
        }
      }

      return flyerRequest
    })

    setFlyerRequests(updatedFlyerRequests)
  }

  const archiveHandler = id => {
    Modal.confirm({
      icon: <ExclamationCircleOutlined />,
      content: `Are you sure to delete this Flyer Request (${id})?`,
      onOk() {
        props.onArchive(id)
        
        setFlyerRequests(flyerRequests.filter(flyerRequest => flyerRequest.objectId !== id))
      },
    });
  }

  const columns = [
    { title: "ID", dataIndex: "objectId" },
    { title: "Merchant Name", dataIndex: ["merchant", "name"] },
    { title: "Merchant Email", dataIndex: ["merchant", "user", "email"] },
    { title: "Quantity", dataIndex: "amount" },
    { 
      title: "Sizes", 
      dataIndex: "sizes",
      render: text => text.map(size => `${size.size} (${size.quantity})`).join(', ') 
    },
    { 
      title: "Address Name", 
      dataIndex: ["address", "fullName"],
    },
    { 
      title: "Address Phone", 
      dataIndex: ["address", "phone"],
      render: (text, record) => `${record.address.countryCode}${text}`
    },
    { 
      title: "Address Line 1", 
      dataIndex: ["address", "line1"],
    },
    { 
      title: "Address Line 2", 
      dataIndex: ["address", "line2"],
    },
    { 
      title: "City", 
      dataIndex: ["address", "city"],
    },
    { 
      title: "State", 
      dataIndex: ["address", "state"],
    },
    { 
      title: "Postcode", 
      dataIndex: ["address", "postcode"],
    },
    { 
      title: "Requested At", 
      dataIndex: "createdAt", 
      sorter: true,
      render: text => new Date(text).toLocaleString() 
    },
    { 
      title: "Status", 
      dataIndex: "status", 
      render: (text, record) => (
        <Select 
          defaultValue={text}
          onChange={selectedStatus => statusSelectedHandler(selectedStatus, record.objectId)}
        >
          <Select.Option value="REQUESTED">REQUESTED</Select.Option>
          <Select.Option value="POSTED">POSTED</Select.Option>
        </Select>
      )
    },
    { 
      title: "Tracking ID", 
      dataIndex: "trackingNo", 
      ellipsis: true,
      render: (text, record) => (
        <Input 
          value={text} 
          onChange={e => {setFlyerRequests(
            flyerRequests.map(flyerRequest => flyerRequest.objectId === record.objectId 
              ? { ...flyerRequest, trackingNo: e.target.value } 
              : flyerRequest
            )
          )}} 
        />
      )
    },
    { 
      title: "Fulfilled At", 
      dataIndex: "fulfilledAt", 
      sorter: true,
      render: text => text ? formatDateTime(text).toLocaleString() : '-'
    },
    { 
      title: "Action", 
      dataIndex: "action", 
      render: (_, record) => (
        <Space>
          <Tooltip title="Save">
            <Button 
              type="primary" 
              icon={<SaveOutlined />} 
              onClick={() => updateHandler(record.objectId)} 
            />
          </Tooltip>
          <Tooltip title="Delete">
            <Button 
              disabled={record.status !== 'REQUESTED'}
              type="danger" 
              icon={<DeleteOutlined />} 
              onClick={() => archiveHandler(record.objectId)} 
            />
          </Tooltip>
        </Space>
      )
    },
  ];

  return (
    <div className="table">
      <Table
        rowKey="objectId"
        className="table-section"
        columns={columns}
        dataSource={flyerRequests}
        pagination={false}
        scroll={{ x: true }}
        onChange={props.onChange}
        loading={props.loading}
      />
    </div>
  )
}

export default FlyersRequestList