import React from "react"
import { Table } from "antd"

import { formatDateTime } from "../../helpers/date";

const EmailList = props => {
  const columns = [
    { title: "Type", dataIndex: "type" },
    { title: "Sender", dataIndex: ["sentFrom"] },
    { title: "Recipient", dataIndex: ["sentTo"] },
    { title: "Subject", dataIndex: "subject" },
    { 
        title: "Event At (YYYY-MM-DD)", 
        dataIndex: "_created_at",
        render: (text) => (text ? formatDateTime(text) : "-"),
      },
  ];

  return (
    <div className="table">
      <Table
        rowKey="objectId"
        className="table-section"
        columns={columns}
        dataSource={props.emails}
        pagination={false}
        scroll={{ x: true }}
      />
    </div>
  )
}

export default EmailList