import React from "react";

import AuditLogItem from "../User/AuditLogItem";

const SettingsAuditLogs = ({ auditLogs }) => {
  const items = auditLogs ? (
    auditLogs
      .map(auditLog => {
        auditLog.changes.old.conversion = JSON.stringify(
          auditLog.changes.old.conversion
        );
        auditLog.changes.attributes.conversion = JSON.stringify(
          auditLog.changes.attributes.conversion
        );

        return auditLog;
      })
      .map(auditLog => (
        <AuditLogItem key={auditLog.objectId} auditLog={auditLog} />
      ))
  ) : (
    <p>No log</p>
  );

  return <div>{items}</div>;
};

export default SettingsAuditLogs;
