import React, { useState } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { Table } from "antd";
import UserBadgeTag from "../shared/UserBadgeTag";
import TopupPackageTag from "../shared/TopupPackageTag";

import { formatDateTime } from "../../helpers/date";

const SubAccountFilter = ({
  masterAccount,
  selectedRowKeys,
  onSelectRows,
  name,
  loading
}) => {
  const table = [
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
      sorter: {
        compare: (a, b) => a.username.length - b.username.length,
        multiple: 1,
      },
      sortDirections: ["descend", "ascend"],
      render: (text, row) => (
        <Link to={`/sub-account/${row._id}`}>{text}</Link>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Last Active Date",
      dataIndex: "lastDateActive",
      key: "lastDateActive",
      render: (text) => (text === "-" ? "-" : moment(text).fromNow()),
    },
    {
      title: "Registered Date (YYYY-MM-DD)",
      dataIndex: "_created_at",
      key: "_created_at",
      render: (text) => (text ? formatDateTime(text) : "-"),
      sorter: {
        compare: (a, b) => a.createdAt - b.createdAt,
        multiple: 3,
      },
    },
  ];

  const userTable = [
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
      sorter: {
        compare: (a, b) => a.username.length - b.username.length,
        multiple: 1,
      },
      sortDirections: ["descend", "ascend"],
      render: (text, row) => <a href={`/user/${row.id}`}>{text}</a>,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Registered Date (YYYY-MM-DD)",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => (text ? formatDateTime(text) : "-"),
      sorter: {
        compare: (a, b) => a.totalTopup - b.totalTopup,
        multiple: 3,
      },
    },
  ];

  return (
    <div className="table">
      {name === "user" ? (
        <Table
          rowKey="objectId"
          rowSelection={{ selectedRowKeys, onChange: onSelectRows }}
          className="table-section"
          columns={userTable}
          dataSource={masterAccount}
          pagination={false}
          scroll={{ x: true }}
          loading={loading}
        />
      ) : (
        <Table
          rowKey="objectId"
          rowSelection={{ selectedRowKeys, onChange: onSelectRows }}
          className="table-section"
          columns={table}
          dataSource={masterAccount}
          pagination={false}
          scroll={{ x: true }}
          loading={loading}
        />
      )}
    </div>
  );
};

export default SubAccountFilter;
