import React, { useState, useEffect, useCallback } from "react";
import { Col, Row, Pagination, Collapse, Form, Table, Tabs } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";
import axios from "axios";
import PageHeader from "../Layout/PageHeader";
import moment from "moment";
import { Link } from "react-router-dom";

import ReferralAccountFilter from "./ReferralAccountFilter";
import Commission from "./Commission";

import { formatDateTime } from "../../helpers/date";

const ReferralAccount = ({ selectedRowKeys, onSelectRows }) => {
  const [referralAccount, setReferralAccount] = useState();
  const [filters, setFilters] = useState({});
  const [pagination, setPagination] = useState({ currentPage: 1, limit: 10 });
  const [isLoading, setIsLoading] = useState(false);

  const userTable = [
    {
      title: "Referral Code Id",
      dataIndex: "referralCodeId",
      key: "referralCodeId",
      render: (text, row) => (
        <Link to={`/referral-account/${row._id}`}>{text}</Link>
      ),
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Total Commission (RM)",
      dataIndex: "totalCommission",
      key: "totalCommission",
    },
    {
      title: "Commission Count)",
      dataIndex: "commissionCount",
      key: "commissionCount",
    },
    {
      title: "Email Verfied On",
      dataIndex: "emailVerifiedOn",
      key: "emailVerifiedOn",
      render: (text) => (text ? formatDateTime(text) : "-"),
    },
  ];

  const fetchReferralAccount = useCallback(async () => {
    setIsLoading(true);
    const params = {
      ...(filters.startDate !== null && {
        startDate: filters.startDate?.startOf("day")?.toISOString(),
      }),
      ...(filters.endDate !== null && {
        endDate: filters.endDate?.endOf("day")?.toISOString(),
      }),
      ...(filters.search && {
        searchField: filters.searchField,
        search: filters.search,
      }),
      limit: pagination.limit,
      page: pagination.currentPage,
    };

    const res = await axios.get("/api/referral-account", { params });
    setReferralAccount(res.data.data);
    setPagination(res.data.pagination);
    setIsLoading(false);
  }, [pagination.currentPage, pagination.limit, filters]);

  useEffect(() => {
    fetchReferralAccount();
  }, [pagination.currentPage, pagination.limit, fetchReferralAccount]);

  return (
    <div>
      <PageHeader
        title="Referral Account"
        childRoutes={[
          { path: "/referral-account", breadcrumbName: "Referral Account" },
        ]}
      />
      <br />
      <div className="table mt-4">
        <Tabs defaultActiveKey="1" animated={true}>
          <Tabs.TabPane tab="Referral" key="referral">
            <Collapse
              expandIcon={({ isActive }) => (
                <CaretRightOutlined rotate={isActive ? 90 : 0} />
              )}
            >
              <Collapse.Panel header="Filters" key="filters">
                <ReferralAccountFilter
                  filters={filters}
                  onFiltersChange={(filters) => setFilters(filters)}
                  tabName="referral"
                />
              </Collapse.Panel>
            </Collapse>
            <br />
            <Row justify="end">
              <Col>
                <Pagination
                  total={pagination?.totalSize}
                  pageSize={pagination?.limit}
                  onChange={(currentPage, limit) => {
                    setPagination({ ...pagination, limit, currentPage });
                  }}
                  current={pagination.currentPage}
                />
              </Col>
            </Row>

            <Table
              className="table-section"
              columns={userTable}
              dataSource={referralAccount}
              pagination={false}
              scroll={{ x: true }}
              loading={isLoading}
            />
            <Row justify="end">
              <Col>
                <Pagination
                  total={pagination?.totalSize}
                  pageSize={pagination?.limit}
                  onChange={(currentPage, limit) => {
                    setPagination({ ...pagination, limit, currentPage });
                  }}
                  current={pagination.currentPage}
                />
              </Col>
            </Row>
          </Tabs.TabPane>

          <Tabs.TabPane tab="Comissions" key="commission">
            <Commission />
          </Tabs.TabPane>
        </Tabs>
      </div>
      <br />
    </div>
  );
};

export default ReferralAccount;
