import React, { useState, useEffect } from "react";
import { Row, Col, Card, Collapse } from "antd";
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, registerables } from 'chart.js';
import axios from "axios";
import moment from 'moment'

import AnalysisFilters from '../Analysis/AnalysisFilters'

ChartJS.register(...registerables);

const endOfThisMonth = moment().endOf('month')
const oneYearAgo = moment().subtract(12, 'months').startOf('month')

const UserAnalytic = ({ merchantId }) => {
  const [filters, setFilters] = useState({ startDate: oneYearAgo, endDate: endOfThisMonth })
  const [monthlyOrderStats, setMonthlyOrderStats] = useState([])

  useEffect(() => {
    const dateParams = {
      startDate: filters.startDate?.startOf('month')?.toISOString(),
      endDate: filters.endDate?.endOf('month')?.toISOString(),
    }

    const fetchMonthlyOrderStats = async () => {
      const res = await axios.get(`/api/analysis/monthly-order`, {
        params: { 
          merchantId,
          ...dateParams }
      })
      setMonthlyOrderStats(res.data.data)
    }

    fetchMonthlyOrderStats()
  }, [filters.startDate, filters.endDate, merchantId])

  return (
    <React.Fragment>
      <Collapse>
        <Collapse.Panel header={`Filters (${filters.startDate.format('MMM YYYY')} - ${filters.endDate.format('MMM YYYY')})`} key="filters">
          <AnalysisFilters
            filters={filters} 
            onFiltersChange={filters => setFilters(filters)} 
          />
        </Collapse.Panel>
      </Collapse>

      <Row gutter={16} className="mt-4">
        <Col md={12}>
          <Card title="Order RTO Rate (%)">
          <Line
            data={{
              labels: monthlyOrderStats.map(stat => `${stat.year}-${stat.month}`),
              datasets: [{
                label: 'RTO (%)',
                backgroundColor: 'rgb(99, 99, 132)',
                fill: true,
                order: 1,
                borderColor: 'rgb(99, 99, 132)',
                data: monthlyOrderStats?.map(stat => {
                  return ((stat.returnedCount / (stat.totalCount || 0)) * 100).toFixed(2)
                }),
              }]
            }}
            options={{
              interaction: {
                mode: 'index'
              },
              plugins: {
                legend: {
                  display: false
                },
                tooltip: {
                  callbacks: {
                    label: function(context) {
                      const totalCount = monthlyOrderStats[context.dataIndex].totalCount
                      const returnedCount = monthlyOrderStats[context.dataIndex].returnedCount

                      return `${context.formattedValue}% : ${returnedCount} return requests in ${totalCount} Order`
                    }
                  }
                }
              }
            }}
          />
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default UserAnalytic;
