import React, { useState } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import UserBadgeTag from "../shared/UserBadgeTag";
import TopupPackageTag from "../shared/TopupPackageTag";
import { Table, Button, message } from "antd";

import { CopyOutlined } from "@ant-design/icons";

import { formatDateTime } from "../../helpers/date";

const DASHBOARD_URL =
  process.env.REACT_APP_DASHBOARD_URL || "https://partner.parceldaily.com";
const AccountManagerList = ({
  accountManager,
  selectedRowKeys,
  onSelectRows,
  name,
  loading,
}) => {
  const table = [
    {
      title: "Id",
      dataIndex: "accountManagerId",
      key: "accountManagerId",
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
      sorter: {
        compare: (a, b) => a.username.length - b.username.length,
        multiple: 1,
      },
      sortDirections: ["descend", "ascend"],
      render: (text, row) => (
        <Link to={`/account-manager/${row.id}`}>{text}</Link>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Total Topup Performance (RM)",
      dataIndex: "topupPerformance",
      key: "topupPerformance",
    },
    {
      title: "Total Topup Count",
      dataIndex: "topupCount",
      key: "topupCount",
    },
    {
      title: "New User Count",
      dataIndex: ["newUserStats", "merchantCount"], // Accessing nested property
      key: "merchantCount",
      render: (text, record) => (
        <span>{record.newUserStats ? record.newUserStats.merchantCount : "-"}</span>
      ),
    },
    {
      title: "New User Topup Performance (RM)",
      dataIndex: ["newUserStats", "totalTopup"], // Accessing nested property
      key: "totalTopup",
      render: (text, record) => (
        <span>{record.newUserStats ? record.newUserStats.totalTopup : "-"}</span>
      ),
    },
    {
      title: "New User Total Topup Count",
      dataIndex: ["newUserStats", "topupMerchantCount"], // Accessing nested property
      key: "topupMerchantCount",
      render: (text, record) => (
        <span>{record.newUserStats ? record.newUserStats.topupMerchantCount : "-"}</span>
      ),
    },
    {
      title: "Register URL",
      dataIndex: "accountManagerId",
      render: (text, row) => (
        <React.Fragment>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <span style={{ alignSelf: "flex-start", marginRight: 8 }}>
              {`${DASHBOARD_URL}/register/${row.accountManagerId}`}
            </span>
            <div style={{ flex: 1 }}></div>{" "}
            <Button
              icon={<CopyOutlined />}
              onClick={() => {
                navigator.clipboard.writeText(
                  `${DASHBOARD_URL}/register/${row.accountManagerId}`
                );
                message.success("Copied to clipboard");
              }}
            />
          </div>
        </React.Fragment>
      ),
    },
  ];

  const userTable = [
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
      sorter: {
        compare: (a, b) => a.username.length - b.username.length,
        multiple: 1,
      },
      sortDirections: ["descend", "ascend"],
      render: (text, row) => <a href={`/user/${row.id}`}>{text}</a>,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Credit",
      dataIndex: "credit",
      key: "credit",
      sorter: {
        compare: (a, b) => a.credit - b.credit,
        multiple: 2,
      },
    },
    {
      title: "Total Topup",
      dataIndex: "totalTopup",
      key: "totalTopup",
      sorter: {
        compare: (a, b) => a.totalTopup - b.totalTopup,
        multiple: 3,
      },
    },
    {
      title: "Package",
      dataIndex: "topupPackage",
      key: "topupPackage",
      render: (text) => (text ? <TopupPackageTag packageKey={text} /> : "-"),
    },
    {
      title: "Badge",
      dataIndex: "badge",
      key: "badge",
      render: (text, row) =>
        text ? <UserBadgeTag statusKey={text}>Verified</UserBadgeTag> : "-",
    },
    {
      title: "Total Usage",
      dataIndex: "totalUsage",
      key: "totalUsage",
      render: (text) => (text ? text.toFixed(2) : "-"),
      sorter: {
        compare: (a, b) => a.totalUsage - b.totalUsage,
        multiple: 2,
      },
    },
    {
      title: "Total Order",
      dataIndex: "totalNoOrder",
      key: "totalNoOrder",
      sorter: {
        compare: (a, b) => a.totalTopup - b.totalTopup,
        multiple: 3,
      },
    },
    {
      title: "Last Active Date",
      dataIndex: "lastDateActive",
      key: "lastDateActive",
      render: (text) => (text === "-" ? "-" : moment(text).fromNow()),
    },
    {
      title: "Referral Code",
      dataIndex: "referralCode",
      key: "referralCode",
      render: (text) => (text ? text : "-"),
      sorter: (a, b) => {
        a = a.referralCode || "";
        b = b.referralCode || "";
        return a.localeCompare(b);
      },
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Locked Tier",
      dataIndex: "lockedTier",
      key: "lockedTier",
    },
    {
      title: "Registered Date (YYYY-MM-DD)",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => (text ? formatDateTime(text) : "-"),
      sorter: {
        compare: (a, b) => a.totalTopup - b.totalTopup,
        multiple: 3,
      },
    },
  ];

  return (
    <div className="table">
      {name === "user" ? (
        <Table
          rowKey="objectId"
          rowSelection={{ selectedRowKeys, onChange: onSelectRows }}
          className="table-section"
          columns={userTable}
          dataSource={accountManager}
          pagination={false}
          scroll={{ x: true }}
          loading={loading}
        />
      ) : (
        <Table
          rowKey="objectId"
          rowSelection={{ selectedRowKeys, onChange: onSelectRows }}
          className="table-section"
          columns={table}
          dataSource={accountManager}
          pagination={false}
          scroll={{ x: true }}
          loading={loading}
        />
      )}
    </div>
  );
};

export default AccountManagerList;
