import React from 'react'
import { EditOutlined } from '@ant-design/icons'

import { camelToTitle } from '../../helpers/strings'

const formatAttribute = attribute => {
  if (Array.isArray(attribute)) {
    return (
      <ul>
        { attribute.map(attr => <li>{ JSON.stringify(attr) }</li>) }
      </ul>
    )
  }

  return JSON.stringify(attribute) || "-"
}

const AuditLogItem = ({ auditLog }) => {
  const attributes = Object.keys(auditLog.changes.attributes)

  const actionMap = {
    'update': "updated"
  }

  const formatLog = attribute => {
    return (
      <p>
        <strong>
          { camelToTitle(attribute)}{' '}
        </strong>
        has been { actionMap[auditLog.description] || 'updated' } from{' '}
        <strong>
          { formatAttribute(auditLog.changes.old[attribute]) }{' '}
        </strong>
        to{' '}
        <strong>
          { formatAttribute(auditLog.changes.attributes[attribute]) }{' '}
        </strong>
      </p>
    )
  }

  return (
    <React.Fragment>
      <div>
        <h4>
          <EditOutlined></EditOutlined>{' '}
          <strong>
            { actionMap[auditLog.description]?.toUpperCase() || 'UPDATED' }
          </strong>
        </h4>
        <ol>
          {attributes.map(attribute => <li>{ formatLog(attribute) }</li> )}
        </ol>
      </div>
      <div>
        { new Date(auditLog.createdAt).toLocaleString() }
        <br />
        { auditLog.updatedBy?.email ? `(${auditLog.updatedBy?.email})` : "" }
      </div>
    </React.Fragment>
  )
}

export default AuditLogItem