export const BETA_FEATURES = {
    hideAddress: {
        label: "HIDE_ADDRESS",
        desc: "Hide Merchant Address in AWB",
    },
    hideLogo: {
        label: "HIDE_LOGO",
        desc: "Hide Parcel Daily logo in AWB",
    },
    hideCodAmount: {
        label: "HIDE_COD_AMOUNT",
        desc: "Hide COD Amount in AWB",
    }, 
    codWarning: {
        label: "COD_WARNING",
        desc: "Show COD RTO Warning to User",
    },
    shipmentAnalysis: {
        label: "SHIPMENT_ANALYSIS",
        desc: "Show Shipment Analysis page on User Partner",
    },
    autoNDR: {
        label: "AUTO_NEXT_DAY_REMITTANCE",
        desc: "Auto mark all User Order as NDR without charge",
    },
    skipInvoice: {
        label: "SKIP_INVOICE",
        desc: "Skip international order invoice upload",
    },
    nextPageContentList: {
        label: "NEXT_PAGE_CONTENT_LIST",
        desc: "List the order's content list on the next page if character more than 50",
    },
    returnFeeCharge: {
        label: "RETURN_FEE_CHARGE",
        desc: "Mark this user to be charge for Return Handling Fee",
    },
}
